import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";
import useModal from "../Hooks/useModal";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import { GET_WAITERS } from "../../apiAdmin";
import { GET_ORDERS_CAJA_PADEL } from "../../apiPadel";
import PedidoCajaPadel from "./PedidoCajaPadel";
import TicketPadel from "./TicketPadel";

const CajaMainPage = () => {
    const [dataOrders, setDataOrders] = useState();
    const [dataWaiters, setDataWaiters] = useState();
    const [loading, setLoading] = useState(false);
    const { openModal, isOpen, closeModal } = useModal();
    const { openModal: openTicket, isOpen: isOpenTicket, closeModal: closeTicket } = useModal();
    const [selectedOrden, setSelectedOrden] = useState(null);
    const [idTicket, setIdTicket] = useState(null);

    const fetchDataOrders = async () => {
        try {
            setLoading(true);
            const ordersData = await GET_ORDERS_CAJA_PADEL();
            const waitersData = await GET_WAITERS();
            const groupedOrders = ordersData.reduce((acc, order) => {
                const key = `${order.num_mesa}-${order.id_mesero}`; 
                if (!acc[key]) {
                    acc[key] = {
                        id_mesero: order.id_mesero,
                        num_mesa: order.num_mesa,
                        tipo_orden: order.tipo_orden,
                        total: parseFloat(order.total),
                        orden: [order],
                    };
                    
                } else {
                    acc[key].total += parseFloat(order.total); 
                    acc[key].orden.push(order);
                }
                return acc;
            }, {});
            
            const finalOrders = Object.values(groupedOrders);
            setDataOrders(finalOrders);
            setDataWaiters(waitersData);
        } catch (err) {
            console.error(err);
            setDataOrders([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDataOrders();
    }, []);

    const handleOpen = (orden) => {
        openModal();
        setSelectedOrden(orden);
    }

    const getWaiterName = (id_mesero) => {
        const waiter = dataWaiters?.find(waiter => waiter.id === id_mesero);
        return waiter ? waiter.nombre : "Desconocido"; 
    };

    const columns = [
        {
            name:"Mesero",
            selector: row => getWaiterName(row.id_mesero),
            sortable: true,
        },
        {
            name:"Mesa",
            selector: row => row.num_mesa,
            sortable: true,
            compact:true,
        },
        {
            name:"Total",
            selector: row => `$${row.total} MX`,
        },
        {
            name:"Acciones",
            selector: row => 
            <div className="flex flex-row space-x-4">
                <ButtonForm text="Liberar" bgColor="bg-blue-600" width="w-24" onClick={()=> handleOpen(row)}/>
            </div>,
            width:"auto",
        },
    ]

    const NoDataComponent = () => (
        <div className="h-[75vh] flex justify-center items-center">
          <LargeText text="No hay ordenes en caja" colorText="text-red-500" size="text-5xl" textBold />
        </div>
      );      

    return(
        <div className="w-full bg-white">
            <div className="border-2 border-black rounded-lg py-2">
                <div className="flex flex-row justify-center items-center space-x-4">
                    <LargeText text="Pedidos" textAling="text-center" size="text-2xl" colorText="" textBold />
                    <ButtonForm text="Actualizar" onClick={fetchDataOrders} bgColor="border border-black hover:border-0 hover:bg-yellow-300" textColor="text-black" width="w-24"/>
                </div>
                <DataTable
                    data={dataOrders}
                    columns={columns}
                    fixedHeader
                    fixedHeaderScrollHeight="63vh"
                    noDataComponent={<NoDataComponent/>}
                    progressPending={loading}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                />
            </div>
            {isOpen && selectedOrden !== null &&
                <ModalAdmin title="Productos" onClose={closeModal}>
                    <PedidoCajaPadel ordenes={selectedOrden} onClose={closeModal} refetch={fetchDataOrders} openTicket={openTicket} setIdTicket={setIdTicket} />
                </ModalAdmin>
            }
            {isOpenTicket && idTicket &&
                <ModalAdmin title="Imprimir" onClose={closeTicket} >
                    <TicketPadel id={idTicket}/>
                </ModalAdmin>
            }
        </div>
    )
}

export default CajaMainPage;