import React, { useState, useEffect, useCallback } from "react";
import { GET_BITACORA, GET_CONSUMABLES } from "../../../apiAdmin";
import DataTable from "react-data-table-component";
import moment from "moment";

const IssuesTable = ({ kitchenId }) => {
    const [issues, setIssues] = useState();
    const [consumables, setConsumables] = useState([]);

    const fetchData = useCallback(async () => {
        try {
            const data = await GET_BITACORA();
            const filteredData = data.filter(issue => issue.id_cocina === parseInt(kitchenId, 10));
            setIssues(filteredData);
            const dataConsumables = await GET_CONSUMABLES();
            const filteredConsumables = dataConsumables.filter(consumable => consumable.almacen.id_cocina === parseInt(kitchenId, 10));
            setConsumables(filteredConsumables);
        } catch (error) {
            console.error("Error al cargar la bitacora", error);
        }
    }, [kitchenId]);
    
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const getConsumableName = (id) => {
        const consumable = consumables.find(c => c.id === id);
        return consumable ? consumable.almacen.nombre : "Desconocido";
    }

    const columns = [
        { name:"ID", selector: row => row.id, width:"45px" },
        { name:"Copas o unidades", selector: row => row.cantidad, width:"140px" },
        { name:"Producto", selector: row => getConsumableName(row.id_almacen),  width:"15vw" },
        { name:"Razon", selector: row => row.razon, width:"33vw" },
        { name:"Fecha", selector: row => moment(row.created_at).format("DD/MM/YYYY"), width:"105px", sortable:true },
    ]

    return(
        <div>
            <DataTable data={issues} columns={columns} />
        </div>
    )
}

export default IssuesTable;