import React, { useState, useEffect, useCallback } from "react";
import { Formik, FieldArray } from "formik";
import ButtonForm from "../../atoms/ButtonForm";
import { toast } from "react-toastify";
import { CREATE_PRODUCT, GET_CATEGORIAS_PRODUCTS, GET_CONSUMABLES, UPLOAD_IMAGE, } from "../../../apiTheLaundry/apiAdmin";
import InputFFAdmin from "../../AdminAtoms/InputFFAdmin";

const CreateBebida = ({ onClose, refetch, id_cocina }) => {
	const [loading, setLoading] = useState(false);
	const [consumables, setConsumables] = useState([]);
	const [categoriasProdc, setCategoriasProdc] = useState();
	const [file, setFile] = useState(null);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const data = await GET_CONSUMABLES();
			const filteredConsumables = data.filter(
				(consumable) => consumable.almacen.id_cocina === parseInt(id_cocina, 10)
			);
			setConsumables(filteredConsumables);
			const categoriasProductos = await GET_CATEGORIAS_PRODUCTS();
			const mappedCategoriasP = categoriasProductos.map((cat) => ({
				label: cat.nombre, value: cat.prioridad,
			}));
			setCategoriasProdc(mappedCategoriasP);
		} catch (error) {
			console.error("Error al cargar los consumibles", error);
		} finally {
			setLoading(false);
		}
	},[id_cocina]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const optionsReceta = consumables.map((consumable) => ({
		value: consumable.id,
		label: consumable.almacen.nombre,
	}));

	const handleSubmit = async (values, { setSubmitting }) => {
		const { nombre, descripcion, precio, status, id_cocina, tipo, receta, categoria, tipo_botella } =
			values;

		setLoading(true);
		try {
			let imageUrl = "";
			if (file) {
				const folder = "Productos";
				const uploadResponse = await UPLOAD_IMAGE(file, folder);
				imageUrl = uploadResponse.url;
			}
			const productResponse = await CREATE_PRODUCT({ 
				nombre, imagen: imageUrl, descripcion, precio, status, id_cocina, tipo, receta, categoria, tipo_botella
			});
			const productId = productResponse?.newProduct?.id;
			if (!productId) { throw new Error("El ID del producto no se encontró en la respuesta"); }
			toast.success("Bebida creada correctamente"); refetch(); onClose();
		} catch (error) {
			console.error("Error al crear la bebida:", error);
			toast.error("Error al crear la bebida");
		} finally {
			setLoading(false);
		}
		setSubmitting(false);
	};

	const validate = (values) => {
		const errors = {};
		if (!values.nombre) {
			errors.nombre = "Campo requerido";
		}
		if (!values.precio) {
			errors.precio = "Campo requerido";
		} else if (values.precio < 0) {
			errors.precio = "El precio no puede ser negativo";
		}
		if (!values.tipo_botella) {
			errors.tipo_botella = "Es botella de vino?";
		}
		if (values.receta.length > 0) {
			const recetaErrors = [];
			values.receta.forEach((receta, index) => {
				if (!receta.id) {
					recetaErrors[index] = { id: "El consumible no puede estar vacío" };
				}
			});
			if (recetaErrors.length > 0) {
				errors.receta = recetaErrors;
			}
		}
		return errors;
	};

	const options = [
		{ label: "Disponible", value: "Disponible" },
		{ label: "Minimo", value: "Minimo" },
		{ label: "Oculto", value: "Oculto" },
	];

	const optionsBotella = [
		{ label: "Cierto", value: "Cierto" },
		{ label: "Falso", value: "Falso" },
	];

	return (
		<div>
			<Formik
				initialValues={{
					nombre: "",
					descripcion: "",
					precio: 0,
					status: "",
					id_cocina: id_cocina,
					tipo: "Bebida",
					receta: [],
					categoria: "",
					tipo_botella:""
				}}
				onSubmit={handleSubmit}
				validate={validate}
				enableReinitialize
			>
				{({ handleSubmit, values }) => (
					<form onSubmit={handleSubmit}>
						<div className="w-full flex flex-row space-x-4">
							<div className="w-1/2 flex flex-col space-y-4">
								<InputFFAdmin name="nombre" label="Nombre(s)" type="text" placeholder="Nombre(s)" />
								<InputFFAdmin name="descripcion" label="Descripción" type="text" placeholder="Descripción del producto" />
								<InputFFAdmin name="categoria" label="Categoria del producto" type="select" options={categoriasProdc} />
								<InputFFAdmin name="status" label="Estatus de stock" type="select" options={options} />
							</div>
							<div className="w-1/2 flex flex-col space-y-4">
								<InputFFAdmin
									name="precio"
									label="Precio"
									type="number"
									placeholder="Precio en pesos mexicanos"
									value={values.precio}
								/>
								<InputFFAdmin name="tipo_botella" label="Es botella de vino" type="select" options={optionsBotella} />
								<InputFFAdmin
									name="imagen"
									label="Imagen"
									type="file"
									accept="image/*"
									onChange={(event) => setFile(event.currentTarget.files[0])}
								/>
								<FieldArray name="receta">
									{({ insert, remove, push }) => (
										<div className="space-y-4">
											{values.receta.length > 0 &&
												values.receta.map((receta, index) => (
													<div key={index} className="flex space-x-4">
														<InputFFAdmin
															name={`receta.${index}.id`}
															label="Consumible"
															type="select"
															options={optionsReceta}
														/>
														<button
															type="button"
															onClick={() => remove(index)}
															className="text-red-500"
														>
															Eliminar
														</button>
													</div>
												))}
											<button
												type="button"
												onClick={() => push({ id: "" })}
												className="text-blue-500 mt-2"
											>
												Agregar ingrediente
											</button>
										</div>
									)}
								</FieldArray>
							</div>
						</div>
						<div className="w-full flex flex-row justify-between items-center">
							<ButtonForm
								type="submit"
								bgColor={loading ? "bg-gray-300" : "bg-violet-300"}
								width="w-1/3 ml-auto mt-6"
								text={loading ? "Cargando..." : "Agregar"}
								disabled={loading}
							/>
						</div>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default CreateBebida;
