import React, { useState, useEffect } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useProducts } from "../../context/ProductContext";

const Contador = ({ 
    width="w-24", 
    selectedProduct, initialCount, onCountChange
}) => {
    const { cart, updateCart, calculateTotal } = useProducts();
	const [count, setCount] = useState(initialCount || 1);

	useEffect(() => {
		if (initialCount === undefined) {
			const initialCartCount =
				cart.find(
					(item) =>
						item.id === selectedProduct.id &&
						JSON.stringify(item.extras) ===
							JSON.stringify(selectedProduct.extras) &&
						JSON.stringify(item.additionals) ===
							JSON.stringify(selectedProduct.additionals)
				)?.quantity || 1;
			setCount(initialCartCount);
		}
	}, [selectedProduct.id, cart, initialCount, selectedProduct.additionals, selectedProduct.extras]);

	const updateTotalProduct = (newCount) => {
		const productPrice = calculateTotal(selectedProduct, newCount);
		return productPrice;
	};

	const onIncrement = () => {
		const newCount = count + 1;
		setCount(newCount);

		if (onCountChange) {
			onCountChange(newCount);
		} else {
			const totalProduct = updateTotalProduct(newCount);
			updateCart({
				...selectedProduct,
				quantity: newCount,
				totalProduct,
			});
		}
	};

	const onDecrement = () => {
		if (count > 0) {
			const newCount = count - 1;
			setCount(newCount);
			if (onCountChange) {
				onCountChange(newCount);
			} else {
				const totalProduct = updateTotalProduct(newCount);
				updateCart({
					...selectedProduct,
					quantity: newCount,
					totalProduct,
				});
			}
		}
	};

    const divClass = ` ${width} flex flex-row border-2 border-green-500 rounded-full h-10 p-2 justify-between items-center`;

    return (
        <div className={divClass}>
            <FaRegTrashAlt className="h-4 text-white cursor-pointer hover:text-red-500" onClick={onDecrement} />
            <div className="rounded-full h-6 w-6 justify-center flex items-center text-green-500 text-bold">{count}</div>
            <IoIosAddCircleOutline className="h-6 w-6 text-white hover:text-green-400 cursor-pointer" onClick={onIncrement} />
        </div>
    );
};

export default Contador;
