import React from "react";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigation } from "../../Hooks/useNavigation";
import LargeText from "../../atoms/LargeText";
import ButtonForm from "../../atoms/ButtonForm";
import RegisterField from "../../atoms/RegisterField";
import { CREATE_USER, LOGIN_USER_EXT } from "../../../api";
import contenedorNeon from "../../assets/Imagenes/neon.webp";
import { PulseLoader } from "react-spinners";
import moment from "moment";

const RegisterStep3 = ({ setActiveSection, mailPhone, loading, setLoading }) => {
	const navigate = useNavigation();

	const initialValues = {
		nombre: "",
		apellido: "",
		fecha_nacimiento: "",
		email: mailPhone || "",
		telefono: "",
		password: "",
		password2: "",
	};

	const handleSubmit = async (values) => {
		setLoading(true);
		const { password2, ...filteredValues } = values;

		try {
			const response = await CREATE_USER(filteredValues);
			if (response){
				toast.success("Usuario creado exitosamente");
				handleIngresar(filteredValues);
			}
		} catch (error) {
			toast.error("Usuario ");
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const handleIngresar = async (values ) => {
		try {
			const dataUser =  await LOGIN_USER_EXT(mailPhone, values.password);
            if (dataUser) {
				navigate.navigateToHome();
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleBack = () => {
		setActiveSection("2");
	};

	const validate = (values) => {
		const errors = {};
		if (!values.nombre) {
			errors.nombre = "Campo requerido";
		}
		if (!values.apellido) {
			errors.apellido = "Campo requerido";
		}
		if (!values.telefono) {
			errors.telefono = "Campo requerido";
		} else if (!/^\d{10}$/.test(values.telefono)) {
			errors.telefono = "El número de teléfono debe tener 10 dígitos";
		}
		if (!values.password) {
			errors.password = "Campo requerido";
		} else if (
			!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,}/.test(values.password)
		) {
			errors.password =
				"6 caracteres, una mayúscula, una minúscula y un número.";
		}
		if (!values.password2) {
			errors.password2 = "Campo requerido";
		} else if (values.password !== values.password2) {
			errors.password2 = "Las contraseñas no coinciden";
		}
		if (!values.fecha_nacimiento) {
			errors.fecha_nacimiento = "Campo requerido";
		} else {
			const edad = moment().diff(moment(values.fecha_nacimiento, "YYYY-MM-DD"), "years");
			if (edad < 15) {
				errors.fecha_nacimiento = "Debes tener al menos 15 años";
			}
		}

		return errors;
	};

	return (
		<div className="w-full sm:w-[40%] h-screen flex flex-col items-center py-8 sm:py-2 px-6 sm:px-0">
			<Formik
				initialValues={initialValues}
				validate={validate}
				onSubmit={handleSubmit}
			>
				{({ handleSubmit }) => (
					<form
						onSubmit={handleSubmit}
						className="w-full flex flex-col justify-center items-center space-y-2"
					>
						<img src={contenedorNeon} alt="contenedor" className="w-[70%]" />
						<RegisterField name="nombre" type="text" placeholder="Nombre(s)" />
						<RegisterField name="apellido" type="text" placeholder="Apellido(s)" />
						<RegisterField name="telefono" type="number" placeholder="Telefono" />
						<RegisterField name="password" type="password" placeholder="Contraseña" />
						<RegisterField name="password2" type="password" placeholder="Confirmar contraseña" />
						<div className="flex flex-row w-full">
							<LargeText text="Fecha de nacimiento" />
							<RegisterField name="fecha_nacimiento" type="date"/>
						</div>
						<ButtonForm 
							text="Registrarse" bgColor="bg-yellow-300" textColor=""
							type={loading ?  <PulseLoader color="#0b8500"/>  : "submit"} disabled={loading} 
						/>
						<FaArrowLeftLong
							className="text-yellow-300 w-24 h-6 mt-4 cursor-pointer"
							onClick={handleBack}
						/>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default RegisterStep3;
