import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Circle from "../atoms/Circle";
import Titulo from "../atoms/Titulo";
import H2Custom from "../atoms/H2Custom";
import FooterMenu from "../organisms/FooterMenu";
import FondoMenu from "../assets/Imagenes/FondoMenu.jpg"
import { getKitchen } from "../../api";

const Menu = () => {
    const navigate = useNavigate();
    const [cocinas, setCocinas] = useState([]);
    
    useEffect (()=>{
        const fetchKitchen = async () =>{
            try{
                const data = await getKitchen();
                const filteredCocinas = data
                    .filter(cocina => cocina.tipo !== "CocinaPadel")
                    .sort((a,b) => a.id - b.id );
                setCocinas(filteredCocinas);
            } catch (error) {
                console.log("Error al obtener los datos", error);
            }
        };
        fetchKitchen();
    },[]);

    useEffect(() => {
        document.title = "Menú - Contenedor";
    }, []); 

    const handleCircleClick = (id) => {
        navigate(`${id}`);
    };

    return(
        <div>
            <div 
                className="px-4 pt-4 flex  flex-col justify-start items-center min-h-screen"
                style={{ backgroundImage:`url(${FondoMenu})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            >
                <div className="mt-8">
                    <Titulo Letras="COCINAS CONTENEDOR" />
                </div>
                <H2Custom text="Se indican las cocinas abiertas al brillar" textBold size="text-xl" textAling="text-center" />
                <div className="w-full flex flex-wrap justify-between mt-4">
                    {cocinas.map((cocina)=>(
                        <Circle
                            key={cocina.id}
                            cocina={cocina}
                            selected={cocina.status === "Abierto" ? true : false}
                            Height="aspect-square" 
                            Width="w-[45%] md:w-[13%] md:mx-[1%] md:my-8" 
                            onClick={()=>handleCircleClick(cocina.id)}
                        />
                    ))}
                </div>
            </div>
            <FooterMenu/>
        </div>
    )
}

export default Menu;