import React, { useState, useEffect, useRef } from "react";
import { GET_WAITERS_ID } from "../../apiTheLaundry/apiAdmin";
import { getKitchen } from "../../apiTheLaundry/api";
import { GET_ORDER_BY_ID } from "../../apiTheLaundry/apiCaja";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import ButtonForm from "../atoms/ButtonForm";
import { MdLocalPrintshop } from "react-icons/md";
import Logo from "../assets/Imagenes/lau.png"
import DataTable from "react-data-table-component";

const Ticket = ({ id }) => {
  const [order, setOrder] = useState([]);
  const [mesero, setMesero] = useState();
  const [cocinas, setCocinas] = useState([]);
  const componentRef = useRef(); 

  useEffect(() => {
    const getInfo = async () => {
      try {
        const orderData = await GET_ORDER_BY_ID(id);
        setOrder(orderData);
        const cocinasData = await getKitchen();
        setCocinas(cocinasData);
        const meseroData = await GET_WAITERS_ID(orderData?.id_mesero);
        setMesero(meseroData);
      } catch (err) {
        console.error(err);
      }
    };

    getInfo();
  }, [id]);

  const getCocinaName = (id_cocina) => {
    const cocina = cocinas.find((c) => c.id === id_cocina);
    return cocina ? cocina.nombre : "Cocina Desconocida";
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <ButtonForm
        onClick={handlePrint}
        text="Imprimir Ticket"
        bgColor="bg-orange-500"
        width="w-1/3 m-auto"
        icon={<MdLocalPrintshop size={30}/>}
      />
      <div ref={componentRef} className="print:w-[80mm] print:text-xs">
        <div className="gap-4 px-1">
          <div className="flex flex-col justify-center items-center px-2 mb-4">
            <img src={Logo} className="w-1/2" alt="logo" />
            <label>
              RFC: GIC110317SA0
            </label>
            <label>
              Lateral Sur de la Vía Atlixcayotl, 6823, San Antonio Cacalotepec, 72828, Heroica Puebla de Zaragoza, Puebla, México 
            </label>
          </div>
            <div className="flex flex-row justify-between p-x-1">
              <label>{`No. de ticket :${order?.id}`}</label>
              <label>{`Fecha: ${moment(order?.createdAt).format("DD/MM/YY HH:mm")}`}</label>
            </div>
          {Object.keys(order.orden || {}).map((key) => (
            <div key={key} className="col-span-3 mt-4">
              <h1 className="font-bold text-sm">{`Cocina: ${getCocinaName(parseInt(key))}`}</h1>
              <table className="table-auto w-full">
                <thead>
                  <tr className="border-b border-gray-400">
                    <th className="text-left w-1/6">Cant</th>
                    <th className="text-left w-2/6">Producto</th>
                    <th className="text-left w-1/6">Precio/u</th>
                    <th className="text-left w-1/6">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {order.orden[key].map((producto) => (
                    <tr key={producto.uniqueId} className="border-b border-gray-400">
                      <td>{producto.quantity}</td>
                      <td className="truncate">{producto.nombre}</td>
                      <td>{`$${producto.precio}`}</td>
                      <td>{`$${producto.totalProduct}`}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
          <div className="grid grid-cols-2 mt-8">
            <label>{`Mesero: ${mesero?.nombre}`}</label>
            <label>{`No. caja: ${order?.id_caja}`}</label>
            <label>{`Pago: ${order?.metodo_pago}`}</label>
            <label>{`Total: $${order?.total_general}`}</label>
            {order?.metodo_pago === "Mixto" && (
              <>
                <label>{`Efectivo: $${order?.total_efectivo}`}</label>
                <label>{`Tarjeta: $${order?.total_tarjeta}`}</label>
              </>
            )}
          </div>
        </div>
      </div>
      {order?.comprobantes?.length > 0 &&
        <div className="border-2 border-gray-300 rounded-lg mt-4">
          <DataTable
            data={order.comprobantes}
            columns={columns}
          />
        </div>
      }
    </div>
  );
};

export default Ticket;

const columns = [
  { name:"# Terminal", selector: row => row.terminal },
  { name:"Monto", selector: row => `$${row.monto}MX`},
  { name:"# Autorización", selector: row => row.autorizacion },
  { name:"# Operación", selector: row => row.operacion }
]

