import React, { useEffect } from "react";
import CirclesBares from "../adminMolecules/CirclesBares";
import H2Custom from "../atoms/H2Custom";

const BarLaundry = () => {
    useEffect(() => {
        document.title = "Bares - The Laundry";
    }, []); 

    return(
        <div className="space-y-4">
            <H2Custom text="Bares" colorText="" size="text-4xl" textBold />
            <CirclesBares/>
        </div>
    )
}

export default BarLaundry;