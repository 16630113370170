import React from "react";
import MenuLinks from "../AdminAtoms/MenuLinks";
import Logo from "../assets/Imagenes/logoAdmin.png";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";
import useModal from "../Hooks/useModal";
import ModalConfirmAdmin from "../adminMolecules/ModalConfirmAdmin";
import { logout } from "../../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IoMenu, IoCloseSharp } from "react-icons/io5";

const Sidebar = () => {
  const navigate = useNavigate();
  const { openModal, closeModal, isOpen } = useModal();
  const { openModal: openSide, closeModal: closeSide, isOpen: isOpenSide } = useModal();

  const handleLogout = async () => {
    try {
      await logout();
      toast.success("Sesión cerrada exitosamente");
      navigate(`/login`);
    } catch (error) {
      console.error(error);
      toast.error("Error al cerrar sesión");
    }
  };

  return (
    <div className="">
      {!isOpenSide &&
        <button className="fixed md:hidden top-1 left-1 z-50 bg-black rounded-full" onClick={openSide}>
          <IoMenu className="text-yellow-200" size={40} />
        </button>
      }
      {isOpenSide &&
        <div className="fixed flex flex-row w-full text-white h-full z-30 ">
          <div className="w-2/3 bg-gray-200">
            <div className="w-full p-1 flex flex-col justify-center items-center">
              <img src={Logo} className="w-[90%]" alt="LOGO" />
              <LargeText text="Panel de administrador" colorText="text-black" />
            </div>
            <MenuLinks />
            <ButtonForm text="Cerrar sesión" textColor="text-black mt-full" onClick={openModal} />
            {isOpen &&
              <ModalConfirmAdmin onClose={closeModal} onConfirm={handleLogout} />
            }
          </div>
          <div className="w-1/3 flex bg-black bg-opacity-50 justify-center p-4">
            <button className="h-10 w-10 bg-black rounded-full" onClick={closeSide}>
              <IoCloseSharp className="text-yellow-200" size={40} />
            </button>
          </div>
        </div>
      }
      <div className="hidden md:block w-48 bg-slate-200 text-white h-full z-30">
        <div className="w-full p-1 flex flex-col justify-center items-center">
          <img src={Logo} className="w-[90%]" alt="LOGO" />
          <LargeText text="Panel de administrador" colorText="text-black" />
        </div>
        <MenuLinks />
        <ButtonForm text="Cerrar sesión" textColor="text-black mt-full" onClick={openModal} />
        {isOpen &&
          <ModalConfirmAdmin onClose={closeModal} onConfirm={handleLogout} />
        }
      </div>
    </div>
  );
};

export default Sidebar;
