import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const LinkCaja = ({ name, path, icon: Icon, childs }) => {
    const [isOpen, setIsOpen] = useState();

    const handleToggle = ( ) => {
        setIsOpen(!isOpen)
    }
    return(
        <div>
        <NavLink
          to={path}
          className={({ isActive }) =>
            `flex items-center w-full h-16 p-4 ${
              isActive ? "bg-white text-black font-bold" : "bg-transparent text-black"
            }`
          }
          onClick={childs?.length ? handleToggle : null}
        >
          {Icon && <Icon className="mr-2" size={25} />}
          {name}
        </NavLink>
        {isOpen && childs?.length > 0 && (
          <ul className="ml-4">
            {childs.map((child) => (
              <li key={child.path}>
                <NavLink
                  to={child.path}
                  className={({ isActive }) =>
                    `block w-full p-2 text-sm ${
                      isActive ? "text-black bg-white" : "bg-transparent text-black"
                    }`
                  }
                >
                  {child.name}
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
}

export default LinkCaja;