import React, { useState } from "react";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import ButtonForm from "../atoms/ButtonForm";
import { UPDATE_KITCHEN } from "../../apiAdmin";

const EditKitchenUser = ({ onClose, refetch, row }) => {
    const [loading, setLoading] = useState();

    const initialValues = { username: row.user?.username || "", password:"", }

    const handleSubmit = async(values) =>{
        try {
            setLoading(true);
            await UPDATE_KITCHEN(row.id_user,values);
            toast.success("Administrador editado correctamente");
            refetch();
            onClose();
        } catch (error){
            console.error("Error", error);
            toast.error("Error al editar el administrador");
        } finally {
            setLoading(false);
        }
    }

    const validate = (values) => {
		const errors = {};
        if (!values.username) { errors.username = "Campo requerido"; }
		return errors;
	};

    return(
        <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate} >
                {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="w-full flex flex-row space-x-4">
                        <div className="w-1/2 flex flex-col space-y-4">
                            <InputFFAdmin name="username" label="Nombre de usuario" placeholder="Nombre de usuario" type="text" />
                        </div>
                        <div className="w-1/2 flex flex-col space-y-4">
                            <InputFFAdmin name="password" label="Contraseña" type="password" placeholder="Contraseña" />  
                        </div>
                    </div>
                   <ButtonForm 
                        bgColor={loading ? "bg-gray-300" : "bg-violet-300"} width="w-1/3 ml-auto mt-6" 
                        text={loading ? "Cargando" : "Guardar cambios"} type="submit" disabled={loading ? true : false } 
                    />
                </Form>
                )}
            </Formik>
        </div>
    )
}

export default EditKitchenUser;