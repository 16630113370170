import { MdPointOfSale } from "react-icons/md";
import { GiArchiveResearch } from "react-icons/gi";

export const linksCaja = [
    {
        name:"Caja",
        path:"/caja",
        icon:MdPointOfSale,
        childs: [], 
    },
    {
        name:"Historial de pedidos",
        path:"/historialPedidos",
        icon:GiArchiveResearch,
        childs: [], 
    },
]

export const linksCajaPadel = [
    {
        name:"Caja",
        path:"/cajaPadel",
        icon:MdPointOfSale,
        childs: [],
    },
    {
        name:"Historial de pedidos",
        path:"/historialPedidosPadel",
        icon:GiArchiveResearch,
        childs: [],
    },
]