import React, { useState, useEffect, useCallback } from "react";
import LargeText from "../../atoms/LargeText";
import MenuCard from "../../atoms/MenuCard";
import { BsArrowRight, BsArrowLeft, BsArrowUp } from "react-icons/bs";
import useModal from "../../Hooks/useModal";
import ModalProduct from "../Carrito/ModalProduct";
import { GET_PRODUCTS_BY_KITCHEN } from "../../../api";

const Platillos = ({ cocina }) => {
    const [loading, setLoading] = useState(false);
    const [menu, setMenu] = useState([]);
    const { openModal, isOpen, closeModal } = useModal();
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(6); 

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 640) { 
                setItemsPerPage(menu.length); 
            } else {
                setItemsPerPage(6);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [menu.length]);

    useEffect(() => {
        setCurrentPage(0);
    }, [cocina]);

    const fetchMenu = useCallback(async () => {
        try {
            setLoading(true);
            const data = await GET_PRODUCTS_BY_KITCHEN(cocina?.id);
            const sortedMenu = data !== null 
            ? [...data].sort((a, b) => {
                if (a.categoria === null && b.categoria === null) return 0;
                if (a.categoria === null) return 1;
                if (b.categoria === null) return -1;
                if (a.categoria !== b.categoria) {
                    return a.categoria - b.categoria;
                }
                return a.nombre.localeCompare(b.nombre);
            }) 
            : [];
            setMenu(sortedMenu);
        } catch (error) {
            setMenu([]);
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [cocina?.id]);

    useEffect(() => {
        fetchMenu();
    }, [fetchMenu]);

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = menu.slice(startIndex, endIndex);
    const totalPages = Math.ceil(menu.length / itemsPerPage);

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleAddProduct = (product) => {
        setSelectedProduct(product);
        openModal();
    };

    return (
        <div className="w-full h-fit py-4 sm:py-0 sm:h-full">
            {!cocina ? (
                <div className="text-white flex flex-col justify-center text-center items-center h-full w-full ">
                    <BsArrowUp className="text-yellow-300 flex sm:hidden" size={50} />
                    <LargeText text="Selecciona una cocina" colorText="text-yellow-300" textBold size="text-4xl sm:text-6xl" />
                    <BsArrowRight className="text-yellow-300 hidden sm:flex" size={100} />
                </div>
            ) : (
                <section className="h-full flex flex-col justify-around sm:justify-between">
                    <header className="w-full hidden sm:flex flex-row"> 
                        <div className="flex-col justify-between items-center pl-4">
                            <LargeText
                                text="Cocina seleccionada"
                                size="text-4xl"
                                textBold={true}
                            />
                            <LargeText
                                text={cocina.nombre}
                                size="text-3xl"
                                textBold={true}
                                colorText="text-yellow-300"
                            />
                            <LargeText
                                text="Selecciona los productos que deseas agregar"
                                size="text-3xl"
                                textBold={true}
                            />
                        </div>
                        <div className="hidden sm:flex pl-12">
                            {menu.length > itemsPerPage && (
                                <div className="w-auto flex flex-row px-4 space-x-4">
                                    <BsArrowLeft
                                        className={`text-yellow-300 w-16 h-12 cursor-pointer`}
                                        onClick={handlePrevPage}
                                    />
                                    <BsArrowRight
                                        className={`text-yellow-300 w-16 h-12 cursor-pointer`}
                                        onClick={handleNextPage}
                                    />
                                </div>
                            )}
                        </div>
                    </header>
                    {menu.length === 0 && loading !== true ? (
                        <div className="text-center h-full w-full flex items-center justify-center">
                            <LargeText text="No hay productos disponibles en esta cocina" size="text-4xl" textBold={true} />
                        </div>
                    ) : (
                        <div className="flex flex-row sm:flex-wrap w-full h-fit sm:h-[80%] space-x-4 sm:space-x-0 overflow-x-auto px-4 sm:px-0">
                            {currentItems.map((platillo) => (
                                <MenuCard
                                    key={platillo.id}
                                    platillo={platillo}
                                    onClick={() => handleAddProduct(platillo)}
                                />
                            ))}
                        </div>
                    )}
                </section>
            )}
            {isOpen && selectedProduct && (
                <ModalProduct
                    cocina={cocina}
                    item={selectedProduct}
                    onClose={closeModal}
                />
            )}
        </div>
    );
};

export default Platillos;
