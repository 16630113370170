import React from "react";

const Link = ({ value, width = "auto", icon = null, onClick, status, text = "text-white", productCount = 0, show="" }) => {
    const iconClass = `relative h-full flex items-center justify-center `;
    const buttonClass = `${show} ${width} h-full p-2 flex items-center hover:underline decoration-yellow-400 font-bold ${text}
        ${icon !== null && status === true ? "bg-lime-950" : ""}`;

    return (
        <button className={buttonClass} onClick={onClick}>
            {value}
            {icon !== null ? (
                <span className={iconClass}>
                    {icon}
                    {productCount > 0 && status === false && (
                        <div className="absolute bottom-0 left-0 w-6 h-6 bg-yellow-300 text-black rounded-full flex justify-center items-center">
                            {productCount}
                        </div>
                    )}
                </span>
            ) : null}
        </button>
    );
}

export default Link;
