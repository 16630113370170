import { Formik, Form } from "formik";
import React, { useState } from "react";
import InputFFAdmin from "../../AdminAtoms/InputFFAdmin";
import ButtonForm from "../../atoms/ButtonForm";
import { CREATE_BITACORA, UPDATE_CONSUMABLE } from "../../../apiAdmin";
import { toast } from "react-toastify";
import LargeText from "../../atoms/LargeText";

const SetInsumo = ({ row ,cocinas, refetch, onClose}) => {
    const [loading, setLoading] = useState(false);
    
    const handleSubmit = async (values) => {
        const transfer = Number(values.transfer);
        const nuevasMedidasTotales = row.medidas_totales - transfer;
        const newUnits = Math.ceil(nuevasMedidasTotales / row.medidas);
        const updatedValues = {
            medidas_totales: nuevasMedidasTotales,
            cantidad_disponible: newUnits,
        };
        const bitacoraValues = {
            razon: values.razon,
            cantidad: values.transfer,
            id_almacen: row.id,
            id_cocina: row.almacen.id_cocina,
        };
    
        try {
            setLoading(true);
            await UPDATE_CONSUMABLE(row.id_almacen, updatedValues);
            
            try {
                await CREATE_BITACORA(bitacoraValues);
                toast.success("Insumo agregado correctamente");
            } catch (bitacoraError) {
                console.error("Error al crear la bitácora", bitacoraError);
                toast.error("Insumo actualizado, pero hubo un error al crear la bitácora");
            } finally {
                refetch();
                onClose();
            }
        } catch (error) {
            console.error("Error al agregar el insumo", error);
            toast.error("Error al agregar el insumo");
        } finally {
            setLoading(false);
        }
    };    

    const initialValues ={
        transfer:0,
        razon:"",
    }

    const validate = (values) => {
        const errors = {};
        if (!values.transfer) { errors.transfer = "Campo requerido"; }
        if (values.transfer > row.medidas_totales ) { errors.transfer = "Supera las copas existentes"; } 
        else if (!Number.isInteger(Number(values.transfer))) { errors.transfer = "Debe ser un numero entero"} 
        else if (values.transfer <= 0) { errors.transfer ="No puede tener valor negativo o 0"; }
        if (!values.razon) { errors.razon = "Se necesita una razón" }
        return errors;
    };  

    const cocinaNombre = cocinas.find(cocina => cocina.id === row.almacen.id_cocina)?.nombre || "Cocina no encontrada";

    return(
        <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate} >
                {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-3">
                        <LargeText text={cocinaNombre} colorText="" textBold />
                        <LargeText text={row.almacen.nombre} colorText="" textBold />
                        <LargeText text={`Medidas: ${row.medidas}`} colorText="" textBold />
                        <LargeText text={`Cantidad en almacen: ${row.almacen.cantidad}`} colorText="" textBold />
                        <LargeText text={`Botellas bateo: ${row.cantidad_disponible}`} colorText="" textBold />
                        <LargeText text={`Copas bateo: ${row.medidas_totales}`} colorText="text-red-500" textBold />
                    </div>
                    <div className="flex flex-row space-x-4">
                        <div className="w-1/2 space-y-4">
                            <InputFFAdmin name="transfer" label="Copas a restar" type="number" />
                        </div>
                        <div className="w-1/2 space-y-4">
                            <InputFFAdmin name="razon" label="Razon" value="Detalle la razon" />
                        </div>
                    </div>
                    <ButtonForm 
                        text="Añadir" bgColor={loading === true ? "bg-gray-300" : "bg-violet-400" } 
                        width="w-64 ml-auto mt-6" type="submit" disabled={loading === true ? true : false} 
                    />
                </Form>
                )}
            </Formik>
        </div>
    )
}
export default SetInsumo;