import React, { useEffect, useState } from "react";
import moment from 'moment';
import { GET_HISTORIAL } from "../../apiCaja";
import { useSession } from "../../context/SessionContext";
import TablePedidos from "../admKitOrganisms/TablePedidos";
import FilterKD from "../adminOrganisms/FilterKD";

const PedidosAdmK = () => {
    const [dataOrders, setDataOrders] = useState([]);
    const [originalOrders, setOriginalOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const { user } = useSession();
    const today = moment().format('YYYY-MM-DD');

    useEffect(() => {
        const getDataOrders = async () => {         
                try {
                    setLoading(true);
                    const ordersData = await GET_HISTORIAL();
                    const ordersFiltered = ordersData.filter(order => order?.orden[user?.id]);
                    setOriginalOrders(ordersFiltered);
                    setDataOrders(ordersFiltered.filter(order => {
                        const orderDate = moment(order.createdAt);
                        const start = moment(today).startOf('day');
                        const end = moment(today).endOf('day');
                        return orderDate.isBetween(start, end, null, '[]');
                    }));
                } catch (err) {
                    console.error(err);
                } finally {
                    setLoading(false);
                }
        };
        getDataOrders();
    }, [user?.id, today]);

    useEffect(() => {
        document.title = "Historial de pedidos - Contenedor";
    }, []); 

    return (
        <div className="space-y-4">
            <FilterKD originalOrders={originalOrders} setDataOrders={setDataOrders} today={today} />
            <div className="border-2 border-violet-300 rounded-lg pt-4">
                <TablePedidos data={dataOrders} kitchenId={user?.id} loading={loading}/>
            </div>
        </div>
    );
};

export default PedidosAdmK;