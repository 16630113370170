import React, { useState } from "react";
import ButtonForm from "../atoms/ButtonForm";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import { UPDATE_KITCHEN, UPLOAD_IMAGE } from "../../apiAdmin";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";

const EditKitchenAdmn = ({ cocina, refetch, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);

    const initialValues = {
        nombre: cocina.nombre || "",
        username: cocina.user.username || "",
        descripcion: cocina.descripcion || "",
        background_color: cocina.background_color || "",
        border_color: cocina.border_color || "",
        props_color: cocina.props_color || "",
        shadow_color: cocina.shadow_color || "",
        text_color: cocina.text_color || "",
        hora_entrada: cocina.hora_entrada || "",
        hora_salida: cocina.hora_salida || "",
        password: "",
    };

    const onSubmitCocina = async (values) => {
        try {
            setLoading(true);
			let imageUrl = "";
			if (file) {
				const folder = "Cocinas";
				const uploadResponse = await UPLOAD_IMAGE(file, folder);
				imageUrl = uploadResponse.url;
			}
			const kitchenData = { ...values, imagen: imageUrl };
			await UPDATE_KITCHEN(cocina.id_user, kitchenData);
			toast.success("cocina actualizada correctamente");
            refetch();
            onClose();
		} catch (error) {
			console.error("Error al actualizar el cocina:", error);
			toast.error("Error al actulizar el cocina");
		} finally {
            setLoading(true);
        }
    }
    
    return(
        <div>
            <Formik onSubmit={onSubmitCocina} initialValues={initialValues} >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="flex flex-row space-x-4">
                            <div className="w-1/2 flex flex-col space-y-4">
                                <InputFFAdmin name="nombre" type="text" label="Nombre" />
                                <InputFFAdmin name="username" type="text" label="Nombre de usuario" disabled/>
                                <InputFFAdmin name="descripcion" label="Descripcion" type="text" />
                                <InputFFAdmin name="password" label="Contraseña" type="password" />
                        </div>
                        <div className="w-1/2 flex flex-col space-y-4">
                            <InputFFAdmin
                                name="imagen"
                                label="Imagen"
                                type="file"
                                accept="image/*"
                                onChange={(event) => setFile(event.currentTarget.files[0])}
                            />
                            <div className="flex flex-row justify-between">
                                <InputFFAdmin name="border_color" label="Borde" type="color" />
                                <InputFFAdmin name="shadow_color" label="Sombra" type="color" />
                                <InputFFAdmin name="text_color" label="Texto" type="color" />
                            </div>
                            <div className="flex flex-row justify-between">
                                <InputFFAdmin name="background_color" label="Fondo" type="color" />
                                <InputFFAdmin name="props_color" label="Botones" type="color" />
                            </div>
                        </div>
                    </div>
                        <ButtonForm 
                            text={loading ? "Cargando" : "Guardar cambios"} type="submit" width="ml-auto w-64"
                            bgColor={loading ? "bg-gray-300" : "bg-purple-300"} disabled={loading}
                        />
                </Form>
                )}
            </Formik>
        </div>
    )
}

export default EditKitchenAdmn;