import React, { useState } from "react";
import { CREATE_ADMIN_KITCHEN } from "../../apiTheLaundry/apiAdmin";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import ButtonForm from "../atoms/ButtonForm";

const CreateKitchenAdmin2 = ({ onClose, cocinas, refetch }) => {
    const [loading, setLoading] = useState(false);
    
    const initialValues = {
        username: '', password: '', role: 'CocinaAdmin',
        nombre: '', apellido: '', telefono: '', id_cocina: ""
    }

    const handleSubmit = async(values) => {
        try {
            setLoading(true);
            await CREATE_ADMIN_KITCHEN(values);
            onClose();
            refetch();
            toast.success("Admin creado correctamente");
        } catch (error) {
            console.error("Error al crear el administrador de cocina", error);
            toast.error("Error al crear el administrador")
        } finally {
            setLoading(false);
        }
    }

    const validate = (values) => {
        const errors = {};
        if (!values.nombre) { errors.nombre = "Campo requerido"; } 
        if (!values.apellido) { errors.apellido = "Campo requerido"; } 
        if (!values.password) { errors.password = "Campo requerido"; }
        if (!values.username) { errors.username = "Campo requerido"; }
        if (!values.id_cocina) { errors.id_cocina = "Campo requerido"; }
        return errors;
    };  

    const options = cocinas.map((cocina)=>({value: cocina.id, label:cocina.nombre}));

    return(
        <div className="">
            <Formik onSubmit={handleSubmit} initialValues={initialValues} validate={validate} >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="">
                        <div className="w-full flex flex-row space-x-4">
                            <div className="w-1/2 space-y-3">
                                <InputFFAdmin name="nombre" label="Nombre" />
                                <InputFFAdmin name="username" label="Nombre de usuario" />
                                <InputFFAdmin name="id_cocina" label="Cocina" options={options} type="select" />
                            </div>
                            <div className="w-1/2 space-y-3">
                                <InputFFAdmin name="apellido" label="Apellido" />
                                <InputFFAdmin name="password" label="Contraseña" type="password" />
                            </div>
                        </div>
                        <ButtonForm 
                            text={loading ? "Cargando" :"Crear"} disabled= {loading ? true : false}
                            width="w-64 ml-auto" type="submit" bgColor={loading ? "bg-gray-300" :"bg-purple-300"} 
                        />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default CreateKitchenAdmin2;