import React from "react";
import "./styles.css";

const Titulo = ({ texto, textAling="text-center", adds="", colorText="text-teal-300"}) => {
    return (
        <h1 className={`title text-2xl ${textAling} ${adds} ${colorText} `}>
            {texto}
        </h1>
    );
};

export default Titulo;
