import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";
import useModal from "../Hooks/useModal";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import PedidoCaja from "../CajaOrganism/PedidoCaja";
import { GET_PENDIENTES } from "../../apiCaja";
import { GET_WAITERS } from "../../apiAdmin";
import Ticket from "../CajaOrganism/Ticket";
import H2Custom from "../atoms/H2Custom";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { TfiUnlock } from "react-icons/tfi";

const CajaMain = () => {
	const [dataOrders, setDataOrders] = useState();
	const [dataWaiters, setDataWaiters] = useState();
	const [loading, setLoading] = useState(false);
	const { openModal, isOpen, closeModal } = useModal();
	const { openModal: openTicket, isOpen: isOpenTicket, closeModal: closeTicket } = useModal();
	const [selectedOrden, setSelectedOrden] = useState(null);
	const [idTicket, setIdTicket] = useState(null);

	const fetchDataOrders = async () => {
		try {
			setLoading(true);
			const ordersData = await GET_PENDIENTES();
			const waitersData = await GET_WAITERS();
			setDataOrders(ordersData);
			setDataWaiters(waitersData);
		} catch (err) {
			console.error(err);
			setDataOrders([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchDataOrders();
	}, []);

	useEffect(() => {
		document.title = "Caja - Contenedor";
	}, []);

	const handleOpen = (orden) => {
		openModal();
		setSelectedOrden(orden);
	};

	const handleOpenTicket = (id) => {
		setIdTicket(id);
		openTicket();
	};

	const handleClose = () => {
		closeModal();
		setSelectedOrden(null);
	};

	const getWaiterName = (id_mesero) => {
		const waiter = dataWaiters?.find((waiter) => waiter.id === id_mesero);
		return waiter ? waiter.nombre : "Desconocido";
	};

	const columns = [
		{ name: "ID", selector: (row) => row.id, sortable: true, },
		{ name: "Mesero", selector: (row) => getWaiterName(row.id_mesero), sortable: true, },
		{ name: "Mesa", selector: (row) => row.num_mesa, sortable: true, compact: true, },
		{ name: "Total", selector: (row) => `$${row.total_general} MX` },
		{
			name: "Acciones",
			selector: (row) => (
				<div className="flex flex-row space-x-2">
					<ButtonForm
						text={<TfiUnlock size={30} />}
						bgColor="bg-blue-600"
						width="w-12"
						onClick={() => handleOpen(row)}
					/>
					<ButtonForm
						text={<LiaFileInvoiceDollarSolid size={30} />}
						bgColor="bg-orange-600"
						width="w-12"
						onClick={() => handleOpenTicket(row.id)}
					/>
				</div>
			),
			width: "auto",
		},
	];

	const NoDataComponent = () => (
		<div className="h-[30vh] flex justify-center items-center">
			<LargeText
				text="No hay ordenes en caja"
				colorText="text-red-500"
				size="text-5xl"
				textBold
			/>
		</div>
	);

	return (
		<div className="w-full bg-white">
			<div className="border-2 border-black rounded-lg py-2">
				<div className="flex flex-row justify-center items-center space-x-4">
					<H2Custom
						text="Pedidos"
						textAling="text-center"
						size="text-2xl"
						colorText=""
						textBold
					/>
					<ButtonForm
						text="Actualizar"
						onClick={fetchDataOrders}
						bgColor="border border-black hover:border-0 hover:bg-yellow-300"
						textColor="text-black"
						width="w-24"
					/>
				</div>
				<DataTable
					data={dataOrders}
					columns={columns}
					noDataComponent={<NoDataComponent />}
					progressPending={loading}
					pagination
					paginationPerPage={10}
					paginationRowsPerPageOptions={[10, 20, 50, 100]}
				/>
			</div>
			{isOpen && selectedOrden !== null && (
				<ModalAdmin title="Productos" onClose={handleClose}>
					<PedidoCaja
						orden={selectedOrden}
						onClose={handleClose}
						refetch={fetchDataOrders}
					/>
				</ModalAdmin>
			)}
			{isOpenTicket && idTicket && (
				<ModalAdmin title="Imprimir" onClose={closeTicket}>
					<Ticket id={idTicket} />
				</ModalAdmin>
			)}
		</div>
	);
};

export default CajaMain;
