import React, { useEffect, useState } from "react";
import { Form, Formik, Field } from "formik";
import DataTable from "react-data-table-component";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";
import InputFFCaja from "../cajaAtoms/InputFFCaja";
import { getKitchen } from "../../api";
import { GET_WAITERS_ID } from "../../apiAdmin";
import { toast } from "react-toastify";
import { CREATE_ORDER_HISTORY_PADEL, DELETE_ORDER_PADEL } from "../../apiPadel";
import { useSession } from "../../context/SessionContext";

const PedidoCajaPadel = ({ ordenes, onClose, refetch, openTicket, setIdTicket }) => {
    const [mesero, setMesero] = useState();
    const [cocinas, setCocinas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [metodoPago, setMetodoPago] = useState("Efectivo");
    const { user } = useSession();

    useEffect(() => {
        const getInfo = async () => {
            try {
                const meseroData = await GET_WAITERS_ID(ordenes.id_mesero);
                setMesero(meseroData);
                const cocinasData = await getKitchen();
                setCocinas(cocinasData);
            } catch (err) {
                console.error(err);
            }
        };

        getInfo();
    }, [ordenes]);

    const agrupadasPorCocina = (ordenes?.orden || []).reduce((acc, orden) => {
        const { id_cocina, orden: productos } = orden;
    
        const productosArray = Array.isArray(productos) ? productos : [productos];
    
        if (!id_cocina) return acc;
        if (!acc[id_cocina]) {
            acc[id_cocina] = [];
        }
    
        acc[id_cocina].push(...productosArray);
        return acc;
    }, {});
    
    

    const agrupadasPorCocinaEstructuradas = Object.entries(agrupadasPorCocina).map(([id_cocina, productos]) => {
        const cocina = cocinas.find(c => c.id.toString() === id_cocina); 
        return {
            id_cocina,
            nombre: cocina ? cocina.nombre : "Cargando...",
            productos,     
        };
    });

    const columns = [
        { name: "Nombre", selector: row => row.nombre },
        { name: "Total de producto", selector: row => `$${row?.totalProduct} MX` },
        { name: "Cantidad", selector: row => row.quantity },
    ];

    const initialValues = {
        metodo_pago: "Efectivo",
        propina: 0,
        total_general: ordenes.total,
        total_efectivo: 0,
        total_tarjeta: 0,
        num_mesa: ordenes.num_mesa,
        tipo_orden: ordenes.tipo_orden,
        orden: agrupadasPorCocina, 
        id_mesero: ordenes.id_mesero,
        id_caja:user.id,
    };
    
    const options = [
        { label: "Efectivo", value: "Efectivo" },
        { label: "Tarjeta", value: "Tarjeta" },
        { label: "Mixto", value: "Mixto" },
    ];
    
    const validate = (values) => {
        const errors = {};
        if (!values.metodo_pago) {
            errors.metodo_pago = 'Requerido';
        }
        if (values.propina < 0) {
            errors.propina = 'No puede ser menor a 0';
        }
        if (values.metodo_pago === "Mixto") {
            if (Number(values.total_efectivo) + Number(values.total_tarjeta) !== ordenes.total) {
                errors.total_mixto = 'La suma de efectivo y tarjeta debe ser igual al total general';
            }
        }
        return errors;
    };

    const handleDeleteOrder = async () => {
        try {
            for (const orden of ordenes.orden) {
                await DELETE_ORDER_PADEL(orden.id);
            }
            toast.success("Ordenes liberadas correctamente");
        } catch (error) {
            console.error("Error al actualizar el pedido", error);
            toast.error("Error al eliminar las órdenes");
        }
    };

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            if (!values.propina) {
                values.propina = 0;
            }

            if (values.metodo_pago === "Efectivo") {
                values.total_efectivo = values.total_general;
                values.total_tarjeta = 0;
            } else if (values.metodo_pago === "Tarjeta") {
                values.total_tarjeta = values.total_general;
                values.total_efectivo = 0;
            }

            const data = await CREATE_ORDER_HISTORY_PADEL(values);
            setIdTicket(data.newOrder.id);
            toast.success("Pedido creado correctamente");
            await handleDeleteOrder();
            refetch();
            onClose();
            openTicket();
        } catch (error) {
            console.error("Error al crear el pedido", error.message || error );
            toast.error("Error al crear el pedido");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="w-full space-y-4">
            {agrupadasPorCocinaEstructuradas.map(({ id_cocina, nombre, productos }) => (
                <div className="border border-black rounded-lg" key={id_cocina}>
                    <LargeText text={`Cocina: ${nombre}`} colorText="" size="text-2xl ml-4" textAlign="text-center" textBold />
                    <DataTable columns={columns} data={productos} />
                </div>
            ))}
            <div className="w-full flex flex-row items-center justify-end space-x-4">
                <LargeText text={`Mesa: ${ordenes.num_mesa},`} colorText="" size="text-xl" textAlign="text-end" textBold />
                <LargeText text={`Mesero: ${mesero?.nombre} ${mesero?.apellido},`} colorText="" size="text-xl" textAlign="text-end" textBold />
                <LargeText text={`Total: $${ordenes.total} MX`} colorText="" size="text-xl" textAlign="text-end" textBold />
            </div>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
                {({ values, setFieldValue, errors }) => (
                    <Form className="w-full">
                        <div className="w-full flex flex-row justify-center items-center space-x-4">
                            <div className="w-1/2">
                            <label htmlFor="metodo" className="text-black text-lg font-bold">Metodo de pago</label>
                                <Field
                                    as="select" name="metodo_pago"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setMetodoPago(value);
                                        setFieldValue("metodo_pago", value);
                                    }}
                                    className="border-2 bg-transparent text-black border-black w-full p-2 pr-8 rounded-xl focus:border-red-500 focus:outline-none ring-0 disabled:cursor-not-allowed disabled:bg-black disabled:text-yellow-300"
                                >
                                    {options.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                            <div className="w-1/2">
                                <InputFFCaja name="propina" label="Propina" value="propina" type="number" />
                            </div>
                        </div>
                        
                        {metodoPago === "Mixto" && (
                            <div className="flex flex-row space-x-4">
                                <InputFFCaja name="total_efectivo" label="Total Efectivo" value="total_efectivo" type="number" />
                                <InputFFCaja name="total_tarjeta" label="Total Tarjeta" value="total_tarjeta" type="number" />
                                {errors.total_mixto && <div className="text-red-600">{errors.total_mixto}</div>}
                            </div>
                        )}

                        <ButtonForm 
                            width="w-32 ml-auto" type="submit" disabled={loading} 
                            text={loading ? "Liberando" : "Liberar"}  bgColor={loading ? "bg-gray-300" : "bg-green-700"} 
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default PedidoCajaPadel;
