import { Formik, Form } from "formik";
import React, { useState } from "react";
import InputFFAdmin from "../../AdminAtoms/InputFFAdmin";
import ButtonForm from "../../atoms/ButtonForm";
import { UPDATE_CONSUMABLE } from "../../../apiAdmin";
import { toast } from "react-toastify";
import LargeText from "../../atoms/LargeText";

const AddInsumo = ({ row ,cocinas, refetch, onClose}) => {
    const [loading, setLoading] = useState(false);
    
    const handleSubmit = async (values) => {
        const transfer = Number(values.transfer);
        const nuevaCantidadAlmacen = row.almacen.cantidad + transfer;
        const updatedValues = {
            cantidad: nuevaCantidadAlmacen,
        }
        try {
            setLoading(true);
            await UPDATE_CONSUMABLE(row.id_almacen, updatedValues);
            refetch();
            onClose();
            toast.success("Insumo agregado correctamente");
        } catch (error) {
            console.error("Error al agregar el insumo", error);
            toast.error("Error al agregar el insumo")
        } finally {
            setLoading(false);
        }
    }

    const initialValues ={
        transfer:0, 
    }

    const validate = (values) => {
        const errors = {};
        if (!values.transfer) { errors.transfer = "Campo requerido"; } 
        else if (!Number.isInteger(Number(values.transfer))) { errors.transfer = "Debe ser un numero entero"} 
        else if (values.transfer <= 0) { errors.transfer ="No puede tener valor negativo o 0"; }
        return errors;
    };  

    const cocinaNombre = cocinas.find(cocina => cocina.id === row.almacen.id_cocina)?.nombre || "Cocina no encontrada";

    return(
        <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate} >
                {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-3">
                        <LargeText text={cocinaNombre} colorText="" textBold />
                        <LargeText text={row.almacen.nombre} colorText="" textBold />
                        <LargeText text={`Medidas: ${row.medidas}`} colorText="" textBold />
                        <LargeText text={`Cantidad en almacen: ${row.almacen.cantidad}`} colorText="text-red-500" textBold />
                        <LargeText text={`Botellas bateo: ${row.cantidad_disponible}`} colorText="" textBold />
                        <LargeText text={`Copas bateo: ${row.medidas_totales}`} colorText="" textBold />
                    </div>
                    <div className="flex flex-row space-x-4">
                        <div className="w-1/2 space-y-4">
                            <InputFFAdmin name="transfer" label="Añadir al almacen" type="number" />
                        </div>
                    </div>
                    <ButtonForm 
                        text="Añadir" bgColor={loading === true ? "bg-gray-300" : "bg-violet-400" } 
                        width="w-64 ml-auto mt-6" type="submit" disabled={loading === true ? true : false} 
                    />
                </Form>
                )}
            </Formik>
        </div>
    )
}
export default AddInsumo;