import axios from "axios";

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true,
	headers: { "Content-Type": "application/json", Platform: "web" },
});

export const GET_ORDERS_CAJA_PADEL = async () => {
	try {
		const response = await api.get("/api/ordersPadelForCashier");
		return response.data;
	} catch (error) {
		console.error("Error fetching orders:", error);
		throw error;
	}
};

export const GET_ORDER_BY_ID_PADEL = async (id) => {
	try {
		const response = await api.get(`/api/orderHistoryPadel/${id}`);
		return response.data;
	} catch (error) {
		console.error("Error al obtener la orden:", error);
		throw error;
	}
};

export const CREATE_ORDER_HISTORY_PADEL = async (orderData) => {
	try {
		const response = await api.post("/api/orderHistoryPadel", orderData);
		return response.data;
	} catch (error) {
		console.error("Error al crear la orden:", error);
		throw error;
	}
};

export const DELETE_ORDER_PADEL = async (id) => {
	try {
		const response = await api.delete(`/api/orderPadel/${id}`);
		return response.status;
	} catch (error) {
		console.error("Error al eliminar la orden:", error);
		throw error;
	}
};

export const GET_HISTORIAL_PADEL = async () => {
	try {
		const response = await api.get("/api/ordersHistoryPadel");
		return response.data;
	} catch (error) {
		console.error("Error fetching historial:", error);
		throw error;
	}
};

export const GET_PRODUCTS_PADEL = async () => {
	try {
		const response = await api.get("/api/productos/padel");
		return response.data;
	} catch (error) {
		console.error("Error fetching productos padel data:", error);
		throw error;
	}
};
