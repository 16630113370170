import axios from "axios";
import { toast } from "react-toastify";

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true,
	headers: { "Content-Type": "application/json", Platform: "web" },
});

export const checkSession = async () => {
	try {
		const response = await api.get("/api/check-session");
		if (response.status === 200) {
			return response.data.user;
		} else {
			throw new Error("Sesión no válida.");
		}
	} catch (error) {
		console.error("Error al verificar la sesión:", error);
		throw error;
	}
};

export const login = async (credential, password) => {
	try {
		const response = await api.post("/api/login", { credential, password });
		toast.success("Sesion iniciada correctamente");
		return response.data;
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error) {
			toast.error(error.response.data.error);
		} else {
			toast.error("Error al iniciar sesión");
			console.error(error);
		}
	}
};

export const LOGIN_USER_EXT = async (email, password) => {
	try {
		const response = await api.post("/api/loginExternal", { email, password });
		toast.success("Sesion iniciada correctamente");
		return response.data;
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error) {
			toast.error(error.response.data.error);
		} else {
			toast.error("Error al iniciar sesión");
			console.error(error);
		}
	}
};

export const logout = async () => {
	try {
		const response = await api.post("/api/logout");
		return response.data;
	} catch (error) {
		console.error("Error al cerrar sesión", error);
		throw error;
	}
};

export const CREATE_USER = async (user) => {
	try {
		const response = await api.post("/api/registerExternal", user);
		return response.data;
	} catch (error) {
		console.error("Error al crear el usuario", error);
		throw error;
	}
};

export const getKitchen = async () => {
	try {
		const response = await api.get("/api/kitchen");
		return response.data;
	} catch (error) {
		console.error("Error al obtener las cocinas de la base de datos", error);
		throw error;
	}
};

export const getKitchenById = async (id) => {
	try {
		const response = await api.get(`/api/kitchenId/${id}`);
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 404) {
			throw new Error("No existe la cocina con el ID proporcionado");
		}
		throw new Error("Error al obtener la información de la cocina");
	}
};

export const GET_PRODUCTS_BY_KITCHEN = async (id) => {
	try {
		const response = await api.get(`/api/kitchen/${id}/products`);
		return response.data;
	} catch (error) {
		console.error("Error al obtener los productos: ", error);
		if (error.response.status === 404) {
			return error.response.status;
		} else {
			throw error;
		}
	}
};

export const GET_ORDER_BY_ID = async (orderId) => {
	try {
		const response = await api.get(`/api/orderHistory/${orderId}`);
		return response.data;
	} catch (error) {
		console.error("Error:", error.response?.data || error.message);
		throw error;
	}
};
