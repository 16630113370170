import React from "react";
import LargeText from "../atoms/LargeText";

const ProblemProduct = () => {
    return(
        <div>
            <LargeText text="- Verifica envio sin acentos" colorText="text-violet-500" size="text-2xl" textBold/>
            <LargeText text="- Revisa tu conexion a internet" colorText="text-violet-500" size="text-2xl" textBold/> 
            <LargeText text="- Revisa si hay error en algun campo al presionar enviar" colorText="text-violet-500" size="text-2xl" textBold/> 
            <LargeText text="- Revisa si al crear arroja una notificacion emergente de error " colorText="text-violet-500" size="text-2xl" textBold/> 
            <LargeText text="- El precio no debe ser menor que 0 pero si puede ser 0, aplica en extras y/o adicionales" colorText="text-violet-500" size="text-2xl" textBold/> 
            <LargeText text="- Verifica que los campos de extras y/o adicionales esten llenos, Nombre, Precio y Categoria" colorText="text-violet-500" size="text-2xl" textBold/> 
        </div>
    );
}

export default ProblemProduct;