import React, { useState } from "react";
import Circle from "../../atoms/Circle";
import LargeText from "../../atoms/LargeText";
import CardCocinaCarrito from "./CardCocinaCarrito";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

const CocinaModalCarrito = ({ cocina, products }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <div className="w-full h-24 border-b-4 border-teal-300 flex flex-row p-4 items-center justify-end">
                <Circle Width="min-w-16 sm:w-16" Height="h-16" border="border-2" cocina={cocina}/>
                <div className="pl-4 w-2/3 flex flex-col">
                    <LargeText text={cocina.nombre} size="text-2xl" textBold={true} />
                </div>
                <div className="flex flex-col h-24 pb-2 sm:pb-0 items-center justify-between sm:justify-around ml-auto w-10">
                    <div className="flex justify-center items-center ml-4" onClick={toggleOpen}>
                        {isOpen ? (
                            <MdOutlineKeyboardArrowUp className="w-10 h-10 ml-auto cursor-pointer text-white" />
                        ) : (
                            <MdOutlineKeyboardArrowDown className="w-10 h-10 ml-auto cursor-pointer text-white" />
                        )}
                    </div>
                    <div className="ml-auto h-6 w-6 bg-teal-300 rounded-full text-center">
                        {products.length}
                    </div>
                </div>
            </div>
            {isOpen && (
                <div>
                    {products.map((product, index) => (
                        <CardCocinaCarrito
                            key={`${product.id}-${index}`}
                            item={product}
                            cocina={cocina}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default CocinaModalCarrito;
