import React, { useEffect, useState, useCallback } from "react";
import LargeText from "../atoms/LargeText";
import { GET_PRODUCTS_BY_KITCHEN } from "../../api";

const ListProducts = ({ kitchenId, dataOrders }) => {
  const [productsDetails, setProductsDetails] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [leastProducts, setLeastProducts] = useState([]);

  const fetchMenu = useCallback(async () => {
    try {
      const data = await GET_PRODUCTS_BY_KITCHEN(kitchenId);
      setProductsDetails(data);
    } catch (error) {
      setProductsDetails([]);
      console.error(error.message);
    }
  }, [kitchenId]);

  useEffect(() => {
    fetchMenu();
  }, [fetchMenu]);

  useEffect(() => {
    if (dataOrders?.length > 0 && productsDetails.length > 0) {
      const productSales = {};

      dataOrders?.forEach((order) => {
        if (order?.orden && order?.orden[kitchenId]) {
          const orderProducts = order?.orden[kitchenId]?.products;
          orderProducts?.forEach((product) => {
            productSales[product.id] = (productSales[product.id] || 0) + 1;
          });
        }
      });

      const productWithSales = productsDetails?.map((product) => ({
        ...product,
        sales: productSales[product.id] || 0,
      }));

      const sortedProducts = productWithSales?.sort((a, b) => b.sales - a.sales);

      setTopProducts(sortedProducts?.slice(0, 2));
      setLeastProducts(sortedProducts?.slice(-2));
    } else {
      setTopProducts([]);
      setLeastProducts([]);
    }
  }, [dataOrders, productsDetails, kitchenId]);

  return (
    <div className="w-full flex flex-row justify-around bg-slate-200 rounded-lg p-4 space-x-4">
      {topProducts.length > 0 ? (
        <div className="w-1/2 flex flex-row space-x-4">
          <div className="w-1/2">
            <img
              alt={topProducts[0]?.nombre || "Platillo"}
              src={topProducts[0]?.imagen}
              className="w-full aspect-square border-4 border-purple-600 rounded-lg"
            />
            <LargeText
              text={`${topProducts[0]?.nombre} platillo más vendido`}
              textBold
              colorText=""
            />
          </div>
          <div className="w-1/2">
            <img
              alt={topProducts[1]?.nombre || "PLATILLO"}
              src={topProducts[1]?.imagen}
              className="w-full aspect-square border-4 border-red-500 rounded-lg"
            />
            <LargeText
              text={`${topProducts[1]?.nombre} 2do platillo más vendido`}
              textBold
              colorText=""
            />
          </div>
        </div>
      ): (
        <LargeText text="No hay datos para mostrar" colorText="text-red-600" size="text-3xl" textBold />
      )}
      {leastProducts.length > 0 && (
        <div className="w-1/2 flex flex-row space-x-4">
          <div className="w-1/2">
            <img
              alt={leastProducts[0]?.nombre || "PLATILLO"}
              src={leastProducts[0]?.imagen}
              className="w-full aspect-square border-4 border-green-600 rounded-lg"
            />
            <LargeText
              text={`${leastProducts[0]?.nombre} Platillo menos vendido`}
              textBold
              colorText=""
            />
          </div>
          <div className="w-1/2">
            <img
              alt={leastProducts[1]?.nombre || "PLATILLO"}
              src={leastProducts[1]?.imagen}
              className="w-full aspect-square border-4 border-pink-500 rounded-lg"
            />
            <LargeText
              text={`${leastProducts[1]?.nombre} 2do platillo menos vendido`}
              textBold
              colorText=""
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ListProducts;
