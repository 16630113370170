import React from "react";
import { toast } from "react-toastify";
import useModal from "../Hooks/useModal";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import ModalConfirmAdmin from "../adminMolecules/ModalConfirmAdmin";
import { DELETE_PRODUCT } from "../../apiTheLaundry/apiAdmin";
import EditProduct from "../adminOrganisms/EditProduct";

const CardProduct = ({ refetch, product }) => { 
    const {openModal, closeModal, isOpen} = useModal();
    const {openModal:openConfirm, closeModal:closeConfirm, isOpen:isOpenConfirm} = useModal();
    
    const handleDelete = async () => {
        try {
            const status = await DELETE_PRODUCT(product.id);
            if (status === 204) {
                toast.success("Producto eliminado correctamente");
                refetch();
                closeConfirm();
            } else {
                toast.error("Error al eliminar el producto");
            }
        } catch (error) {
            toast.error("Error al eliminar el producto");
        }
    }

    return(
        <div className="relative w-[18%] h-auto flex flex-col items-center p-2 rounded-lg border-2 border-orange-500 mb-4 space-y-2 shadow-md">
            <img src={product.imagen} alt="Product" className="w-3/4 aspect-square rounded-lg" />
            <LargeText text={product.nombre} textAling="text-center" colorText="" textBold />
            <LargeText text={`$ ${product.precio}`} colorText="" textBold />
            <ButtonForm text="Editar" bgColor="bg-green-600" onClick={openModal} />
            <ButtonForm text="Eliminar" bgColor="bg-red-500" onClick={openConfirm} />
            {isOpen && 
                <ModalAdmin title="Editar producto" onClose={closeModal} >
                    <EditProduct product={product} onClose={closeModal} refetch={refetch} />
                </ModalAdmin>
            }
            {isOpenConfirm &&
                <ModalConfirmAdmin onClose={closeConfirm} onConfirm={handleDelete} />
            }
        </div>
    );
};

export default CardProduct;