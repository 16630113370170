import React, { useEffect } from "react";
import VentasGrls from "../adminMolecules/VentasGrls";
import CirclesCocinas from "../adminMolecules/CirclesCocinas";
import H2Custom from "../atoms/H2Custom";

const Kitchens = () => {
    useEffect(() => {
        document.title = "Cocinas - Contenedor";
    }, []); 

    return(
        <div className="w-full h-full space-y-4">
            <H2Custom text="Cocinas" colorText="" size="text-4xl" />
            <VentasGrls/>
            <div className="w-full flex flex-row space-x-4">
                <CirclesCocinas />
            </div>    
        </div>
    );
};

export default Kitchens;