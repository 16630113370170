import React from "react";
import DataTable from "react-data-table-component";

const PedidoCocina = ({ ordenes }) => {
    const agrupadas = Object.entries(ordenes).map(([id_cocina, productos]) => {
        return {
            id_cocina,
            productos,     
        };
    });

    return(
        <div className="space-y-4">
            {agrupadas?.map(({id_cocina, productos })=>(
                <div key={id_cocina} className="border-2 border-black rounded-lg">
                    <DataTable
                        data={productos}
                        columns={columns}
                    />
                </div>
            ))}
        </div>
    )
}

const columns = [
    {name:"ID", selector: row => row.id },
    {name:"Nombre", selector: row => row.nombre },
    {name:"Precio Publico", selector: row => ` $ ${row.precio} mx` },
    {name:"Precio compra", selector: row => ` $ ${row.precio_compra} mx` },
    {name:"Precio lista", selector: row => ` $ ${row.precio_lista} mx` },
    {name:"Cantidad", selector: row => row.quantity },
    {name:"En este pedido", selector: row => ` $ ${row.quantity * row.precio} mx` },
]

export default PedidoCocina;