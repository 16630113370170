import React from "react";

const ButtonAbout = ({ text = {}, onClick }) => {
  return (
    <div className="relative flex items-center justify-center h-screen">
      <svg className="absolute invisible">
        <defs>
          <filter id="gooey">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
            />
            <feColorMatrix
              type="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -10"
            />
            <feComposite in="SourceGraphic" operator="atop" />
          </filter>
        </defs>
      </svg>
      <button
        onClick={onClick}
        className="relative min-w-40 px-8 py-4 text-black bg-teal-300 border-2 border-teal-300 rounded-sm focus:outline-none text-lg font-bold"
        style={{ filter: "url(#gooey)" }}
      >
        {text}
        <span className="absolute inset-0 flex items-center justify-center">
          {[...Array(10)].map((_, index) => (
            <span
              key={index}
              className={`absolute w-6 h-6 bg-teal-300 rounded-full animate-bubble z-[-10]`}
              style={{
                left: `${Math.random() * 100}%`,
                animationDelay: `${index * 0.2}s`,
                animationDuration: `${5 + index * 0.2}s`,
              }}
            ></span>
          ))}
        </span>
      </button>
    </div>
  );
};

export default ButtonAbout;
