import React from "react";
import iconCard1 from "../../assets/Imagenes/iconCardAbout3.webp";
import iconCard2 from "../../assets/Imagenes/iconCardAbout2.webp";
import iconCard3 from "../../assets/Imagenes/iconCardAbout1.webp";
import iconCard4 from "../../assets/Imagenes/filete.webp";
import LargeText from "../../atoms/LargeText";
import { useNavigate } from "react-router-dom";
import "../styles.css";

const CardsAbout = () => {
	const navigate = useNavigate();

	return (
		<div className="h-full w-full flex flex-wrap sm:flex-row">
			<div className="card w-1/2 sm:w-1/4 h-1/2 sm:h-full flex flex-col items-center justify-center space-y-6 px-6 pt-6 hover:bg-[#f5d000ae]">
				<img
					src={iconCard1}
					width="30%"
					alt="SERVICES"
					className="filter brightness-0 invert"
				/>
				<LargeText textBold={true} text="Variedad Culinaria" />
				<LargeText
					textBold={true}
					size="text-sm hidden sm:flex"
					textAling="text-justify"
					text="Desde sushi fresco y tacos auténticos hasta pastas artesanales y postres irresistibles, 
                        nuestras estaciones de comida ofrecen algo para todos los paladares. 
                        Cada platillo es preparado con ingredientes de la más alta calidad, garantizando una experiencia culinaria inolvidable."
				/>
			</div>
			<div className="card w-1/2 sm:w-1/4 h-1/2 sm:h-full flex flex-col items-center justify-center space-y-6 px-6 bg-[#1f1f1fae] hover:bg-[#F5D000ae]">
				<img src={iconCard2} width="30%" alt="SERVICES" />
				<LargeText textBold={true} text="Servicio a Domicilio" />
				<LargeText
					textBold={true}
					textAling="text-justify"
					size="text-sm hidden sm:flex"
					text="Sabemos que a veces prefieres disfrutar de una comida deliciosa en la comodidad de tu hogar. 
                    Por eso, ofrecemos un servicio de entrega rápida y confiable para que puedas saborear tus platos favoritos donde quieras."
				/>
			</div>
			<div className="card w-1/2 sm:w-1/4 h-1/2 sm:h-full flex flex-col items-center justify-center space-y-6 px-6 hover:bg-[#F5D000ae] bg-[#1f1f1fae] sm:bg-transparent ">
				<img
					src={iconCard3}
					width="30%"
					alt="SERVICES"
					className="filter brightness-0 invert"
				/>
				<LargeText textBold={true} text="Música en Vivo" />
				<LargeText
					size="text-sm"
					textBold={true}
					textAling="text-justify hidden sm:flex"
					text="Déjate llevar por el ritmo de nuestras bandas locales y DJs, 
                        que se presentan en vivo para crear una atmósfera vibrante y llena de energía. 
                        La música en vivo es el complemento perfecto para tu experiencia gastronómica."
				/>
			</div>
			<button
				className="card w-1/2 sm:w-1/4 h-1/2 sm:h-full flex flex-col items-center justify-center space-y-6 px-6 bg-transparent sm:bg-[#1f1f1fae] hover:bg-[#F5D000ae]"
				onClick={() => navigate("/TheLaundry")}
			>
				<img
					src={iconCard4}
					width="30%"
					alt="SERVICES"
					className="filter brightness-0 invert"
				/>
				<LargeText textBold={true} text="The Laundry" />
				<LargeText
					textBold={true}
					textAling="text-justify"
					text="¡Descubre el sabor de la auténtica parrilla texana en The Laundry! Aquí, 
                        cada corte es cocido con el verdadero estilo texano: bajo y lento, en leña y carbón, 
                        logrando un ahumado perfecto y sabores intensos. Vive una experiencia única que mezcla tradición, sabor y un ambiente inigualable"
					size="text-sm hidden sm:flex"
				/>
			</button>
		</div>
	);
};

export default CardsAbout;
