import React from "react";
import { Formik } from "formik";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigation } from "../../Hooks/useNavigation";
import ButtonForm from "../../atoms/ButtonForm";
import LargeText from "../../atoms/LargeText";
import RegisterField from "../../atoms/RegisterField";
import contenedorNeon from "../../assets/Imagenes/neon.webp";
import { toast } from "react-toastify";

const RegisterStep1 = ({ setActiveSection, setMailPhone, mailPhone }) => {
	const navigate = useNavigation();

	const initialValues = {
		emailCellphone: mailPhone || "",
	};

	const handleSubmit = (values) => {
		setActiveSection("2");
		setMailPhone(values.emailCellphone);
		toast.success("Correo enviado 📧✔️")
	};

	const handleCancel = () => {
		navigate.navigateToAbout();
	};

	const validate = (values) => {
		const errors = {};
		if (!values.emailCellphone) {
			errors.emailCellphone = "Campo requerido";
		} else if (
			!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.emailCellphone)
		) {
			errors.emailCellphone = "Debe ser un email válido";
		}
		return errors;
	};

	return (
		<div className="w-full sm:w-[33%] h-screen flex items-center justify-center py-8 px-6 sm:px-0 ">
			<Formik
				initialValues={initialValues}
				validate={validate}
				onSubmit={handleSubmit}
			>
				{({ handleSubmit, isSubmitting }) => (
					<form 
						className="w-full flex flex-col items-center justify-center text-center"
						onSubmit={handleSubmit}
					>
						<img src={contenedorNeon} alt="contenedor" />
						<LargeText text="¿Cual es tu correo electronico?" textBold={true} />
						<RegisterField
							name="emailCellphone"
							type="text"
							placeholder="Correo electronico"
						/>
						<ButtonForm
							text="Continuar"
							bgColor="bg-[#578E76]"
							type="submit"
							disabled={isSubmitting}
						/>
						<LargeText
							size="text-sm"
							textBold
							text="Al continuar, aceptas recibir llamadas, WhatsApp o SMS de Contenedores y de sus afiliados al número proporcionado, incluso de forma automática."
						/>
						<FaArrowLeftLong
							className="text-yellow-300 w-24 h-6 mt-4 cursor-pointer"
							onClick={handleCancel}
						/>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default RegisterStep1;
