import React, { useState } from "react";
import { Formik, Form } from "formik";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import ButtonForm from "../atoms/ButtonForm";
import { CREATE_KITCHEN, UPLOAD_IMAGE } from "../../apiTheLaundry/apiAdmin";
import { toast } from "react-toastify";

const CreateKitchen = ({ onClose, refetch, setKitchenId, openAdmin }) => {
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState(null);

	const handleSubmit = async (values) => {
		try {
			setLoading(true);

			let imageUrl = "";
			if (file) {
				const folder = "Cocinas";
				const uploadResponse = await UPLOAD_IMAGE(file, folder);
				imageUrl = uploadResponse.url;
			}

			const kitchenData = { ...values, imagen: imageUrl };
			const kitchen = await CREATE_KITCHEN(kitchenData);
			setKitchenId(kitchen.id);
			toast.success("Cocina creada correctamente");
			onClose();
			openAdmin();
			refetch();
		} catch (error) {
			console.error("Error al crear la cocina:", error);
		} finally {
			setLoading(false);
		}
	};

	const initialValues = {
		username: "",
		password: "",
		role: "Cocina",
		nombre: "",
		descripcion: "",
		background_color: "",
		border_color: "",
		props_color: "",
		shadow_color: "",
		text_color: "",
		hora_entrada: "",
		hora_salida: "",
	};

	const options = [
		{ label: "Cocina", value: "Cocina" },
		{ label: "Bar", value: "Bar" },
		{ label: "Cocina Padel", value: "CocinaPadel" },
	];

	const validate = (values) => {
		const errors = {};
		if (!values.nombre) {
			errors.nombre = "Campo requerido";
		}
		if (!values.password) {
			errors.password = "Campo requerido";
		}
		if (!values.username) {
			errors.username = "Campo requerido";
		}
		if (!values.hora_entrada) {
			errors.hora_entrada = "Campo requerido";
		}
		if (!values.hora_salida) {
			errors.hora_salida = "Campo requerido";
		}
		return errors;
	};

	return (
		<div className="w-full">
			<Formik
				onSubmit={handleSubmit}
				initialValues={initialValues}
				validate={validate}
			>
				{({ handleSubmit }) => (
					<Form onSubmit={handleSubmit} className="">
						<div className="w-full flex flex-col md:flex-row space-x-0 md:space-x-4">
							<div className="w-full md:w-1/2 space-y-3">
								<InputFFAdmin name="nombre" label="Nombre" />
								<InputFFAdmin name="username" label="Nombre de usuario" />
								<InputFFAdmin
									name="imagen"
									label="Imagen"
									type="file"
									accept="image/*"
									onChange={(event) => setFile(event.currentTarget.files[0])}
								/>
								<div className="w-full flex flex-row space-x-4 md:space-x-2">
									<InputFFAdmin
										name="hora_entrada"
										label="Hora de entrada"
										type="time"
									/>
									<InputFFAdmin
										name="hora_salida"
										label="Hora de salida"
										type="time"
									/>
								</div>
								<div className="flex flex-row space-x-4 md:space-x-2">
									<InputFFAdmin
										name="background_color"
										label="Fondo"
										type="color"
									/>
									<InputFFAdmin
										name="border_color"
										label="Borde"
										type="color"
									/>
									<InputFFAdmin
										name="props_color"
										label="Botones"
										type="color"
									/>
								</div>
							</div>
							<div className="w-full md:w-1/2 space-y-3">
								<InputFFAdmin
									name="role"
									options={options}
									type="select"
									label="Tipo"
								/>
								<InputFFAdmin
									name="password"
									label="Contraseña"
									type="password"
								/>
								<InputFFAdmin name="descripcion" label="Descripcion" />
								<div className="flex flex-row space-x-4">
									<InputFFAdmin name="text_color" label="Texto" type="color" />
									<InputFFAdmin
										name="shadow_color"
										label="Sombra"
										type="color"
									/>
								</div>
							</div>
						</div>
						<ButtonForm
							text={loading ? "Cargando" : "Crear"}
							bgColor={loading ? "bg-gray-300" : "bg-purple-300"}
							width="w-full md:w-64 ml-auto"
							type="submit"
							disabled={loading ? true : false}
						/>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default CreateKitchen;
