import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { GET_WAITERS_ID } from "../../apiTheLaundry/apiAdmin";
import { getKitchen } from "../../apiTheLaundry/api";
import LargeText from "../atoms/LargeText";
import moment from "moment";

const PedidoHistorial = ({ ordenes, row }) => {
    const [cocinas, setCocinas] = useState([]);
    const [mesero, setMesero] = useState([]);

    useEffect(() => {
        const getInfo = async () => {
            try {
                const meseroData = await GET_WAITERS_ID(row.id_mesero);
                setMesero(meseroData);
                const cocinasData = await getKitchen();
                setCocinas(cocinasData);
            } catch (err) {
                console.error(err);
            }
        };

        getInfo();
    }, [row.id_mesero]);

    const agrupadas = Object.entries(ordenes).map(([id_cocina, productos]) => {
        const cocina = cocinas.find(c => c?.id?.toString() === id_cocina); 
        return {
            id_cocina,
            nombre: cocina ? cocina?.nombre : "Cargando...",
            productos,     
        };
    });

    return(
        <div className="space-y-4">
            {agrupadas?.map(({id_cocina, nombre, productos })=>(
                <div key={id_cocina} className="border-2 border-black rounded-lg">
                    <LargeText text={nombre} colorText="" size="text-2xl ml-4" textAlign="text-center" textBold />
                    <DataTable
                        columns={columns}
                        data={productos}
                    />
                </div>
            ))}
            <div className="grid grid-cols-3">
                <LargeText text={`Fecha: ${moment(row.createdAt).format("DD/MM/YYYY HH:mm")}`} colorText="" textBold/>
                <LargeText text={`Mesa: ${row.num_mesa}`} colorText="" textBold/>
                <LargeText text={`Mesero: ${mesero.nombre}`} colorText="" textBold/>
                <LargeText text={`Propina: $${row.propina} MX`} colorText="" textBold/>
                <LargeText text={`Pago en: ${row.metodo_pago}`} colorText="" textBold/> 
                {row.metodo_pago === "Mixto" ? (                    
                    <div>
                        <LargeText text={`Total: $${row.total_general} MX`} colorText="" textBold/>
                        <LargeText text={`Efectivo: $${row.total_efectivo} MX`} colorText="" textBold/>
                        <LargeText text={`Tarjeta: $${row.total_tarjeta} MX`} colorText="" textBold/>
                    </div>
                    ) : (
                        <LargeText text={`Total: $${row.total_general} MX`} colorText="" textBold/>
                    )
                }
            </div>
        </div>
    )
}

export default PedidoHistorial;

const columns = [
    { name: "Nombre", selector: row => row.nombre, sortable: true },
    { name: "Cantidad", selector: row => row.quantity, sortable: true },
    { name: "Total", selector: row => `$${row.totalProduct} MX`, sortable: true },
]