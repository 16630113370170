import React, { useState } from "react";
import { Formik } from "formik";
import ButtonForm from "../atoms/ButtonForm";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import { toast } from "react-toastify";
import { UPDATE_KITCHEN, UPLOAD_IMAGE } from "../../apiAdmin";

const EditKitchen = ({ onClose, cocina, refetch }) => {
	const [file, setFile] = useState(null);
	const handleSubmit = async (values) => {
		try {
			let imageUrl = "";
			if (file) {
				const folder = "Cocinas";
				const uploadResponse = await UPLOAD_IMAGE(file, folder);
				imageUrl = uploadResponse.url;
			}
			const kitchenData = { ...values, imagen: imageUrl };
			await UPDATE_KITCHEN(cocina.id_user, kitchenData);
			toast.success("cocina actualizada correctamente");
			refetch();
			onClose();
		} catch (error) {
			console.error("Error al actualizar el cocina:", error);
			toast.error("Error al actulizar el cocina");
		}
	};

	const initialValues = {
		nombre: cocina.nombre || "",
		descripcion: cocina.descripcion || "",
		background_color: cocina.background_color || "",
		border_color: cocina.border_color || "",
		props_color: cocina.props_color || "",
		shadow_color: cocina.shadow_color || "",
		text_color: cocina.text_color || "",
		hora_entrada: cocina.hora_entrada || "",
		hora_salida: cocina.hora_salida || "",
	};

	const validate = (values) => {
		const errors = {};
		if (!values.nombre) {
			errors.nombre = "Campo requerido";
		}
		if (!values.hora_entrada) {
			errors.hora_entrada = "Campo requerido";
		}
		if (!values.hora_salida) {
			errors.hora_salida = "Campo requerido";
		}
		return errors;
	};

	return (
		<div>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validate={validate}
			>
				{({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<div className="w-full flex flex-row space-x-4">
							<div className="w-1/2 flex flex-col space-y-4">
								<InputFFAdmin
									name="nombre"
									label="Nombre(s)"
									type="text"
									placeholder="Nombre(s)"
								/>
								<InputFFAdmin
									name="imagen"
									label="Imagen"
									type="file"
									accept="image/*"
									onChange={(event) => setFile(event.currentTarget.files[0])}
								/>
								<div className="flex flex-row justify-between">
									<InputFFAdmin
										name="border_color"
										label="Borde"
										type="color"
									/>
									<InputFFAdmin
										name="shadow_color"
										label="Sombra"
										type="color"
									/>
									<InputFFAdmin name="text_color" label="Texto" type="color" />
								</div>
							</div>
							<div className="w-1/2 flex flex-col space-y-4">
								<InputFFAdmin
									name="descripcion"
									label="Descripcion"
									type="text"
									placeholder="Descripcion de la cocina"
								/>
								<div className="w-full flex flex-row space-x-4">
									<InputFFAdmin
										name="hora_entrada"
										label="Hora de entrada"
										type="time"
									/>
									<InputFFAdmin
										name="hora_salida"
										label="Hora de salida"
										type="time"
									/>
								</div>
								<div className="flex flex-row justify-between">
									<InputFFAdmin
										name="background_color"
										label="Fondo"
										type="color"
									/>
									<InputFFAdmin
										name="props_color"
										label="Botones"
										type="color"
									/>
								</div>
							</div>
						</div>
						<ButtonForm
							bgColor="bg-violet-300"
							width="w-1/3 ml-auto"
							text="Guardar cambios"
							type="submit"
						/>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default EditKitchen;
