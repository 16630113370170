import { Formik, Form } from "formik";
import React, { useState } from "react";
import InputFFAdmin from "../../AdminAtoms/InputFFAdmin";
import ButtonForm from "../../atoms/ButtonForm";
import { CREATE_CONSUMABLE } from "../../../apiTheLaundry/apiAdmin";
import { toast } from "react-toastify";

const CreateInsumo = ({ cocinas, refetch, onClose}) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            await CREATE_CONSUMABLE(values);
            refetch();
            onClose();
            toast.success("Insumo creado correctamente");
        } catch (error) {
            console.error("Error al crear el insumo", error);
            toast.error("Error al crear el insumo")
        } finally {
            setLoading(false);
        }
    }

    const options = cocinas?.map(cocina => ({
        value: cocina.id, label: cocina.nombre
    }))

    const initialValues ={
        nombre:"", id_cocina:"", cantidad:0,  medidas:"",
        cantidad_disponible:0, medidas_totales:0, precio_compra: 0,
    }

    const validate = (values) => {
        const errors = {};
        if (!values.nombre) { errors.nombre = "Campo requerido"; }
        if (!values.medidas) { errors.medidas = "Campo requerido"; } 
        else if (!Number.isInteger(Number(values.medidas))) { errors.medidas = "Debe ser un número entero"; } 
        else if (values.medidas <= 0) { errors.medidas ="No puede tener valor negativo ó 0"; }
        if (values.cantidad < 0) { errors.cantidad = "No puede tener valor negativo"; }
        if (values.precio_compra < 0) { errors.precio_compra = "No puede tener valor negativo"; }
        if (!values.id_cocina) { errors.id_cocina = "Campo requerido"; }
        return errors;
    };  

    return(
        <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate} >
                {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="flex flex-row space-x-4">
                        <div className="w-1/2 space-y-4">
                            <InputFFAdmin name="nombre" value="nombre" label="Nombre" />
                            <InputFFAdmin name="cantidad" value="cantidad" label="Cantidad" type="number" />
                            <InputFFAdmin name="precio_compra" label="Costo al comprar" type="number" />
                        </div>
                        <div className="w-1/2 space-y-4">
                            <InputFFAdmin name="medidas" value="medidas" label="Copas por botella" type="number" />
                            <InputFFAdmin name="id_cocina" label="Bar" type="select" options={options} />
                        </div>
                    </div>
                    <ButtonForm 
                        text="Crear" bgColor={loading === true ? "bg-gray-300" : "bg-violet-400" } 
                        width="w-64 ml-auto mt-6" type="submit" disabled={loading === true ? true : false} 
                    />
                </Form>
                )}
            </Formik>
        </div>
    )
}
export default CreateInsumo;