import React from "react";
import { linksCajaPadel } from "../data/DataLinksCaja";
import LinkCaja from "../cajaAtoms/LinkCaja";

const MenuLinksPadel = () => {
    return(
      <ul className="w-full">
        {linksCajaPadel.map((link)=> (
            <li key={link.path}>
                <LinkCaja
                    name={link.name}
                    path={link.path}
                    icon={link.icon}
                    childs={link.childs}
                />
            </li>
        ))}
      </ul>
    );
};

export default MenuLinksPadel;