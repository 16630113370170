import React from "react";
import "./styles.css";

const Button = ({ text, onClick, isFilled, largeMargin, mainButton, width="w-64", ...rest }) => {
  const buttonClass = `button ${width} text-white h-12 rounded-full${
    isFilled ? "bg-blue-500 text-white" : "bg-transparent "
  } ${largeMargin ? "border-4 border-yellow-300 hover:bg-yellow-300 hover:text-black hover:text-[#FCFF5A] " : "border border-white"} 
    px-4 py-2 rounded transition duration-300 ease-in-out`;

  return (
    <button
      className={buttonClass}
      onClick={onClick}
      {...rest}
    >
      {text}
      {mainButton && (
        <>
          <span className="button-span"/><span className="button-span"/><span className="button-span"/><span className="button-span"/>
        </>
      )}
    </button>
  );
};

export default Button;
