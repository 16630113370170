import React from "react";
import LargeText from "./LargeText";
import { toast } from "react-toastify";

const MenuCard = ({platillo, onClick}) =>{
    const price = Number(platillo.precio);
    const formattedPrice = price.toLocaleString();

    return(
        <button 
            className={`h-44 sm:h-[50%] min-w-44 max-w-44 sm:min-w-[33.33%] flex flex-col 
                        ${platillo.status === "Oculto" ? ("border-2 border-black hover:border-red-500 focus:border-red-500 cursor-not-allowed")
                            : ("border-2 border-gray-800 sm:border-transparent hover:border-green-500 focus:border-green-300") } `}
            onClick={platillo.status === "Oculto" ? ()=>toast.warn("Producto no disponible") : onClick }
        >
            <div className="h-1/2 flex justify-center items-center">
                <img src={platillo.imagen} className={`w-[40%] ${platillo.count <= 0 ? ("opacity-50"):("")} rounded-lg sm:rounded-none `} alt="Logo" />
            </div>
            <div className="w-full h-1/2 px-2">
                <LargeText text={platillo.nombre} textBold colorText="text-yellow-300"/>
                <div className="hidden sm:flex">
                    <LargeText
                        text={platillo.descripcion}
                        textBold={true}
                        size={`sm:justify-center line-clamp-3 text-sm ${platillo.count <= 0 ? "opacity-50" : "" }`}
                        textAling="text-justify"
                    />
                </div>
                {platillo.status === "Oculto" ? 
                    <LargeText text="Platillo no disponible" textBold colorText="text-red-500"/>
                :
                    <LargeText text={`$ ${formattedPrice}`} textBold colorText="text-yellow-300"/>
                }
            </div>
        </button>
    );
}

export default MenuCard;