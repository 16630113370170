import React, { useState } from "react";
import RadioButtonsList from "../../atoms/RadioButtonsList";

const CambiarDireccion = ({ Usuario }) => {
    const [selectedOption, setSelectedOption] = useState(
        Usuario.direcciones.find(direccion => direccion.main).id
    );
    const handleOptionChange = (event) => {
        setSelectedOption(Number(event.target.value));
    };

    const options = Usuario.direcciones.map(direccion => ({
        value: direccion.id,
        label: direccion.calle +", "+ direccion.colonia +", "+direccion.cp +", "+ direccion.municipio
    }));

    return(
        <div className="space-y-6">
            <RadioButtonsList
                name="direcciones"
                options={options}
                selectedOption={selectedOption}
                onChange={handleOptionChange}
            />
        </div>
    );
} 

export default CambiarDireccion