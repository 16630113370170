import React from "react";
import LinkCaja from "./LinkCaja";
import { linksCaja } from "../data/DataLinksCaja";

const MenuLinksCaja = () => {
    return(
      <ul className="w-full">
        {linksCaja.map((link)=> (
            <li key={link.path}>
                <LinkCaja
                    name={link.name}
                    path={link.path}
                    icon={link.icon}
                    childs={link.childs}
                />
            </li>
        ))}
      </ul>
    );
};

export default MenuLinksCaja;