import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DELETE_ADMIN, GET_ADMINS } from "../../apiTheLaundry/apiAdmin";
import { MdOutlineEdit, MdOutlineDeleteOutline } from "react-icons/md";
import DataTable from "react-data-table-component";
import ButtonForm from "../atoms/ButtonForm";
import useModal from "../Hooks/useModal";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import ModalConfirmAdmin from "../adminMolecules/ModalConfirmAdmin";
import CreateAdmin from "../adminOrganisms/CreateAdmin";
import EditAdmin from "../adminOrganisms/EditAdmin";
import H2Custom from "../atoms/H2Custom";

const AdminsLaundry = () => {
    const [admin, setAdmin] = useState();
    const [admins, setAdmins] = useState();
    const { openModal, isOpen, closeModal } = useModal();
    const { openModal: openEdit, isOpen: isOpenEdit, closeModal: closeEdit } = useModal();
    const { openModal: openDelete, isOpen: isOpenDelete, closeModal: closeDelete } = useModal();

    const fetchAdmins = async () => {
        try {
            const data = await GET_ADMINS();
            setAdmins(data);
        } catch(error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchAdmins();
    }, []);

    useEffect(() => {
        document.title = "Administradores - The Laundry";
    }, []); 

    const handleClose = () => {
        closeEdit();
        closeDelete();
        setAdmin(null);
    }

    const handleOpenEdit = (row) => {
        openEdit();
        setAdmin(row);
    }

    const handleOpenDelete = (row) => {
        openDelete();
        setAdmin(row);
    }

    const handleDeleteAdmin = async () => {
        try {
            await DELETE_ADMIN(admin?.id_user);
            toast.success("Administrador eliminado correctamente");
            handleClose();
            fetchAdmins();
        } catch (error) {
            toast.error("Error al eliminar el administrador");
        }
    }

    const columns = [ 
        { name: "ID", selector: row => row.id },
        { name: "Nombre de usuario", selector: row => row.user?.username },
        { name: "Nombre", selector: row => row.nombre },
        { name: "Apellido", selector: row => row.apellido },
        { name: "Acciones", selector: row => 
            <div className="flex flex-row space-x-2">
                <ButtonForm text={<MdOutlineEdit size={35}/>} onClick={() => handleOpenEdit(row)} bgColor="bg-blue-300" width="w-12"/>
                <ButtonForm text={<MdOutlineDeleteOutline size={35}/>} onClick={()=> handleOpenDelete(row)}  bgColor="bg-red-500" width="w-12"/>
            </div>
        }
    ];

    return(
        <div>
            <div className="flex flex-row justify-between items-center">
                <H2Custom text="Administradores" colorText="" textBold size="text-4xl"/>
                <ButtonForm text="Crear administrador" bgColor="bg-violet-300" width="w-64" onClick={openModal} />
            </div>
            <div className="border-2 border-gray-300 rounded-lg shadow-lg">
                <DataTable
                    data={admins}
                    columns={columns}
                />
            </div>
            {isOpen && 
                <ModalAdmin title="Agregar administrador" onClose={closeModal}>
                    <CreateAdmin onClose={closeModal} refetch={fetchAdmins} />
                </ModalAdmin>
            }
            {isOpenEdit && admin !== null && 
                <ModalAdmin title="Editar administrador" onClose={handleClose}>
                    <EditAdmin row={admin} onClose={handleClose} refetch={fetchAdmins} />
                </ModalAdmin>
            }
            {isOpenDelete && admin !== null &&
                <ModalConfirmAdmin onConfirm={handleDeleteAdmin} onClose={handleClose} />
            }
        </div>
    )
}

export default AdminsLaundry;