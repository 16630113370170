import React, { useState } from "react";
import DataTable from "react-data-table-component";
import useModal from "../Hooks/useModal";
import ButtonForm from "../atoms/ButtonForm";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import LargeText from "../atoms/LargeText";
import { SiMicrosoftexcel } from "react-icons/si";
import PedidoCocina from "./PedidoCocina";

const TableKD = ({ data, kitchenId }) => {
    const [orden, setOrden] = useState(null);
    const { openModal, closeModal, isOpen } = useModal();

    const calculateTotalProducts = (orden) => {
        return orden.reduce((total, item) => total + item.totalProduct, 0);
    };

    const calculateGananciaCocina = (orden) => {
        return orden.reduce((total, item) => total + (item.precio_compra * item.quantity), 0);
    };

    const calculateGananciasTotales = () => {
        return data.reduce((totals, row) => {
            const orden = row.orden[kitchenId] || [];
            const totalGeneral = calculateTotalProducts(orden);
            const gananciaCocina = calculateGananciaCocina(orden);
            const gananciaContenedor = totalGeneral - gananciaCocina;

            return {
                gananciaCocina: totals.gananciaCocina + gananciaCocina,
                gananciaContenedor: totals.gananciaContenedor + gananciaContenedor,
                gananciaTotal: totals.gananciaTotal + gananciaCocina + gananciaContenedor,
            };
        }, { gananciaCocina: 0, gananciaContenedor: 0, gananciaTotal: 0 });
    };

    const { gananciaCocina, gananciaContenedor, gananciaTotal } = calculateGananciasTotales();

    const handleOpen = (orden) => {
        const ordenFiltrada = orden[kitchenId] || {}; 
        openModal();
        setOrden(ordenFiltrada);
    };

    const columns = [
        {
            name: "ID",
            selector: row => row.id
        },
        {
            name: "Pago",
            selector: row => row.metodo_pago,
        },
        {
            name: "Total",
            selector: row => `$${calculateTotalProducts(row.orden[kitchenId])} MX`,
        },
        {
            name: "Ganancia Cocina",
            selector: row => {
                const gananciaCocina = calculateGananciaCocina(row.orden[kitchenId]);
                return `$${gananciaCocina.toFixed(2)} MX`;
            }
        },
        {
            name: "Ganancia Contenedor",
            selector: row => {
                const totalGeneral = calculateTotalProducts(row.orden[kitchenId]);
                const gananciaCocina = calculateGananciaCocina(row.orden[kitchenId]);
                const gananciaContenedor = totalGeneral - gananciaCocina;
                return `$${gananciaContenedor.toFixed(2)} MX`;
            }
        },
        {
            name: "Acciones",
            selector: row => (
                <ButtonForm
                    text="Ver productos"
                    bgColor="bg-orange-500"
                    width="w-28"
                    onClick={() => handleOpen(row.orden)}
                />
            )
        },
    ];

    const convertArrayOfObjectsToCSV = (array) => {
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);

        let result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            const row = keys.map(key => item[key]).join(columnDelimiter);
            result += row + lineDelimiter;
        });

        return result;
    };

    const downloadCSV = (array) => {
        const csvData = convertArrayOfObjectsToCSV(array); 
        const link = document.createElement('a');
        const filename = 'reporteContenedor.csv';

        let csv;

        if (!csvData.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csvData}`;
        } else {
            csv = csvData;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    };

    const handleExport = () => {
        const exportData = data.map(row => ({
            id: row.id,
            metodo_pago: row.metodo_pago,
            total: calculateTotalProducts(row.orden[kitchenId]),
            ganancia_cocina: calculateGananciaCocina(row.orden[kitchenId]),
            ganancia_contenedor: calculateTotalProducts(row.orden[kitchenId]) - calculateGananciaCocina(row.orden[kitchenId]),
        }));

        exportData.push({
            id: 'Total',
            metodo_pago: '',
            total: gananciaTotal.toFixed(2),
            ganancia_cocina: gananciaCocina.toFixed(2),
            ganancia_contenedor: gananciaContenedor.toFixed(2),
        });

        downloadCSV(exportData);
    };

    return (
        <div className="bg-white rounded-lg shadow-lg">
            <ButtonForm onClick={handleExport} bgColor="bg-green-700" width="w-32 ml-auto mr-4" text={`Exportar`} icon={<SiMicrosoftexcel size={30}/>} />
            <DataTable
                data={data}
                columns={columns}
                fixedHeader
                fixedHeaderScrollHeight="63vh"
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 50, 100]}
            />
            <LargeText colorText="ml-2" text={`Total ganancia general: $${gananciaTotal.toFixed(2)} MX`} textBold />
            <LargeText colorText="ml-2" text={`Total ganancia de cocina: $${gananciaCocina.toFixed(2)} MX`} textBold />
            <LargeText colorText="ml-2" text={`Total ganancia de contenedor: $${gananciaContenedor.toFixed(2)} MX`} textBold />
            {isOpen &&
                <ModalAdmin title="Orden" onClose={closeModal}>
                    <PedidoCocina ordenes={[orden]} />
                </ModalAdmin>
            }
        </div>
    );
};

export default TableKD;
