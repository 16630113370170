import React from "react";
import LinkAdmin from "./LinkAdmin";
import { links } from "../data/DataLinksAdmin";

const MenuLinks = () => {
    return(
      <ul className="w-full">
        {links.map((link)=> (
            <li key={link.path}>
                <LinkAdmin
                    name={link.name}
                    path={link.path}
                    icon={link.icon}
                    childs={link.childs}
                />
            </li>
        ))}
      </ul>
    );
};

export default MenuLinks;