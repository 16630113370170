import React, { useEffect } from "react";
import LargeText from "../atoms/LargeText";
import { useNavigate } from "react-router-dom";
import { MdRestaurantMenu, MdDeliveryDining } from "react-icons/md";
import { FaCashRegister, FaKitchenSet } from "react-icons/fa6";
import { PiUsersThreeLight, PiChefHat } from "react-icons/pi";
import { GrUserAdmin } from "react-icons/gr";
import H2Custom from "../atoms/H2Custom";

const Users = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Usuarios - Contenedor";
    }, []); 

    return(
        <div className="w-full flex flex-col">
            <div className="w-full">
                <H2Custom text="Administra los usuarios" colorText="" size="text-2xl md:text-4xl" textBold/>
            </div>
            <div className="w-full flex flex-col md:flex-row space-x-0 md:space-x-4">
                <div className="w-full md:w-1/2 flex flex-col space-y-4">
                    <button 
                        className="w-full h-40 bg-green-500 rounded-lg flex flex-col justify-center items-center space-y-4"
                        onClick={()=>navigate("/cajas")}
                    >
                        <FaCashRegister size={70} className="text-white" />
                        <LargeText text="Cajas" textBold size="text-xl"/>
                    </button>
                    <button 
                        className="w-full h-40 bg-blue-500 rounded-lg flex flex-col justify-center items-center space-y-4"
                        onClick={()=>navigate("/meseros")}
                    >
                        <MdRestaurantMenu size={70} className="text-white" />
                        <LargeText text="Meseros" textBold size="text-xl"/>                        
                    </button>
                    <button 
                        className="w-full h-40 bg-orange-500 rounded-lg flex flex-col justify-center items-center space-y-4"
                        onClick={()=>navigate("/admins")}
                    >
                        <GrUserAdmin size={70} className="text-white" />
                        <LargeText text="Administradores" textBold size="text-xl"/>                        
                    </button>
                    <button 
                        className="w-full h-40 bg-blue-500 rounded-lg flex flex-col justify-center items-center space-y-4 disabled:bg-gray-300"
                        disabled
                        onClick={()=>navigate("/repartidores")}
                    >
                        <MdDeliveryDining size={70} className="text-white" />
                        <LargeText text="Repartidores" textBold size="text-xl"/>                        
                    </button>
                </div>
                <div className="w-full md:w-1/2 flex flex-col space-y-4 mt-4 md:mt-0">
                    <button 
                        className="w-full h-40 bg-red-500 rounded-lg flex flex-col justify-center items-center space-y-4"
                        onClick={()=>navigate("/UsuariosCocinas")}
                    >
                        <FaKitchenSet size={70} className="text-white" />
                        <LargeText text="Cocinas" textBold size="text-xl" colorText="text-white"/>
                    </button>
                    <button 
                        className="w-full h-40 bg-pink-500 rounded-lg flex flex-col justify-center items-center space-y-4"
                        onClick={()=>navigate("/UsuariosAdminCocinas")}
                    >
                        <PiChefHat size={70} className="text-white" />
                        <LargeText text="Administradores de cocinas" textBold size="text-xl" colorText="text-white"/>                        
                    </button>
                    <button 
                        className="w-full h-40 bg-blue-500 rounded-lg flex flex-col justify-center items-center space-y-4 disabled:bg-gray-300"
                        disabled
                        onClick={()=>navigate("/clientes")}
                    >
                        <PiUsersThreeLight size={70} className="text-white" />
                        <LargeText text="Clientes" textBold size="text-xl"/>                       
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Users;