import React from "react";
import { useNavigation } from "../Hooks/useNavigation";
import { MdLocationOn } from "react-icons/md";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import LargeText from "../atoms/LargeText";

const socialLinks = [
  { href: "https://www.facebook.com/profile.php?id=61559936656892&mibextid=LQQJ4d", icon: <FaFacebookF size={30} /> },
  { href: "https://www.instagram.com/contenedor_pue?igsh=MXkyaHd1NTQ0MHFnZQ==", icon: <FaInstagram size={30} /> },
  { href: "https://www.tiktok.com/@contenedor_pue?_t=8oIq3JaIQEg&_r=1", icon: <FaTiktok size={30} /> },
];

const FooterMenu = () => {
  const navigate = useNavigation();

  const renderSocialLinks = (classNames = "") => (
    <div className={`flex flex-row justify-around items-center space-x-2 text-white ${classNames}`}>
      {socialLinks.map(({ href, icon }, index) => (
        <a key={index} href={href} target="_blank" className="cursor-pointer" rel="noopener noreferrer">
          {icon}
        </a>
      ))}
    </div>
  );

  return (
    <div className="bg-[#006437] w-screen sm:h-40 flex flex-col sm:flex-row">
        <div className="hidden sm:flex flex-col justify-center pl-2 h-full w-[40%]">
          <a href="https://maps.app.goo.gl/SM4SL2JWLQYhDSoZ8" target="_blank" className="w-auto" rel="noopener noreferrer">
            {["Lateral Sur de la Vía Atlixcayotl, 6823", "San Antonio Cacalotepec, 72828", "Heroica Puebla de Zaragoza, Puebla, México"].map((text, index) => (
              <LargeText key={index} textBold={true} text={text} />
            ))}
          </a>
        </div>
      <div className="h-full w-full sm:w-[30%] text-center">
        <div className="h-[40%] flex flex-col">
          <div className="hidden sm:hidden justify-around w-full">
            <LargeText text="Registrar" cursor="cursor-pointer" onClick={navigate.navigateToRegister} />
            <LargeText text="Ingresar" cursor="cursor-pointer" onClick={navigate.navigateToLogin} />
          </div>
        </div>
        <div className="w-full flex flex-col sm:hidden">
          <div className="w-full flex flex-row px-4 py-4 ">
            <div className="w-1/2 flex flex-col items-center justify-center">
              <LargeText text="Redes Sociales" textBold={true} />
              {renderSocialLinks()}
            </div>
            <div className="w-1/2 flex flex-row items-center justify-around text-white">
              <a href="https://www.google.com/maps/place/Contenedor+Puebla/@19.0039722,-98.2711944,15z/data=!4m6!3m5!1s0x85cfc7002b792c67:0xa2d22f664f886edd!8m2!3d19.0039722!4d-98.2711944!16s%2Fg%2F11y92f8m9t?entry=ttu&g_ep=EgoyMDI0MTExOS4yIKXMDSoASAFQAw%3D%3D" target="_blank" className="cursor-pointer text-white" rel="noopener noreferrer">
                <MdLocationOn size={33} className="cursor-pointer" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="h-full w-[40%]">
        <div className="h-[70%] hidden sm:flex flex-col justify-center items-end space-y-4 pr-16">
          <LargeText text="Redes Sociales" textBold={true} />
          <div className="flex flex-row space-x-3">
            {socialLinks.map(({ href, icon }, index) => (
              <a key={index} href={href} target="_blank" className="cursor-pointer text-white" rel="noopener noreferrer">
                {icon}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterMenu;