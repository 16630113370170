import React from "react";
import LargeText from "../atoms/LargeText";

const CardMenuPublic = ({ product }) => { 
    const price = Number(product.precio);
    const formattedPrice = price.toLocaleString();
    
    return(
        <div className="relative w-full sm:w-64 h-auto flex flex-col justify-between items-center p-2 rounded-lg border-2 border-white mb-4 space-y-2 shadow-md">
            <div className="w-full flex flex-col items-center">
                <img src={product.imagen} alt="Product" className="w-3/4 aspect-square rounded-lg" />
                <LargeText text={product.nombre} textAling="text-center" size="text-xl" colorText="text-yellow-300" textBold />
                <LargeText text={product.descripcion} textAling="text-center" textBold />
            </div>
            <div>
                <LargeText text={`$ ${formattedPrice}`} textBold colorText="text-yellow-300"/>
                <LargeText text={product.status === "Disponible" ? null : "Agotado"} textBold colorText="text-red-500"  />
            </div>
        </div>
    );
};

export default CardMenuPublic;