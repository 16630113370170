import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigation } from "../Hooks/useNavigation";
import { FaFacebookF } from "react-icons/fa6";
import { IoLocationOutline } from "react-icons/io5";
import { CgCloseR, CgMenuRight } from "react-icons/cg";
import { FaInstagram, FaTiktok, FaWhatsapp } from "react-icons/fa";
import Button from "../atoms/Button";
import LargeText from "../atoms/LargeText";
import con from "../assets/logos/logo.jpg";
import contenedorNeon from "../assets/Imagenes/neon.webp";

const HeaderRegister = () => {
	const navigate = useNavigation();
	const location = useLocation();
	const [menuOpen, isOpenMenu] = useState(false);

	const handleCambiar = (value) => {
		if (value === "navigateToLanding") {
			navigate.navigateToLanding();
		} else if (value === "navigateToRegister") {
			navigate.navigateToRegister();
		} else if (value === "navigateToIngresar") {
			navigate.navigateToLogin();
		}
		isOpenMenu(false);
	};

	return (
		<section className="w-fit sm:w-full h-10 hidden flex-row fixed top-0 right-0 justify-end px-4 rounded-b-md bg-black bg-opacity-50 z-30">
			<button
				className="hidden sm:flex items-center font-bold text-yellow-300 border-2 border-transparent hover:border-yellow-300 w-auto h-10 px-4"
				onClick={navigate.navigateToLanding}
			>
				Inicio
			</button>
			<button
				className="hidden sm:flex items-center font-bold text-yellow-300 border-2 border-transparent hover:border-yellow-300 w-auto h-10 px-4"
				onClick={navigate.navigateToRegister}
			>
				Registrarse
			</button>
			<button
				className="hidden sm:flex items-center font-bold text-yellow-300 border-2 border-transparent hover:border-yellow-300 w-auto h-10 px-4"
				onClick={navigate.navigateToLogin}
			>
				Ingresar
			</button>
			<CgMenuRight
				className="h-8 w-8 text-yellow-300 flex sm:hidden"
				onClick={() => isOpenMenu(true)}
			/>
			{menuOpen && (
				<div className="fixed top-0 right-0 h-screen w-[90%] bg-black bg-opacity-90 flex flex-col justify-around items-center rounded-l-3xl px-4">
					<div className="w-full flex justify-between items-center">
						<img src={con} className="h-12" alt="Logo" />
						<CgCloseR
							className="w-10 h-10 text-yellow-300"
							onClick={() => isOpenMenu(false)}
						/>
					</div>
					<div className="w-full flex flex-col justify-center items-center space-y-4 pb-4 border-b-2 border-yellow-300 ">
						<Button
							text="Inicio"
							largeMargin={location.pathname === "/" ? true : false}
							onClick={() => handleCambiar("navigateToLanding")}
						/>
						<Button
							text="Ingresar"
							largeMargin={location.pathname === "/register" ? true : false}
							onClick={() => handleCambiar("navigateToIngresar")}
						/>
						<Button
							text="Registrarse"
							largeMargin={location.pathname === "/register" ? true : false}
							onClick={() => handleCambiar("navigateToRegister")}
						/>
					</div>
					<div className="w-full flex flex-col space-y-4">
						<LargeText
							text="Redes sociales"
							colorText="text-yellow-300"
							textAling="text-center"
							textBold
						/>
						<div className=" w-full flex flex-row ">
							<div className="w-1/2 flex flex-row justify-around">
								<FaFacebookF className="h-8 w-8 bg-blue-700 text-white pt-1.5 rounded-full" />
								<FaInstagram className="h-8 w-8 p-1 rounded-full bg-gradient-to-tr from-pink-500 via-pink-500 to-yellow-300 text-white" />
								<FaTiktok className="h-8 w-8 text-white bg-black p-1 rounded-full" />
							</div>
							<div className="w-1/2 flex flex-row justify-around items-center">
								<FaWhatsapp color="white" size={30} />
								<IoLocationOutline color="white" size={30} />
							</div>
						</div>
					</div>
					<img src={contenedorNeon} className="w-3/4" alt="Contenedor" />
				</div>
			)}
		</section>
	);
};

export default HeaderRegister;
