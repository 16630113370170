import React from "react";
import "./styles.css"

const Circle = ({Width ="w-36", Height="h-36", cocina , onClick, border="border-4", selected}) => {
    const circleClass = `circle ${Width} ${Height} ${border} 
                         rounded-full cursor-pointer hover:scale-[1.3] my-4
                        ${selected === true ? ("circle2") : null }`
                    
    return(            
        <div 
            className={circleClass}
            style={{
                backgroundImage: `url(${cocina?.imagen})`,
                backgroundSize: '95%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                '--shadow-color': `${cocina?.shadow_color}`,
                '--border-color': `${cocina?.border_color}`,
                '--background-color': `${cocina?.background_color}`
            }}
            onClick={onClick}
        />
    );
}

export default Circle;