import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getKitchenById } from "../../apiTheLaundry/api";
import { GET_ADMIN_KITCHEN_SESSION } from "../../apiTheLaundry/ApiAdmK";
import Circle from "../atoms/Circle";
import ButtonForm from "../atoms/ButtonForm";
import useModal from "../Hooks/useModal";
import EditKitchenAdmn from "../admKitOrganisms/EditKitchenAdmn";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import EditAdminKitchen from "../adminOrganisms/EditAdminKitchen";

const PerfilAdmnKLaundry = () => {
    const [cocina, setCocina] = useState();
    const [admin, setAdmin] = useState();
    const { openModal, isOpen, closeModal } = useModal();
    const { openModal: openAdmin, isOpen: isOpenAdmin, closeModal: closeAdmin } = useModal();

    const getData = async () => {
        try {
            const adminSession = await GET_ADMIN_KITCHEN_SESSION();
            setAdmin(adminSession);
            if(adminSession.id_cocina !== undefined){
                const cocinaData = await getKitchenById(adminSession.id_cocina);
                setCocina(cocinaData);
            }
        } catch (error) {
            console.error(error);
            toast.error("Sesión no válida");
        }
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        document.title = "Perfil - The Laundry";
    }, []); 

    return (
        <div className="w-full">
            <div className="w-full flex flex-col items-center justify-center">
                <Circle cocina={cocina} selected Width="w-1/3" Height="aspect-square"/>
                <ButtonForm text="Editar cocina" bgColor="bg-purple-300" width="w-1/2" onClick={openModal}/>
                <ButtonForm text="Editar administrador" bgColor="bg-purple-300" width="w-1/2" onClick={openAdmin}/>
            </div>
            {isOpen &&
                <ModalAdmin onClose={closeModal} title="Editar cocina">
                    <EditKitchenAdmn cocina={cocina} refetch={getData} onClose={closeModal} />
                </ModalAdmin>
            }
            {isOpenAdmin &&
                <ModalAdmin onClose={closeAdmin} title="Editar Administrador">
                    <EditAdminKitchen row={admin} onClose={closeAdmin} refetch={getData} />
                </ModalAdmin>
            }
        </div>
    );
}

export default PerfilAdmnKLaundry;
