import React from "react";
import Titulo from "../atoms/Titulo";
import Cocinas from "../molecules/Cocinas/Cocinas";

const Menu = () => {
    return(
        <section className="w-full h-fit flex flex-col pt-4">
            <Titulo
				texto="Explora los sabores de Texas" 
                adds="mb-2"
			/>
            <Titulo
				texto="BARBACOA TEXANA, UN ESTILO DE VIDA EN EL CORAZON DE PUEBLA" 
                colorText="text-white"
			/>
            <Cocinas/>
        </section>
    )
}

export default Menu;