import React from 'react';

const Tooltip = ({ children, text }) => {
  return (
    <div className="relative group">
      {children}
      <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-gray-700 text-violet-400 text-sm rounded py-1 px-2">
        {text}
      </div>
    </div>
  );
};

export default Tooltip;