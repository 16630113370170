import React from "react";
import { Formik } from "formik";
import { FaArrowLeftLong } from "react-icons/fa6";
import LargeText from "../../atoms/LargeText";
import ButtonForm from "../../atoms/ButtonForm";
import RegisterField from "../../atoms/RegisterField";
import contenedorNeon from "../../assets/Imagenes/neon.webp";

const RegisterStep2 = ({ setActiveSection, mailPhone }) => {
	const handleSubmit = () => {
		setActiveSection("3");
	};

	const handleBack = () => {
		setActiveSection("1");
	};

	const validate = (values) => {
		const errors = {};
		if (!values.digito1) {
			errors.digito1 = "Requerido";
		} else if (!/^[0-9]{1}$/.test(values.digito1)) {
			errors.digito1 = "Un número del 1 al 9";
		}
		if (!values.digito2) {
			errors.digito2 = "Requerido";
		} else if (!/^[0-9]{1}$/.test(values.digito2)) {
			errors.digito2 = "Un número del 1 al 9";
		}
		if (!values.digito3) {
			errors.digito3 = "Requerido";
		} else if (!/^[0-9]{1}$/.test(values.digito3)) {
			errors.digito3 = "Un número del 1 al 9";
		}
		if (!values.digito4) {
			errors.digito4 = "Requerido";
		} else if (!/^[0-9]{1}$/.test(values.digito4)) {
			errors.digito4 = "Un número del 1 al 9";
		}
		return errors;
	};

	return (
		<div className="w-full sm:w-[33%] h-screen flex flex-col items-center justify-around py-8 px-6 sm:px-0 ">
			<Formik
				initialValues={{ digito1: "", digito2: "", digito3: "", digito4: "" }}
				validate={validate}
				onSubmit={handleSubmit}
			>
				{({ handleSubmit, isSubmitting }) => (
					<form
						onSubmit={handleSubmit}
						className="flex flex-col justify-center items-center space-y-4 "
					>
						<img src={contenedorNeon} alt="contenedor" />
						<div className="flex flex-col justify-center items-center">
							<LargeText
								text={`Ingresa el código de 4 digitos que se te envio al ${mailPhone}`}
								textBold={true}
								size="text-lg"
								textAling="text-center"
							/>
							<div className="flex flex-row w-full my-4 justify-around items-center">
								<div className="w-16 sm:w-24 mx-2">
									<RegisterField name="digito1" type="text" inputMode="numeric" pattern="[0-9]" placeholder="#" />
								</div>
								<div className="w-16 sm:w-24 mx-2">
									<RegisterField name="digito2" type="text" inputMode="numeric" pattern="[0-9]" placeholder="#" />
								</div>
								<div className="w-16 sm:w-24 mx-2">
									<RegisterField name="digito3" type="text" inputMode="numeric" pattern="[0-9]" placeholder="#" />
								</div>
								<div className="w-16 sm:w-24 mx-2">
									<RegisterField name="digito4" type="text" inputMode="numeric" pattern="[0-9]" placeholder="#" />
								</div>
							</div>
						</div>
						<div className="w-full">
							<ButtonForm
								text="Verificar"
								type="submit"
								bgColor="bg-[#578E76]"
								disabled={isSubmitting}
							/>
							<ButtonForm text="Reenviar código" />
						</div>
						<FaArrowLeftLong
							className="text-yellow-300 w-24 h-6 mt-4 cursor-pointer"
							onClick={handleBack}
						/>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default RegisterStep2;
