import { Formik, Form } from "formik";
import React, { useState } from "react";
import InputFFAdmin from "../../AdminAtoms/InputFFAdmin";
import ButtonForm from "../../atoms/ButtonForm";
import { UPDATE_CONSUMABLE } from "../../../apiTheLaundry/apiAdmin";
import { toast } from "react-toastify";
import LargeText from "../../atoms/LargeText";

const EditInsumo = ({ row ,cocinas, refetch, onClose}) => {
    const [loading, setLoading] = useState(false);
    
    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            await UPDATE_CONSUMABLE(row.id_almacen ,values);
            refetch();
            onClose();
            toast.success("Insumo editado correctamente");
        } catch (error) {
            console.error("Error al actualizar el insumo", error);
            toast.error("Error al actualizar el insumo")
        } finally {
            setLoading(false);
        }
    }

    const initialValues ={
        nombre: row.almacen.nombre || "",
        cantidad:row.almacen.cantidad || 0, 
        cantidad_disponible:row.cantidad_disponible || 0, 
        medidas:row.medidas || "",
        precio_compra:row.almacen.precio_compra || 0,
    }

    const validate = (values) => {
        const errors = {};
        if (!values.nombre) {
            errors.nombre = "Campo requerido";
        }
        if (!values.medidas) {
            errors.medidas = "Campo requerido";
        } else if (!Number.isInteger(Number(values.medidas))) {
            errors.medidas = "Debe ser un número entero"
        } else if (values.medidas <= 0) {
            errors.medidas ="No puede tener valor negativo ó 0";
        }
        return errors;
    };  

    const cocinaNombre = cocinas.find(cocina => cocina.id === row.almacen.id_cocina)?.nombre || "Cocina no encontrada";

    return(
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validate={validate}
            >
                {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-3">
                        <LargeText text={cocinaNombre} colorText="" textBold />
                        <LargeText text={`Cantidad en almacen: ${row.almacen.cantidad}`} colorText="" textBold />
                        <LargeText text={`Botellas bateo: ${row.cantidad_disponible}`} colorText="" textBold />
                    </div>
                    <div className="flex flex-row space-x-4">
                        <div className="w-1/2 space-y-4">
                            <InputFFAdmin name="nombre" value="nombre" label="Nombre" />
                            <InputFFAdmin name="precio_compra" label="Costo al comprar" type="number" />
                        </div>
                        <div className="w-1/2 space-y-4">
                            <InputFFAdmin name="medidas" value="medidas" label="medidas" />
                        </div>
                    </div>
                    <ButtonForm 
                        text="Editar" bgColor={loading === true ? "bg-gray-300" : "bg-violet-400" } 
                        width="w-64 ml-auto mt-6" type="submit" disabled={loading === true ? true : false} 
                    />
                </Form>
                )}
            </Formik>
        </div>
    )
}
export default EditInsumo;