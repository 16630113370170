import React from "react";
import LargeText from "../../atoms/LargeText";

const CardProducto = ({item}) =>{
    return(
        <div className="w-full flex flex-row justify-between my-4 pr-8">
            <div className="w-full sm:w-auto flex flex-row space-x-6 h-20">
                <img src={item.imagen} className="h-full sm:h-3/4" alt="Card producto"/>
                <div className="w-full">
                    <LargeText text={item.nombre} textBold/>
                    {Object.entries(item?.extras).map(([key, extra]) => (
                        <div className="flex flex-row">
                            <LargeText 
                                key={extra.id} 
                                text={`${key}:`} 
                                textBold
                            />
                            <LargeText 
                                key={extra.id} 
                                text={`${extra.nombre}`} 
                                textBold 
                                colorText="ml-2 text-yellow-300"
                            />
                        </div>
                    ))}
                    {Object.entries(item?.additionals).map(([key, additional]) => (
                    <div key={key} className="flex flex-wrap">
                        <LargeText 
                        text={`${key}:`} 
                        textBold
                        />
                        {additional.map((option) => (
                            <LargeText 
                            key={option.id} 
                            text={`-${option.nombre}`} 
                            colorText="text-yellow-300 ml-4"
                            textBold
                        />
                        ))}
                    </div>
                    ))}
                    {item.comentarios !== "" && 
                        <LargeText text={item.comments} textBold={true}  />
                    }
                    <LargeText text={`$ ${item.precio}`} textBold/>
                    <LargeText text={`${item.quantity} unidad(es)`}  size="text-base flex sm:hidden" />
                </div>
            </div>
            <LargeText text={`${item.quantity} unidad(es)`}  size="hidden sm:flex" />
        </div>
    );
}

export default CardProducto;