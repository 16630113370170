import React from "react";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";

const ModalConfirmAdmin = ({ onClose , onConfirm }) =>{
    return(
        <div className="fixed bottom-0 left-0 h-full w-full bg-white bg-opacity-85 flex justify-center items-center z-30 px-4">
            <div className="w-full sm:w-3/5 h-fit sm:h-48 bg-white rounded-lg shadow-lg p-4 flex justify-around items-center flex-col text-center">
                    <LargeText text="¿Estas seguro de realizar esta accción?"textBold size="text-4xl" colorText="text-black" />
                <div className="w-full h-.5 border-2 border-black my-2 " />
                <div className="w-full sm:space-x-4 flex flex-col sm:flex-row">
                    <ButtonForm text="Cancelar" bgColor="bg-red-500" onClick={onClose} />
                    <ButtonForm text="Confirmar" bgColor="bg-green-600" onClick={onConfirm} />
                </div>
            </div>
        </div>
    )
}

export default ModalConfirmAdmin;