import React, { useState, useEffect, useCallback } from "react";
import { Formik, FieldArray } from "formik";
import ButtonForm from "../atoms/ButtonForm";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import { toast } from "react-toastify";
import {
	CREATE_ADICIONAL,
	UPDATE_ADICIONAL,
	DELETE_ADICIONAL,
	DELETE_EXTRA,
	GET_CATEGORIAS,
	GET_ADICIONALES_BY_PRODUCT,
	GET_EXTRAS_BY_PRODUCT,
	UPDATE_PRODUCT,
	UPDATE_EXTRA,
	CREATE_EXTRA,
	UPLOAD_IMAGE,
	GET_CATEGORIAS_PRODUCTS,
} from "../../apiAdmin";
import { MdDelete } from "react-icons/md";
import LargeText from "../atoms/LargeText";
import { FaQuestion } from "react-icons/fa";
import useModal from "../Hooks/useModal";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import ProblemProduct from "../AdminAtoms/ProblemsProduct";

const EditProduct = ({ onClose, refetch, product }) => {
	const [loading, setLoading] = useState(false);
	const [extras, setExtras] = useState([]);
	const [adicionales, setAdicionales] = useState([]);
	const { openModal, closeModal, isOpen } = useModal();
	const [categoriasOptions, setCategoriasOptions] = useState();
	const [categoriasProdc, setCategoriasProdc] = useState();
	const [file, setFile] = useState(null);


	useEffect(() => {
		const getCategorias = async () => {
			try {
				const categoriasData = await GET_CATEGORIAS();
				const mappedCategorias = categoriasData.map((cat) => ({
					label: cat.nombre,
					value: cat.nombre,
				}));
				setCategoriasOptions(mappedCategorias);
				const categoriasProductos = await GET_CATEGORIAS_PRODUCTS();
				console.log(categoriasProductos);
				const mappedCategoriasP = categoriasProductos.map((cat) => ({
					label: cat.nombre, value: cat.prioridad,
				}));
				setCategoriasProdc(mappedCategoriasP);
			} catch (err) {
				console.error(err);
			}
		};

		getCategorias();
	}, []);

	const fetchExtras = useCallback(async () => {
		try {
			setLoading(true);
			const data = await GET_EXTRAS_BY_PRODUCT(product.id);
			setExtras(data);
		} catch (error) {
			console.error("Error fetching extras:", error);
			setExtras([]);
		} finally {
			setLoading(false);
		}
	}, [product.id]);

	useEffect(() => {
		fetchExtras();
	}, [product.id, fetchExtras]);

	const fetchAdds = useCallback(async () => {
		try {
			setLoading(true);
			const data = await GET_ADICIONALES_BY_PRODUCT(product.id);
			setAdicionales(data);
		} catch (error) {
			console.error("Error fetching adicionales:", error);
			setAdicionales([]);
		} finally {
			setLoading(false);
		}
	}, [product.id]);

	useEffect(() => {
		fetchAdds();
	}, [product.id, fetchAdds]);

	const initialValues = {
		nombre: product.nombre || "",
		descripcion: product.descripcion || "",
		precio: product.precio || 0,
		precio_compra: product.precio_compra || 0,
		precio_lista: product.precio_lista || 0,
		status: product.status || "",
		id_cocina: product.id_cocina,
		extras: extras.length > 0 ? extras : [],
		adicionales: adicionales.length > 0 ? adicionales : [],
		categoria: product.categoria || "",
	};

	const handlePrecioListaChange = (value, setFieldValue) => {
		const listaPrice = parseFloat(value) || 0;
		const purchasePrice = (listaPrice * 0.85).toFixed(2);
		const publicPriceInCents = Math.ceil(purchasePrice * 1.5);
		setFieldValue("precio_lista", value);
		setFieldValue("precio_compra", purchasePrice);
		setFieldValue("precio", publicPriceInCents);
	};

	const handleSubmit = async (values, { setSubmitting }) => {
		setLoading(true);
		try {
			let imageUrl = "";
			if (file) {
				const folder = "Productos";
				const uploadResponse = await UPLOAD_IMAGE(file, folder);
				imageUrl = uploadResponse.url;
			}
			const filteredExtras = values.extras.filter(
				(extra) => extra.nombre !== null
			);
			const filteredAdicionales = values.adicionales.filter(
				(adicional) => adicional.nombre !== null
			);

			const data = {
				nombre: values.nombre,
				imagen: imageUrl,
				descripcion: values.descripcion,
				precio: values.precio,
				precio_compra: values.precio_compra,
				status: values.status,
				id_cocina: values.id_cocina,
				precio_lista: values.precio_lista,
				categoria: values.categoria
			};
			await UPDATE_PRODUCT(product.id, data);

			for (const extra of filteredExtras) {
				if (extra.id) {
					await UPDATE_EXTRA(extra.id, extra);
				} else {
					await CREATE_EXTRA({ ...extra, id_producto: product.id });
				}
			}

			for (const adicional of filteredAdicionales) {
				if (adicional.id) {
					await UPDATE_ADICIONAL(adicional.id, adicional);
				} else {
					await CREATE_ADICIONAL({ ...adicional, id_producto: product.id });
				}
			}

			toast.success("Producto actualizado correctamente");
			refetch();
			onClose();
		} catch (error) {
			toast.error("Error al actualizar el producto");
		} finally {
			setLoading(false);
		}
		setSubmitting(false);
	};

	const handleDeleteExtra = async (id) => {
		try {
			const status = await DELETE_EXTRA(id);
			if (status === 200) {
				toast.success("Extra eliminado correctamente");
				fetchExtras();
			} else {
				toast.error("Error al eliminar extra");
			}
		} catch (error) {
			toast.error("Error al eliminar el extra");
		}
	};

	const handleDeleteAdicional = async (id) => {
		try {
			const status = await DELETE_ADICIONAL(id);
			if (status === 200) {
				toast.success("Adicional eliminado correctamente");
				fetchAdds();
			} else {
				toast.error("Error al eliminar adicional");
			}
		} catch (error) {
			toast.error("Error al eliminar el adicional");
		}
	};

	const validate = (values) => {
		const errors = {};

		if (!values.nombre) {
			errors.nombre = "Campo requerido";
		}

		if (values.precio < 0) {
			errors.precio = "El precio no puede ser negativo";
		}

		if (values.precio_compra < 0) {
			errors.precio_compra = "El precio no puede ser negativo";
		}

		values.extras.forEach((extra, index) => {
			if (!extra.nombre) {
				errors[`extras[${index}]`] =
					"Campo requerido";
			}
		});

		if (!values.status || values.status === "") {
			errors.status = "Debes seleccionar un estatus valido";
		}

		values.adicionales.forEach((adicional, index) => {
			if (!adicional.nombre) {
				errors[`adicionales[${index}]`] =
					"Campo requerido";
			}
		});

		return errors;
	};

	const options = [
		{ label: "Disponible", value: "Disponible" },
		{ label: "Minimo", value: "Minimo" },
		{ label: "Oculto", value: "Oculto" },
	];

	return (
		<div>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validate={validate}
				enableReinitialize={true}
			>
				{({ handleSubmit, values, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<div className="w-full flex flex-row space-x-4">
							<div className="w-1/2 flex flex-col space-y-4">
								<InputFFAdmin
									name="nombre"
									label="Nombre(s)"
									type="text"
									placeholder="Nombre(s)"
								/>
								<InputFFAdmin
									name="descripcion"
									label="Descripción"
									type="text"
									placeholder="Descripción del producto"
								/>
								<InputFFAdmin
									name="status"
									label="Estatus de stock"
									type="select"
									options={options}
								/>
								<InputFFAdmin
									name="imagen"
									label="Imagen"
									type="file"
									accept="image/*"
									onChange={(event) => setFile(event.currentTarget.files[0])}
								/>
							</div>
							<div className="w-1/2 flex flex-col space-y-4">
								<InputFFAdmin
									name="precio_lista"
									label="Precio de lista"
									type="number"
									placeholder="Precio en pesos mexicanos"
									value={values.precio_lista}
									onChange={(e) =>
										handlePrecioListaChange(e.target.value, setFieldValue)
									}
								/>
								<InputFFAdmin
									name="precio"
									label="Precio publico"
									type="number"
									placeholder="Precio en pesos mexicanos"
									value={values.precio}
									disabled
								/>
								<InputFFAdmin
									name="precio_compra"
									label="Precio de compra"
									type="number"
									placeholder="Precio en pesos mexicanos"
									value={values.precio_compra}
									disabled
								/>
								<InputFFAdmin name="categoria" label="Categoria del producto" type="select" options={categoriasProdc} />
							</div>
						</div>
						<FieldArray name="extras">
							{({ push, remove }) => (
								<div className="mt-4">
									<LargeText
										text={
											loading ? "CARGANDO EXTRAS ESPERE UN MOMENTO" : "Extras"
										}
										colorText=""
										size="text-4xl"
										textBold
									/>
									{values.extras.map((extra, index) => (
										<div
											key={index}
											className="flex flex-row space-x-4 items-center border-violet-300 border p-2 mb-2 rounded-lg"
										>
											<InputFFAdmin
												name={`extras[${index}].nombre`}
												label="Nombre del extra"
												type="text"
												placeholder="Nombre del extra"
												value={extra.nombre}
												onChange={(e) =>
													setFieldValue(
														`extras[${index}].nombre`,
														e.target.value
													)
												}
											/>
											<InputFFAdmin
												name={`extras[${index}].categoria`}
												label="Categoría"
												type="select"
												options={categoriasOptions}
												value={extra.categoria}
												onChange={(e) =>
													setFieldValue(
														`extras[${index}].categoria`,
														e.target.value
													)
												}
											/>
											<button
												type="button"
												onClick={() =>
													extra.id ? handleDeleteExtra(extra.id) : remove(index)
												}
												disabled={loading}
												className="bg-gray-400 hover:bg-red-500 text-white p-2 rounded text-3xl"
											>
												<MdDelete />
											</button>
										</div>
									))}

									<button
										type="button"
										onClick={() =>
											push({ nombre: "", categoria: "" })
										}
										className="bg-green-500 text-white p-2 rounded"
									>
										Agregar extra
									</button>
								</div>
							)}
						</FieldArray>
						<FieldArray name="adicionales">
							{({ push, remove }) => (
								<div className="mt-4">
									<LargeText
										text={
											loading
												? "CARGANDO ADICIONALES ESPERE UN MOMENTO"
												: "Adicionales"
										}
										colorText=""
										size="text-4xl"
										textBold
									/>
									{values.adicionales.map((adicional, index) => (
										<div
											key={index}
											className="flex flex-row space-x-4 items-center border-violet-300 border p-2 mb-2 rounded-lg"
										>
											<InputFFAdmin
												name={`adicionales[${index}].nombre`}
												label="Nombre del adicional"
												type="text"
												placeholder="Nombre del adicional"
												value={adicional.nombre}
												onChange={(e) =>
													setFieldValue(
														`adicionales[${index}].nombre`,
														e.target.value
													)
												}
											/>
											<InputFFAdmin
												name={`adicionales[${index}].categoria`}
												label="Categoría"
												type="select"
												options={categoriasOptions}
												value={adicional.categoria}
												onChange={(e) =>
													setFieldValue(
														`adicionales[${index}].categoria`,
														e.target.value
													)
												}
											/>
											<button
												type="button"
												onClick={() =>
													adicional.id
														? handleDeleteAdicional(adicional.id)
														: remove(index)
												}
												className="bg-gray-400 hover:bg-red-500 text-white p-2 rounded text-3xl"
											>
												<MdDelete />
											</button>
										</div>
									))}

									<button
										type="button"
										onClick={() =>
											push({ nombre: "", categoria: "" })
										}
										className="bg-green-500 text-white p-2 rounded"
									>
										Agregar adicional
									</button>
								</div>
							)}
						</FieldArray>
						<div className="w-full flex flex-row justify-between items-center">
							<FaQuestion
								size={30}
								className="text-gray-400 hover:text-white hover:bg-violet-300 rounded-full p-1 cursor-pointer"
								onClick={openModal}
							/>
							<ButtonForm
								type="submit"
								bgColor={loading ? "bg-gray-300" : "bg-violet-300"}
								width="w-1/3 ml-auto"
								text={loading ? "Cargando..." : "Editar"}
								disabled={loading}
							/>
						</div>
					</form>
				)}
			</Formik>
			{isOpen && (
				<ModalAdmin
					title="Problemas al crear o editar producto"
					onClose={closeModal}
				>
					<ProblemProduct />
				</ModalAdmin>
			)}
		</div>
	);
};

export default EditProduct;
