import React, { useState } from "react";
import DataTable from "react-data-table-component";
import useModal from "../Hooks/useModal";
import ButtonForm from "../atoms/ButtonForm";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import LargeText from "../atoms/LargeText";
import { SiMicrosoftexcel } from "react-icons/si";
import PedidoBar from "./bar/PedidoBar";

const TableBD = ({ data, kitchenId }) => {
    const [orden, setOrden] = useState(null);
    const { openModal, closeModal, isOpen } = useModal();

    const calculateTotalProducts = (orden) => {
        return orden.reduce((total, item) => total + item.totalProduct, 0);
    };

    const calculateTotalGanancia = () => {
        return data.reduce((total, row) => total + calculateTotalProducts(row.orden[kitchenId]), 0);
    };

    const handleOpen = (orden) => {
        const ordenFiltrada = orden[kitchenId] || {}; 
        openModal();
        setOrden(ordenFiltrada);
    };

    const columns = [
        {
            name: "ID",
            selector: row => row.id
        },
        {
            name: "Pago",
            selector: row => row.metodo_pago,
        },
        {
            name: "Total",
            selector: row => `$${calculateTotalProducts(row.orden[kitchenId])} MX`,
        },
        {
            name: "Acciones",
            selector: row => (
                <ButtonForm
                    text="Ver productos"
                    bgColor="bg-orange-500"
                    width="w-28"
                    onClick={() => handleOpen(row.orden)}
                />
            )
        },
    ];

    const convertArrayOfObjectsToCSV = (array) => {
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);

        let result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            const row = keys.map(key => item[key]).join(columnDelimiter);
            result += row + lineDelimiter;
        });

        return result;
    };

    const downloadCSV = (array) => {
        const csvData = convertArrayOfObjectsToCSV(array); 
        const link = document.createElement('a');
        const filename = 'reporteContenedor.csv';

        let csv;

        if (!csvData.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csvData}`;
        } else {
            csv = csvData;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    };

    const handleExport = () => {
        const exportData = data.map(row => ({
            id: row.id,
            metodo_pago: row.metodo_pago,
            total: calculateTotalProducts(row.orden[kitchenId]),
        }));

        exportData.push({
            id: 'Total',
            metodo_pago: '',
        });

        downloadCSV(exportData);
    };

    return (
        <div className="bg-white rounded-lg shadow-lg">
            <ButtonForm onClick={handleExport} bgColor="bg-green-700" width="w-32 ml-auto mr-4" text={`Exportar`} icon={<SiMicrosoftexcel size={30}/>} />
            <DataTable
                data={data}
                columns={columns}
                fixedHeader
                fixedHeaderScrollHeight="63vh"
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 50, 100]}
            />
            <LargeText colorText="ml-2" text={`Total ganancia general: $${calculateTotalGanancia()} MX`} textBold />
            {isOpen &&
                <ModalAdmin title="Orden" onClose={closeModal}>
                    <PedidoBar ordenes={[orden]} />
                </ModalAdmin>
            }
        </div>
    );
};

export default TableBD;
