import React from "react";
import LargeText from "./LargeText";

const InputLabel = ({etiqueta="", value, type="", placeholder={}, icon, onChange, required=false, ...rest}) =>{
    return( 
        <div className="w-full flex flex-col my-2  ">
            <LargeText text={etiqueta} textBold />
            <div className="relative w-full flex">
              {icon && (
              <span className="flex items-center justify-center mx-4">{icon}</span>
            )}
        <input
          placeholder={placeholder}
          type={type}
          className={`bg-[#ffffff57] w-full py-2 pl-2 rounded-md border-white text-white pl-${icon ? '10' : '3'} placeholder-gray-300`}
          value={value}
          onChange={onChange}
          min={type === "number" ? 0 : undefined} 
          required={required}
          {...rest}
        />
      </div>
    </div>
    );
}

export default InputLabel;