import { TbReportAnalytics } from "react-icons/tb";
import { FaRegUserCircle } from "react-icons/fa";
import { PiChefHat, PiCheersFill, PiTennisBallFill } from "react-icons/pi";

export const links = [
    { name:"Cocinas", path:"/kitchens", icon:PiChefHat, },
    { name:"Padel", path:"/Padel", icon:PiTennisBallFill, },
    { name:"Pedidos", path:"/PedidosAdmin", icon:TbReportAnalytics, childs: [], },
    { name:"Usuarios", path:"/users", icon:FaRegUserCircle, childs: [], },
    {
        name:"Bar", path:"/barAdmin", icon:PiCheersFill,
        childs: [
            { name:"Bares", path:"/barAdmin", },
            { name:"Almacenes", path:"/almacenes", },
        ],
    },
]