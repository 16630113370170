import React from "react";

const ButtonForm = ({type = "button", text="", onClick, bgColor="transparent",textColor="text-white" , width="w-full", icon=null, icon2=null, divStyles="justify-center",...res }) => {
  const buttonClass = `${width} ${bgColor} ${textColor} h-12 rounded-full py-2 flex flex-row my-2 items-center ${divStyles} `;
  const divClass = `flex flex-row items-center`;
  return (
    <button
      type={type}
      onClick={onClick}
      className={buttonClass}
      {...res}
    >
        <div className={divClass}>
      {icon !== null ? <span  className="flex mr-2 justify-center items-center" >{icon}</span> : null}
      {text}
      {icon2 !== null ? <div>{icon2}</div> : null}
      </div>
    </button>
  );
};

export default ButtonForm;