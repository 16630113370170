import React, { useState } from "react";
import { UPDATE_CHASHIER } from "../../apiAdmin";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import ButtonForm from "../atoms/ButtonForm";

const EditCaja = ({ row, onClose, refetch }) => {
    const [loading, setLoading] = useState();
    
    const initialValues = {
        username: row.user.username || "", password:"", nombre: row.nombre || "",
        role: row.user.role || "Caja", hora_entrada: row.hora_entrada || "", hora_salida: row.hora_salida || "", apellido: row.apellido || "",
    }

    const options = [
        {value:"Caja", label:"Caja contenedores"},
        {value:"CajaPadel", label:"Caja One Padel"},
    ]

    const handleSubmit = async(values) =>{
        try {
            setLoading(true);
            await UPDATE_CHASHIER(row.id_user,values);
            toast.success("Caja creado correctamente");
            refetch();
            onClose();
        } catch (error){
            console.error("Error", error);
            toast.error("Error al editar la caja");
        } finally {
            setLoading(false);
        }
    }

    const validate = (values) => {
		const errors = {};
        if (!values.username) { errors.username = "Campo requerido"; }
		if (!values.nombre) { errors.nombre = "Campo requerido"; }
		if (!values.apellido) { errors.apellido = "Campo requerido"; }
		return errors;
	};

    return(
        <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate} >
                {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="w-full flex flex-row space-x-4">
                        <div className="w-1/2 flex flex-col space-y-4">
                            <InputFFAdmin name="nombre" label="Nombre(s)" type="text" placeholder="Nombre(s)" />
                            <InputFFAdmin name="username" label="Nombre de usuario" type="text" placeholder="Nombre de usuario" />
                            <InputFFAdmin name="password" label="Contraseña" type="password" placeholder="Contraseña" />  
                            <div className="w-full flex flex-row space-x-4">
                                <InputFFAdmin name="hora_entrada" label="Hora de entrada" type="time" />
                                <InputFFAdmin name="hora_salida" label="Hora de salida" type="time" />
                            </div>
                        </div>
                        <div className="w-1/2 flex flex-col space-y-4">
                            <InputFFAdmin name="apellido" label="Apellidos(s)" type="text" placeholder="Apellido(s)" />
                            <InputFFAdmin name="role" options={options} type="select" label="Tipo"/>
                        </div>
                    </div>
                   <ButtonForm 
                        bgColor={loading ? "bg-gray-300" : "bg-violet-300"} width="w-1/3 ml-auto mt-6" 
                        text={loading ? "Cargando" : "Guardar cambios"} type="submit" disabled={loading ? true : false } 
                    />
                </Form>
                )}
            </Formik>
        </div>
    )
}

export default EditCaja;