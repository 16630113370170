import React from "react";
import "./styles.css";

const LargeText = ({
  text,
  textBold = false,
  colorText = "text-white",
  size = "text-lg",
  onClick,
  cursor = "",
  textAlign = "",
  title = "",
  titleColor = "text-gray-500",
  content = "",
  separator = ": ",
  ...rest
}) => {
  return (
    <p
      className={`w-auto font-custom ${textAlign} ${cursor} ${size} ${
        textBold ? "font-bold" : "font-normal"
      } ${colorText}`}
      onClick={onClick}
      {...rest}
    >
      {title ? (
        <span className={`font-bold ${titleColor} ${size} `}>
          {title}
          {content && <span className={`${size} ${textBold ? "font-bold" : "font-normal"}  text-white `}> {separator}{content}</span>}
        </span>
      ) : (
        text
      )}
    </p>
  );
};

export default LargeText;
