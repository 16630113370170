import React, { useEffect, useState } from "react";
import RegisterStep1 from "../organisms/Register/RegisterStep1"
import RegisterStep2 from "../organisms/Register/RegisterStep2";
import RegisterStep3 from "../organisms/Register/RegisterStep3";
import contenedor from "../assets/Imagenes/OIG4.jpeg";

const Register = () => {
    const [mailPhone, setMailPhone] = useState(null)
    const [activeSection, setActiveSection] = useState('1');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = "Registro - Contenedor";
    }, []); 
    
    return(
        <div 
            style={{
                backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, .8), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, .8)), url(${contenedor})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}
            className="w-full h-full bg-black flex justify-center items-center flex-col"
        >
            { activeSection === "1" && 
                <RegisterStep1 
                    setActiveSection={setActiveSection} setMailPhone={setMailPhone}
                    mailPhone={mailPhone} loading={loading} setLoading={setLoading}
                /> 
            }
            { activeSection === "2" && 
                <RegisterStep2 
                    setActiveSection={setActiveSection} mailPhone={mailPhone} 
                    loading={loading} setLoading={setLoading}
                /> 
            }
            { activeSection === "3" && 
                <RegisterStep3 
                    setActiveSection={setActiveSection} mailPhone={mailPhone} 
                    loading={loading} setLoading={setLoading}
                /> 
            }
        </div>
    );
}

export default Register;