import React, { useEffect, useState } from "react";
import { getKitchen } from "../../apiTheLaundry/api";
import DataTable from "react-data-table-component";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";
import { MdOutlineEdit } from "react-icons/md";
import useModal from "../Hooks/useModal";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import EditKitchenUser from "../adminOrganisms/EditKitchenUser";
import H2Custom from "../atoms/H2Custom";

const KitchensUsersLaundry = () => {
    const [cocina, setCocina] = useState([])
    const [cocinas, setCocinas] = useState();
    const { openModal: openEdit, isOpen: isOpenEdit, closeModal: closeEdit } = useModal();

    const fetchKitchen = async () =>{
        try{
            const data = await getKitchen();
            setCocinas(data);
        } catch (error) {
            console.log("Error al obtener los datos", error);
        }
    };

    useEffect (()=>{
        fetchKitchen()
    },[]);

    useEffect(() => {
        document.title = "Cocinas - The Laundry";
    }, []); 

    const handleClose = () => {
        closeEdit();
        setCocina(null);
    }

    const handleOpenEdit = (row) => {
        openEdit();
        setCocina(row);
    }

    const columns = [
        { name:"ID", selector: row => row.id},
        { name:"Nombre", selector: row => row.nombre}, 
        { name:"Nombre de usuario", selector: row => row.user?.username},
        { name: "Acciones", width:"100px", selector: row => 
            <ButtonForm text={<MdOutlineEdit size={35}/>} onClick={() => handleOpenEdit(row)} bgColor="bg-blue-300" width="w-12"/>
        }
    ]

    return(
        <div className="border-2 border-gray-200 rounded-lg shadow-lg">
            <H2Custom text="Cocinas" textBold size="text-4xl" colorText="ml-2"/>
            <LargeText text="Para editar los detalles de la cocina ir a la pagina de la cocina" colorText="ml-2" textBold />
            <DataTable
                data={cocinas}
                columns={columns}
            />
            {isOpenEdit &&
                <ModalAdmin onClose={handleClose} title="Editar cocina">
                    <EditKitchenUser row={cocina} refetch={fetchKitchen} onClose={handleClose} />
                </ModalAdmin>
            }
        </div>
    )
}

export default KitchensUsersLaundry;