import React, { useState } from "react";
import { Formik } from "formik";
import ButtonForm from "../atoms/ButtonForm";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import { toast } from "react-toastify";
import { CREATE_WAITERS } from "../../apiAdmin";

const CreateMesero = ({ onClose, refetch }) => {
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            setLoading(true);
            await CREATE_WAITERS(values); 
            toast.success("Mesero creado correctamente ✅🍽");
            refetch();
            onClose();
        } catch (error) {
            toast.error("Error al crear el mesero ❌🍽");
        } finally {
            setLoading(false);
        }
        setSubmitting(false);
    };

    const initialValues = { 
        username:"", 
        nombre: "", 
        apellido: "", 
        role:"Mesero",
        hora_entrada:"", 
        hora_salida:"",
    }

    const validate = (values) => {
		const errors = {};
        if (!values.username) { errors.username = "Campo requerido"; }
		if (!values.nombre) { errors.nombre = "Campo requerido"; }
		if (!values.apellido) { errors.apellido = "Campo requerido"; }
        if (!values.password) { errors.password = "Campo requerido"; }
        if (!values.hora_entrada) { errors.hora_entrada = "Campo requerido"; }
        if (!values.hora_salida) { errors.hora_salida = "Campo requerido"; }
		return errors;
	};

    const options = [
        { label: "Mesero en Contenedor", value: "Mesero" },
        { label: "Mesero en One Padel", value: "MeseroPadel" },
    ]

    return(
        <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate} >
                {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className="w-full flex flex-row space-x-4">
                        <div className="w-1/2 flex flex-col space-y-4">
                            <InputFFAdmin name="nombre" label="Nombre(s)" type="text" placeholder="Nombre(s)" />
                            <InputFFAdmin name="username" label="Nombre de usuario" type="text" placeholder="Nombre de usuario" />
                            <InputFFAdmin name="password" label="Contraseña" type="password" placeholder="Contraseña" />  
                        </div>
                        <div className="w-1/2 flex flex-col space-y-4">
                            <InputFFAdmin name="apellido" label="Apellidos(s)" type="text" placeholder="Apellido(s)" />
                            <InputFFAdmin name="role" options={options} type="select" label="Tipo"/>
                            <div className="w-full flex flex-row space-x-4">
                                    <InputFFAdmin name="hora_entrada" label="Hora de entrada" type="time" />
                                    <InputFFAdmin name="hora_salida" label="Hora de salida" type="time" />
                            </div>
                        </div>
                    </div>
                    <ButtonForm 
                        text={loading ? "Cargando" : "Guardar cambios"} type="submit"
                        bgColor={loading ? "bg-gray-300" : "bg-violet-300"} width="w-1/3 ml-auto mt-6" 
                    />
                </form>
                )}
            </Formik>
        </div>
    );
}

export default CreateMesero;