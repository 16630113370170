import React, { useState, useEffect, useCallback } from "react";
import useModal from "../../Hooks/useModal";
import LargeText from "../../atoms/LargeText";
import ButtonForm from "../../atoms/ButtonForm";
import RadioButtonsList from "../../atoms/RadioButtonsList";
import Modal from "../../molecules/Modal";
import AgregarDireccion from "../../molecules/Guia/AgregarDireccion";
import { GET_ADDRESS_BY_USER } from "../../../apiUser";
import { useSession } from "../../../context/SessionContext";

const Direcciones = () => {
    const { openModal: openAdd, isOpen: isOpenAdd, closeModal: closeAdd } = useModal();
    const [address, setAddress] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);
    const { user } = useSession();

    const handleOptionChange = (event) => {
        setSelectedOption(Number(event.target.value));
    };

    const fetchAddress = useCallback (async () => {
        try {
            const AddressData = await GET_ADDRESS_BY_USER(user?.id);
            setAddress(AddressData);
            if (AddressData.length > 0) {
                setSelectedOption(AddressData[0].direcciones.find(direccion => direccion.main === true).id || null);
            }
            setLoading(false);
        } catch (error) {
            console.error("Error al obtener las direcciones", error);
            setLoading(false);
        }
    }, [user?.id]);

    useEffect(() => {
        fetchAddress();
    }, [fetchAddress]);

    if (loading) {
        return <div className="w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center">Loading...</div>;
    }

    const options = address[0]?.direcciones?.map(direccion => ({
        value: direccion.id,
        label: `${direccion.calle}, ${direccion.colonia}, ${direccion.codigo_postal}, ${direccion.municipio}`
    }));

    return (
        <div className="w-full h-fit sm:h-fit overflow-scroll bg-gray-700 rounded-md p-2 sm:p-8 mb-4">
            <LargeText text="Direcciones guardadas" textBold colorText="text-yellow-300" />
            <div className="space-y-4 overflow-scroll">
                <RadioButtonsList
                    name="direcciones"
                    options={options}
                    selectedOption={selectedOption}
                    onChange={handleOptionChange}
                />
                <div className="w-full flex flex-col sm:flex-row sm:space-x-4">
                    <ButtonForm text="Agregar ubicación" onClick={openAdd} bgColor="bg-yellow-300" textColor="text-black" />
                </div>
            </div>
            {isOpenAdd &&
                <Modal onClose={closeAdd} title="Agregar dirección" height="h-auto">
                    <AgregarDireccion onClose={closeAdd} refetch={fetchAddress} addressData={address} />
                </Modal>
            }
        </div>
    );
}

export default Direcciones;
