import { Field, ErrorMessage } from "formik";
import { useState } from "react";
import { FaLock, FaLockOpen } from "react-icons/fa6";

const InputFieldFormik = ({
  name = "",
  value = "",
  label = "",
  icon: Icon,
  options = [],
  type = "",
  clickIcon = () => {},
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="w-full relative">
      <label htmlFor={name} className="text-gray-400 text-md">
        {label}
      </label>
      <br />
      {type === "password" ? (
        <div className="relative">
          <Field
            className="border-2 bg-[#00000057] text-yellow-300 border-gray-500 w-full p-2 pr-8 rounded-xl focus:border-yellow-300 focus:outline-none ring-0 disabled:cursor-not-allowed disabled:bg-black disabled:text-yellow-300"
            name={name}
            placeholder={value}
            type={showPassword ? "text" : "password"}
            {...rest}
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute top-0 right-0 h-full flex items-center p-2 text-gray-500"
          >
            {showPassword ? <FaLockOpen className="text-yellow-300" /> : <FaLock className="text-yellow-300" /> }
          </button>
        </div>
      ): type !== "select" && type !== "checkbox" ? (
        <div className="relative">
          <Field
            className="border-2 bg-[#00000057] text-yellow-300 border-opacity-50 border-gray-300 w-full p-2 pr-8 rounded-xl focus:border-yellow-300 focus:outline-none ring-0 disabled:cursor-not-allowed disabled:bg-slate-300 disabled:text-slate-400"
            name={name}
            placeholder={value}
            type={type}
            {...rest}
          />
          {Icon && (
            <div
              className="absolute top-0 right-0 h-full flex items-center"
              onClick={clickIcon}
            >
              <Icon className="text-gray-500 h-4 w-4 mr-2" />
            </div>
          )}
        </div>
      ) : type === "checkbox" ? (
        <div className="flex items-center ">
          <Field
            className="form-checkbox h-11 w-11 border-2 border-gray-300 focus:border-blue-500 focus:outline-none ring-0 disabled:cursor-not-allowed disabled:bg-slate-300 disabled:text-slate-400"
            name={name}
            type="checkbox"
            {...rest}
          />
        </div>
      ) : (
        <Field
          className="border-2 border-gray-300 w-full p-2 pr-8 rounded focus:border-blue-500 focus:outline-none ring-0 disabled:cursor-not-allowed disabled:bg-slate-300 disabled:text-slate-400"
          name={name}
          as="select"
          {...rest}
        >
          <option value="" disabled>
            Selecciona una opción
          </option>
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              className="text-black"
            >
              {option.label}
            </option>
          ))}
        </Field>
      )}

      <ErrorMessage name={name}>
        {(message) => (
          <div className="absolute right-0 text-sm text-yellow-300">{message}</div>
        )}
      </ErrorMessage>
    </div>
);}

export default InputFieldFormik;
