import React from "react";
import "./styles.css"

const H2Custom = ({text , textBold= false, colorText ="text-white", size="text-lg", onClick, cursor="", textAling="", ...res }) =>{

    return(
        <h1
            className={`w-auto font-custom ${textAling} ${cursor} ${size} ${textBold ? ("font-bold") : ("font-normal")}  ${colorText} `}
            onClick={onClick}
            {...res}
        >
            {text}
        </h1>
    );
}

export default H2Custom;