import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import LargeText from "../../atoms/LargeText";
import ButtonForm from "../../atoms/ButtonForm";
import InputFieldFormik from "../../atoms/InputFieldFormik";
import { UPDATE_USER } from "../../../apiUser";
import { PulseLoader } from "react-spinners";
import moment from "moment/moment";

const Perfil = ({ user, loading, setLoading, refetch }) => {
  const [formValues, setFormValues] = useState({
    name: user?.nombre || "",
    apellido: user?.apellido || "", 
    email: user?.email || "",
    fecha_nacimiento: user?.fecha_nacimiento ? user?.fecha_nacimiento.split('T')[0] : "", 
    cellphone: user?.telefono || "", 
  });

  useEffect(() => {
    setFormValues({
      name: user?.nombre || "",
      apellido: user?.apellido || "", 
      email: user?.email || "",
      fecha_nacimiento: user?.fecha_nacimiento ? user?.fecha_nacimiento.split('T')[0] : "",
      cellphone: user?.telefono || "", 
    });
  }, [user]);

  const initialPass = {
    password : "", 
    confirmPassword : ""
  }


  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const updatedUser = {
        nombre: values.name,
        apellido: values.apellido, 
        email: values.email,
        fecha_nacimiento: values.fecha_nacimiento, 
        telefono: values.cellphone, 
      };
      const response = await UPDATE_USER(user.id, updatedUser);
      if (response) {
        refetch();
        toast.success("Datos actualizados correctamente");
        setFormValues(values);
      }
    } catch (error) {
      toast.error("Error al actualizar los datos");
      console.error("Error al actualizar los datos del usuario:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitPass = async (values) => {
    try {
      setLoading(true);
      const updatedUser = {
        password: values.password,
      };
      const response = await UPDATE_USER(user.id, updatedUser);
      if (response) {
        refetch();
        toast.success("Contraseña actualizadda correctamente");
        setFormValues(values);
      }
    } catch (error) {
      toast.error("Error al actualizar los datos");
      console.error("Error al actualizar los datos del usuario:", error);
    } finally {
      setLoading(false);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Campo requerido";
    }
    if (!values.apellido) {
      errors.apellido = "Campo requerido";
    }
    if (!values.cellphone) {
      errors.cellphone = "Campo requerido";
    } else if (!/^\d{10}$/.test(values.cellphone)) {
      errors.cellphone = "Debe ser un número de 10 dígitos";
    }
    if (!values.email) {
      errors.email = "Campo requerido";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Debe ser un email válido";
    }
    if (!values.fecha_nacimiento) {
      errors.fecha_nacimiento = "Campo requerido";
    } else {
      const edad = moment().diff(moment(values.fecha_nacimiento, "YYYY-MM-DD"), "years");
      if (edad < 15) {
        errors.fecha_nacimiento = "Debes tener al menos 15 años";
      }
    }

    return errors;
  };
  
  const validatePassword = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = "Campo requerido";
    } else if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,}/.test(values.password)
    ) {
      errors.password =
        "6 caracteres, una mayúscula, una minúscula y un número.";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Campo requerido";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Las contraseñas no coinciden";
    }
  return errors;
};

  return (
    <div className="w-full h-fit bg-gray-700 rounded-md p-2 sm:p-8">
      <LargeText text="Información de tu cuenta" textBold colorText="text-yellow-300" />
      <Formik enableReinitialize initialValues={formValues} validate={validate} onSubmit={handleSubmit} >
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col sm:grid sm:grid-cols-2"
          >
            <div className="sm:px-2 space-y-2">
              <InputFieldFormik name="name" label="Nombre(s)" type="text" placeholder="Nombre(s)" />
              <InputFieldFormik name="email" label="Correo electrónico" type="text" placeholder="correo@axample.com" />
              <InputFieldFormik name="fecha_nacimiento" label="Fecha de nacimiento" type="date" />
            </div>
            <div className="space-y-2 sm:px-2">
              <InputFieldFormik name="apellido" label="Apellido(s)" type="text" placeholder="Apellido(s)" />
              <InputFieldFormik name="cellphone" label="Celular" type="number" placeholder="celular" />
              <div className="pt-4">
                <ButtonForm 
                  text={loading ? <PulseLoader color="#0b8500"/> : "Actualizar los datos"} bgColor={loading ? "bg-gray-300" : "bg-yellow-300"}
                  textColor="text-black" type="submit" disabled={loading}
                />
              </div   >
            </div>
          </form>
        )}
      </Formik>
      <Formik initialValues={initialPass} validate={validatePassword} onSubmit={handleSubmitPass} >
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col sm:grid sm:grid-cols-2 mt-10"
          >
            <div className="sm:px-2 space-y-2">
              <InputFieldFormik name="password" label="Contraseña" type="password" placeholder="Contraseña" />
            </div>
            <div className="space-y-2 sm:px-2">
              <InputFieldFormik name="confirmPassword" label="Confirmar contraseña" type="password" placeholder="Confirmar contraseña" />
              <div className="pt-2">
                <ButtonForm 
                  text={loading ? <PulseLoader color="#0b8500"/> : "Actualizar contraseña"} bgColor={loading ? "bg-gray-300" : "bg-yellow-300"}
                  textColor="text-black" type="submit" disabled={loading}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Perfil;
