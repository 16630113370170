import React, { createContext, useContext, useState } from 'react';

const ProductContext = createContext();

export const useProducts = () => {
  return useContext(ProductContext);
};

export const ProductProvider = ({ children }) => {
	const [total, setTotal] = useState(0);
	const [cart, setCart] = useState([]);
	const [userData, setUserData] = useState();
	const [tableNumber, setTableNumber] = useState(0);
	const [dataKitchens, setDataKitchens] = useState(null);
	const [role, setRole] = useState();

	const clearCart = () => {
		setCart([]);
		setTotal(0);
	};

	const addToCart = (product) => {
		setCart((prevCart) => {
			const existingProductIndex = prevCart.findIndex(
				(item) =>
					item.id === product.id &&
					item.type === product.type &&
					JSON.stringify(item.extras) === JSON.stringify(product.extras) &&
					JSON.stringify(item.additionals) === JSON.stringify(product.additionals) &&
					item.comments === product.comments
			);
			let updatedCart;
			if (existingProductIndex > -1) {
				updatedCart = [...prevCart];
				updatedCart[existingProductIndex].quantity += product.quantity;
			} else {
				updatedCart = [...prevCart, { ...product }];
			}
			updateTotalPrice(updatedCart);
			return updatedCart;
		});
	};

	const updateCart = (product) => {
		setCart((prevCart) => {
			const existingProductIndex = prevCart.findIndex(
				(item) => item.uniqueId === product.uniqueId
			);
			let updatedCart;
			if (existingProductIndex > -1) {
				if (product.quantity === 0) {
					updatedCart = prevCart.filter(
						(_, index) => index !== existingProductIndex
					);
				} else {
					updatedCart = prevCart.map((item, index) =>
						index === existingProductIndex
							? {
									...item,
									quantity: product.quantity,
									totalProduct: product.totalProduct,
									extras: product.extras,
									additionals: product.additionals,
									comments: product.comments,
							}
							: item
					);
				}
			} else {
				updatedCart = [...prevCart, product];
			}
			updateTotalPrice(updatedCart);
			return updatedCart;
		});
	};

	const updateTotalPrice = (updatdCart) => {
		const total = updatdCart.reduce((acc, item) => acc + item.totalProduct, 0);
		setTotal(total);
	};

	const calculateTotal = (selectedProduct, countProducts) => {
		const productPrice = parseFloat(selectedProduct.precio) || 0;
		return productPrice * countProducts;
	};

	const preparingOrder = cart.reduce((acc, product) => {
		const { id_cocina } = product;
		if (!acc[id_cocina]) {
			acc[id_cocina] = [];
		}
		acc[id_cocina].push(product);
		return acc;
	}, {});

	const groupedByCocina = cart.reduce((acc, product) => {
		const foundKitchen = dataKitchens.find((c) => c.id === product.id_cocina);
		if (foundKitchen) {
			if (!acc[product.id_cocina]) {
				acc[product.id_cocina] = {
					cocina: foundKitchen,
					products: [],
				};
			}
			acc[product.id_cocina].products.push(product);
		}
		return acc;
	}, {});

	const groupedByCategory = (data) => {
		if (!Array.isArray(data)) {
			return {};
		}
		return data.reduce((acc, extra) => {
			if (!acc[extra.categoria]) {
				acc[extra.categoria] = [];
			}
			acc[extra.categoria].push(extra);
			return acc;
		}, {});
	};

  return (
    <ProductContext.Provider 
      value={{
        		total,
				setTotal,
				cart,
				addToCart,
				clearCart,
				groupedByCocina,
				userData,
				setUserData,
				tableNumber,
				setTableNumber,
				updateCart,
				dataKitchens,
				setDataKitchens,
				preparingOrder,
				groupedByCategory,
				calculateTotal,
				role,
				setRole,
      }}>
      {children}
    </ProductContext.Provider>
  );
};
