import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SidebarK from "../AdmKitMolecules/SideBarK";
import { checkSession, getKitchenById } from "../../apiTheLaundry/api";
import { GET_ADMIN_KITCHEN_SESSION } from "../../apiTheLaundry/ApiAdmK";
import { useSession } from "../../context/SessionContext";

const LayoutCocinaLaundry = ({ children }) => {
  const navigate = useNavigate();
  const { setUser } = useSession();

  useEffect(() => {
    const verify = async () => {
      try {
        const user = await checkSession();
        if (user.role !== "CocinaAdmin") {
          toast.error("Acceso denegado");
          navigate(`/TheLaundry/login`);
          return;
        }
        const adminSession = await GET_ADMIN_KITCHEN_SESSION();
        if(adminSession.id_cocina !== undefined){
          const cocina = await getKitchenById(adminSession.id_cocina);
          setUser(cocina);
        }
      } catch (error) {
        if (error.response?.status === 401) {
          toast.error("Sesión no válida");
          navigate(`/TheLaundry/login`);
        }
      }
    };

    verify();
  }, [navigate, setUser]);

  return (
    <div className="flex min-h-screen">
      <SidebarK />
      <div className="flex-grow flex flex-col">
        <main className="h-full pl-52 py-8 pr-6 bg-white">
          {children}
        </main>
      </div>
    </div>
  );
};

export default LayoutCocinaLaundry;
