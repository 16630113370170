import React from "react";
import { linksCocina } from "../data/DataLinksKitchen";
import LinkAdminK from "./LinkAdminK";

const MenuLinksK = () => {
    return(
      <ul className="w-full">
        {linksCocina.map((link)=> (
            <li key={link.path}>
                <LinkAdminK
                    name={link.name}
                    path={link.path}
                    icon={link.icon}
                    childs={link.childs}
                />
            </li>
        ))}
      </ul>
    );
};

export default MenuLinksK;