import React, { useState, useEffect } from "react";
import ButtonForm from "../atoms/ButtonForm";
import DataTable from "react-data-table-component";
import { DELETE_CASHIER, GET_CASHIERS } from "../../apiTheLaundry/apiAdmin";
import { MdOutlineEdit, MdOutlineDeleteOutline } from "react-icons/md";
import useModal from "../Hooks/useModal";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import CreateCaja from "../adminOrganisms/CreateCaja";
import moment from "moment";
import EditCaja from "../adminOrganisms/EditCaja";
import { toast } from "react-toastify";
import ModalConfirmAdmin from "../adminMolecules/ModalConfirmAdmin";
import H2Custom from "../atoms/H2Custom";

const CajasLaundry = () => {
    const [cashiers, setCashiers] = useState([]);
    const { openModal, isOpen, closeModal } = useModal();
    const { openModal: openEdit, isOpen: isOpenEdit, closeModal: closeEdit } = useModal();
    const { openModal: openDelete, isOpen: isOpenDelete, closeModal: closeDelete } = useModal();
    const [cashier, setCashier] = useState(null);

    const fetchCashiers = async () => {
        try{
            const data = await GET_CASHIERS();
            setCashiers(data);
        } catch (error) {
            console.error("Error al obtener los cajeros", error);
        }
    };

    useEffect(() => {
        fetchCashiers();
    }, []);

    useEffect(() => {
        document.title = "Cajas - The Laundry";
    }, []); 

    const handleDeleteCashier = async () => {
        try {
            await DELETE_CASHIER(cashier?.id_user);
            toast.success("Caja eliminada correctamente");
            handleClose();
            fetchCashiers();
        } catch (error) {
            toast.error("Error al eliminar la caja");
        }
    }
    
    const handleClose = () => {
        closeEdit();
        closeDelete();
        setCashier(null);
    }

    const handleOpenEdit = (row) => {
        openEdit();
        setCashier(row);
    }

    const handleOpenDelete = (row) => {
        openDelete();
        setCashier(row);
    }

    const columns = [ 
        { name: "ID", selector: row => row.id, width: "45px" },
        { name: "Nombre", selector: row => row.nombre },
        { name: "Apellido", selector: row => row.apellido },
        { name: "Nombre de usuario", selector: row => row.user.username },
        { name: "Turno", selector: row => 
            <div>
                {moment(row.hora_entrada, "HH:mm:ss").format("HH:mm")} a {moment(row.hora_salida, "HH:mm:ss").format("HH:mm")}
            </div>
        },        
        { name: "Acciones", selector: row => 
            <div className="flex flex-row space-x-2">
                <ButtonForm text={<MdOutlineEdit size={35}/>} onClick={()=> handleOpenEdit(row)} bgColor="bg-blue-300" width="w-12"/>
                <ButtonForm text={<MdOutlineDeleteOutline size={35}/>} onClick={()=> handleOpenDelete(row)}  bgColor="bg-red-500" width="w-12"/>
            </div>
        }
    ];

    return(
        <div className="space-y-4">
            <H2Custom text="Cajas" textBold colorText="" size="text-4xl" />
            <div className="flex flex-col md:flex-row justify-end items-center space-x-0 md:space-x-4 space-y-4 md:space-y-0">
                <ButtonForm text="Crear caja" bgColor="bg-violet-300" width="w-full md:w-64" onClick={openModal} />
            </div>
            <div className="border-2 border-gray-200 rounded-lg shadow-xl">
                <DataTable 
                    data={cashiers} columns={columns} 
                    pagination paginationPerPage={10}
                />
            </div>
            {isOpen && 
                <ModalAdmin title="Agregar caja" onClose={closeModal}>
                    <CreateCaja onClose={closeModal} refetch={fetchCashiers}/>
                </ModalAdmin>
            }
            {isOpenEdit && cashier !== null && 
                <ModalAdmin title="Editar caja" onClose={handleClose}>
                    <EditCaja row={cashier} onClose={handleClose} refetch={fetchCashiers}/>
                </ModalAdmin>
            }
            {isOpenDelete && cashier !== null &&
                <ModalConfirmAdmin onConfirm={handleDeleteCashier} onClose={handleClose} />
            }
        </div>
    )
}

export default CajasLaundry;
