import React, { useEffect } from "react";
import HistorialPadel from "./HistorialPadel";

const Padel = () => {
    useEffect(() => {
        document.title = "Padel";
    }, []); 

    return(
        <div>
            <HistorialPadel/>
        </div>
    )
}

export default Padel;