import React, { useState, useEffect } from "react";
import useModal from "../../Hooks/useModal";
import Link from "../../atoms/Link";
import LargeText from "../../atoms/LargeText";
import InputLabel from "../../atoms/InputLabel";
import Modal from "../../molecules/Modal";
import CardCocina from "../../molecules/Guia/CardCocina";
import CambiarDireccion from "../../molecules/Guia/CambiarDireccion";
import AgregarDireccion from "../../molecules/Guia/AgregarDireccion";
import PagarPedido from "./PagarPedido";
import ButtonForm from "../../atoms/ButtonForm";

const HacerPedidos = ({allProducts, total, setPagar, handleTotal, handleEnvio, Usuario, groupedByCocina }) =>{
    const costoEnvio = 50;
    const [totalPedido, setTotalPedido] = useState(total);
    const { isOpen:IsOpenCambio, openModal:OpenCambio, closeModal:closeCambio } = useModal();
    const { isOpen:IsOpenPagar, openModal:OpenPagar, closeModal:closePagar } = useModal();
    const { isOpen:IsOpenAgregarDireccion, openModal:OpenAgregarDireccion, closeModal:closeAgregarDireccion} = useModal();


//    const  handlePagar = () =>{
//        handleTotal(totalPedido);
//        handleEnvio(costoEnvio);
//        setPagar(true);
//        closePagar();
//    };

    useEffect(() => {
        const costoProductos = total;
        const totalCalculado = costoProductos + costoEnvio;
        setTotalPedido(totalCalculado);
    }, [total, costoEnvio]);

    const direccionPrincipal = Usuario?.direcciones.find(direccion => direccion?.main) || {};

    return( 
        <main className="w-full flex flex-col sm:flex-row">
            <section className="w-full sm:w-2/3 flex flex-col space-y-8 p-4">
                <div className="w-full bg-gray-600 rounded-md p-4 space-y-4">
                    <div className="flex flex-row justify-between items-center">
                        <LargeText text="Direccion de entrega" textBold />
                        <Link value="Cambiar" text="text-yellow-300" onClick={OpenCambio}  />
                    </div>
                    <div className="h-[.5px] w-full border-[1px] border-white " />
                    <LargeText 
                        text={
                            `${direccionPrincipal.calle}, 
                            ${direccionPrincipal.colonia}, 
                            ${direccionPrincipal.cp}, 
                            ${direccionPrincipal.municipio}, 
                            ${direccionPrincipal.estado}`
                        } 
                        textBold 
                    />
                    <InputLabel etiqueta="Instrucciones de entrega (Opcional)" placeholder="Detalles adicionales..."/>
                    <LargeText text="Productos por cocina" textBold />
                    <div className="h-[.5px] w-full border-[1px] border-white " />
                    {Object.entries(groupedByCocina).map(([idCocina, cocinaData], index) => {
                        return (
                            <CardCocina
                                key={index}
                                cocina={cocinaData.cocina} 
                                cantidad={cocinaData.products.length} 
                                allProducts={allProducts} 
                            />
                        );
                    })}
                </div>
            </section>
            <section className="w-full h-fit sm:w-1/3 flex flex-col space-y-8 p-4">
                <div className="w-full bg-gray-600 rounded-md p-4 space-y-2 flex flex-col">
                    <LargeText text="Total" textBold /> 
                    <div className="h-[.5px] w-full border-[1px] border-white " />
                        <div className="flex flex-row justify-between">
                            <LargeText text="Costo de productos" /> 
                            <LargeText text={`$ ${total}`} />
                        </div>
                        <div className="flex flex-row justify-between">
                            <LargeText text="Costo de envío" /> 
                            <LargeText text={`$ ${costoEnvio}`} />
                        </div>
                        <div className="flex flex-row justify-between">
                            <LargeText text="Total" colorText="text-yellow-300" textBold /> 
                            <LargeText text={`$ ${totalPedido}`} textBold />
                        </div>
                        <ButtonForm text="Pagar" bgColor="bg-yellow-300" textColor="" onClick={OpenPagar}/>
                </div>
                {IsOpenCambio &&
                    <Modal title="Cambiar de dirección" height="h-auto" Button="Agregar" onClick={OpenAgregarDireccion} onClose={closeCambio} >
                        <CambiarDireccion onClose={closeCambio} Usuario={Usuario}/>
                        {IsOpenAgregarDireccion &&
                            <Modal title="Agregar una nueva dirección" height="h-auto" onClose={closeAgregarDireccion}>
                                <AgregarDireccion onClose={closeAgregarDireccion}/>
                            </Modal>
                        }
                    </Modal> 
                }
                {IsOpenPagar &&
                    <Modal title="Completar pago" height="h-auto" Button="Agregar" onClick={OpenAgregarDireccion} onClose={closePagar} >
                        <PagarPedido onClose={closePagar} setPagar={setPagar} totalPedido={totalPedido}/>
                    </Modal> 
                }
            </section>
        </main>
    )
}

export default HacerPedidos;