import React, { useState, useEffect } from "react";
import LargeText from "../atoms/LargeText";
import Circle from "../atoms/Circle";
import { useNavigate } from "react-router-dom";
import { getKitchen } from "../../api";
import CreateKitchen from "../adminOrganisms/CreateKitchen";
import ButtonForm from "../atoms/ButtonForm";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import useModal from "../Hooks/useModal";
import CreateKitchenAdmin from "../adminOrganisms/CreateKitchenAdmin";

const CirclesCocinas = () =>{
    const navigate = useNavigate();
    const { openModal, closeModal, isOpen } = useModal();
    const { openModal: openAdmin, closeModal: closeAdmin, isOpen: isOpenAdmin } = useModal();
    const [cocinas, setCocinas] = useState([]);
    const [kitchenId, setKitchenId] = useState(null);

    const fetchKitchen = async () =>{
        try{
            const data = await getKitchen();
            const filteredCocinas = data.filter(cocina => cocina.tipo === "Cocina");
            setCocinas(filteredCocinas);
        } catch (error) {
            console.log("Error al obtener los datos", error);
        }
    };

    useEffect (()=>{
        fetchKitchen()
    },[]);

    const handleCircleClick = (id) => {
        navigate(`${id}`);
    };

    const handleCloseAdmin = () => {
        closeAdmin();
        setKitchenId(null);
    }

    return(
        <div className="w-full flex flex-col p-4 border-2 border-gray-2 rounded-lg shadow-lg">
            <div className="w-full flex flex-row justify-between items-center">
            <LargeText text="Cocinas" size="text-2xl" textBold colorText="text-black"/>
            <ButtonForm text="Crear cocina" bgColor="bg-blue-300" width="w-1/2 md:w-1/4" onClick={openModal}/>
                {isOpen &&
                    <ModalAdmin title="Crear cocina" onClose={closeModal}>
                        <CreateKitchen onClose={closeModal} refetch={fetchKitchen} setKitchenId={setKitchenId} openAdmin={openAdmin} />
                    </ModalAdmin>
                }
            </div>
            <div className="w-full flex flex-wrap justify-between">
            {cocinas.map((cocina)=>(
                <Circle
                    key={cocina.id}
                    cocina={cocina}
                    Height="aspect-square" 
                    Width="w-1/3 md:w-[15%]" 
                    onClick={()=>handleCircleClick(cocina.id)}
                />
            ))}
            </div>
            {isOpenAdmin &&
                <ModalAdmin title="Agregar administrador de la cocina" onClose={handleCloseAdmin}>
                    <CreateKitchenAdmin kitchenId={kitchenId} onClose={handleCloseAdmin} />
                </ModalAdmin>
            }
        </div>
    );
};

export default CirclesCocinas;