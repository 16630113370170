import React, { useState } from "react";
import LargeText from "../../atoms/LargeText";
import CardProducto from "./CardProducto";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

const CardCocina = ({ cocina, cantidad = 0, allProducts }) => {
  const [showProducts, setShowProducts] = useState(false);

  const filteredMenu = allProducts.filter((item) => item.id_cocina === cocina.id);

  const toggleProducts = () => {
    setShowProducts(!showProducts);
  };

  return (
    <div className="w-full py-4">
      <div className="flex justify-between items-center">
        <LargeText colorText="text-yellow-300" text={cocina.nombre} textBold />
        <button onClick={toggleProducts} className="text-2xl">
          {showProducts ? <MdOutlineKeyboardArrowUp color="white" size={30} /> : <MdOutlineKeyboardArrowDown color="white" size={30} />}
        </button>
      </div>
      <LargeText text={`${cantidad} producto(s)`} />
      {showProducts && (
        <div className="flex flex-wrap">
          {filteredMenu.map((item, index) => (
            <CardProducto key={index} item={item} />
          ))}
        </div>
      )}
    </div>
  );
};

export default CardCocina;