import React, { useState } from "react";
import { UPDATE_ADMIN_KITCHEN } from "../../apiAdmin";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import ButtonForm from "../atoms/ButtonForm";

const EditAdminKitchen = ({ row, onClose, refetch }) => {
    const [loading, setLoading] = useState();

    const initialValues = {
        username: row.user.username || "", password:"", nombre: row.nombre || "",  
        apellido: row.apellido || "", telefono: row.telefono || "",
    }

    const handleSubmit = async(values) =>{
        try {
            setLoading(true);
            await UPDATE_ADMIN_KITCHEN(row.id_user,values);
            toast.success("Administrador editado correctamente");
            refetch();
            onClose();
        } catch (error){
            console.error("Error", error);
            toast.error("Error al editar el administrador");
        } finally {
            setLoading(false);
        }
    }

    const validate = (values) => {
		const errors = {};
        if (!values.username) { errors.username = "Campo requerido"; }
		if (!values.nombre) { errors.nombre = "Campo requerido"; }
		if (!values.apellido) { errors.apellido = "Campo requerido"; }
        if (!values.telefono) { errors.telefono = "Campo requerido"; }
		return errors;
	};

    return(
        <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate} >
                {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="w-full flex flex-row space-x-4">
                        <div className="w-1/2 flex flex-col space-y-4">
                            <InputFFAdmin name="nombre" label="Nombre(s)" type="text" placeholder="Nombre(s)" />
                            <InputFFAdmin name="username" label="Nombre de usuario" type="text" placeholder="Nombre de usuario" />
                            <InputFFAdmin name="password" label="Contraseña" type="password" placeholder="Contraseña" />  
                        </div>
                        <div className="w-1/2 flex flex-col space-y-4">
                            <InputFFAdmin name="apellido" label="Apellidos(s)" type="text" placeholder="Apellido(s)" />
                            <InputFFAdmin name="telefono" label="Telefono" type="number" placeholder="Telefono" />
                        </div>
                    </div>
                   <ButtonForm 
                        bgColor={loading ? "bg-gray-300" : "bg-violet-300"} width="w-1/3 ml-auto mt-6" 
                        text={loading ? "Cargando" : "Guardar cambios"} type="submit" disabled={loading ? true : false } 
                    />
                </Form>
                )}
            </Formik>
        </div>
    )
}

export default EditAdminKitchen;