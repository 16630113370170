import React from "react";
import LargeText from "../../atoms/LargeText";
import Contador from "../../atoms/Contador";
import useModal from "../../Hooks/useModal"
import ModalProduct from "./ModalProduct";
import { PiPencilDuotone } from "react-icons/pi";

const CardCocinaCarrito = ({item, cocina }) => {
    const {openModal, isOpen, closeModal } = useModal();   

    return(
        <div className="w-full h-32 flex flex-row border-b-[1px] py-2 border-yellow-300">
            <div className="w-[40%] h-32 flex items-center justify-center p-1">
                <img src={item.imagen}alt="imagen " className="h-[80%] rounded-lg" />
            </div>
            <div className="w-[60%] flex flex-col h-full">
                <div className="w-full h-3/4 flex flex-col">
                    <div className="w-full flex flex-row justify-between items-center"> 
                        <LargeText text={item.nombre} textBold={true}  />
                        <PiPencilDuotone size={20} className="cursor-pointer text-white" onClick={openModal}/>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-between px-0 sm:px-4">
                    <LargeText text={` $ ${item.precio}`} textBold={true}  />
                    <Contador 
						selectedProduct={item}
						countProducts={item.quantity}
                    />
                </div>
            </div>
            {isOpen && (
                <ModalProduct 
                    cocina={cocina} 
                    item={item} 
                    isEditing={true}
                    onClose={closeModal} 
                />
            )}
        </div>
    );
}

export default CardCocinaCarrito;