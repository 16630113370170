import React, { useState } from "react";
import Dropdown from "../../atoms/Dropdown";

const PreguntasFrecuentes = () => {
    const [openDropdown, setOpenDropdown] = useState(null);

    const toggleDropdown = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    return (
        <div className="px-0 sm:px-4">
            <Dropdown 
                Value="1. ¿Qué hago si tengo una alergia alimentaria?" 
                Content="Si tiene alguna alergia alimentaria, indíquelo en la sección de notas al realizar su pedido y tomaremos las precauciones necesarias." 
                isOpen={openDropdown === 2} 
                onToggle={() => toggleDropdown(2)} 
            />
            <Dropdown 
                Value="2. ¿Cómo puedo modificar o cancelar mi pedido?" 
                Content="Para modificar o cancelar su pedido, contáctenos a través de nuestro servicio de atención al cliente lo antes posible." 
                isOpen={openDropdown === 3} 
                onToggle={() => toggleDropdown(3)} 
            />
            <Dropdown 
                Value="3. ¿Cuánto tiempo tarda la entrega de mi pedido?" 
                Content="El tiempo de entrega varía según la distancia y el volumen de pedidos, pero generalmente entregamos en un plazo de 30 a 60 minutos. Durante horas pico, los tiempos de entrega pueden ser un poco más largos. Puede seguir el estado de su pedido en tiempo real a través de nuestra aplicación o sitio web." 
                isOpen={openDropdown === 1} 
                onToggle={() => toggleDropdown(1)} 
            />
        </div>
    );
}

export default PreguntasFrecuentes;
