import React, { useEffect } from "react";
import background from "../assets/Imagenes/Home1.jpg"
import right from "../assets/Imagenes/right.jpg"
import left from "../assets/Imagenes/left.jpg"
import chef from "../assets/Imagenes/4.jpg"
import LargeText from "../atoms/LargeText";
import ButtonAbout from "../atoms/ButtonAbout";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import Menu from "../Organisms/Menu";
import Titulo from "../atoms/Titulo";
import Header from "../Organisms/Header";

const HomePageLaundry = () => {
	useEffect(() => {
        document.title = "The Laundry";
    }, []); 

	return (
		<main>
			<Header/>
			<section 
				className="w-full h-screen bg-cover bg-center flex flex-col justify-center items-center"
				style={{backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${background})`}}
			>
				<div className="w-1/2 h-[20%] mt-[5%] bg-blue-500">

				</div>
				<div className="w-1/2 h-1/2 flex flex-col justify-around">
					<Titulo
						texto="¡Descubre el sabor de la auténtica parrilla texana en The Laundry!" 
					/>
					<LargeText 
						text="Aquí, cada corte es cocido con el verdadero estilo texano: bajo y lento, 
							en leña y carbón, logrando un ahumado perfecto y sabores intensos" 
						textBold size="text-xl" textAling="text-center"
					/>
					<LargeText 
						text="Vive una experiencia única que mezcla tradición, sabor y un ambiente inigualable" 
						textBold size="text-xl" textAling="text-center"
					/>
				</div>
				<div className="w-1/2 h-1/4 flex flex-col items-center">
					<ButtonAbout text="Informes" />
					<MdKeyboardDoubleArrowDown className="text-teal-300" size={300} />
				</div>
			</section>
			<section className="w-full h-screen flex">
				<div className="w-1/2 h-full flex flex-col justify-between px-4 py-8">
					<Titulo 
						texto="Personalización:" 
						textAling="text-start"
					/>
					<LargeText 
						titleColor="text-teal-300"
						title="Eventos Especiales"
						content=" Ya sea un cumpleaños, aniversario o cualquier celebración,
							nuestro chef privado adaptará el menú a la temática o el estilo de tu evento." 
						textBold size="text-xl" textAling="text-center"
					/>
					<LargeText 
						titleColor="text-teal-300"
						title="Menús a Medida"
						content="Trabaja directamente con nuestro chef para diseñar un menú a la medida de tus gustos y necesidades.
							Desde gastronomía internacional hasta cocina de autor, cada plato estará pensado especialmente para ti y tus invitados." 
						textBold size="text-xl" textAling="text-center"
					/>
					<LargeText 
						titleColor="text-teal-300"
						title="Atención Detallada"
						content="Cada aspecto de la experiencia es personalizado, desde las técnicas culinarias utilizadas hasta la presentación final de los platos.
							Creamos experiencias visuales y de sabor inolvidables." 
						textBold size="text-xl" textAling="text-center"
					/>
				</div>
				<div className="relative w-1/4 h-full">
					<img src={left} alt="Left" className="w-full h-full object-cover" />
					<div className="absolute inset-0 bg-gradient-to-b from-black/100 to-transparent"></div>
				</div>
				<div className="relative w-1/4 h-full">
					<img src={right} alt="Right" className="w-full h-full object-cover" />
					<div className="absolute inset-0 bg-gradient-to-b from-black/100 to-transparent"></div>
				</div>
			</section>
				<Menu/>
			<section className="w-full h-screen flex flex-row justify-around items-center ">
				<div 
					className="w-1/4 h-[75%] bg-cover bg-center rounded-lg"
					style={{backgroundImage:`url(${chef})`}}
				>
				</div>
				<div 
					className="w-1/3 h-full flex flex-col pt-[10vh]"
				>
					<LargeText 
						text="Especial Moments" 
						textBold size="text-2xl" textAlign="text-center"
					/>
					<LargeText 
						text="Private chef" 
						textBold size="text-4xl" textAlign="text-center" colorText="text-teal-300"
					/>
					<LargeText 
						text="Disfruta de una experiencia gastronómica única en la privacidad de tu hogar o evento con nuestro Chef Privado.
							diseñado para aquellos que buscan una cena exclusiva, nuestro chef te sorprenderá con menús personalizados y platos creados
							con ingredientes frescos y de alta calidad.
							Ya sea para una velada intima, una celebracion especial o una reunion de negocios, nuestro chef se encargara de cada detalle, 
							ofreciendo una experiencia culinaria de primer nivel." 
						textBold size="text-2xl" textAlign="text-center"
					/>
					<ButtonAbout text="Informes" />
				</div>
				<div 
					className="w-1/4 h-[75%] bg-cover bg-center rounded-lg"
					style={{backgroundImage:`url(${chef})`}}
				>
				</div>
			</section>
			<section className="w-full h-screen bg-red-500">
			</section>
			<section className="w-full h-screen bg-blue-500">
			</section>
			<section className="w-full h-screen bg-red-500">
			</section>
		</main>
	);
};

export default HomePageLaundry;
