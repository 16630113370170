import React, { useState, useEffect, useRef } from "react";
import { GET_WAITERS_ID } from "../../apiAdmin";
import { getKitchen } from "../../api";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import ButtonForm from "../atoms/ButtonForm";
import { MdLocalPrintshop } from "react-icons/md";
import { GET_ORDER_BY_ID_PADEL } from "../../apiPadel";

const TicketPadel = ({ id }) => {
  const [order, setOrder] = useState([]);
  const [mesero, setMesero] = useState();
  const [cocinas, setCocinas] = useState([]);
  const componentRef = useRef(); 

  useEffect(() => {
    const getInfo = async () => {
      try {
        const orderData = await GET_ORDER_BY_ID_PADEL(id);
        setOrder(orderData);
        const cocinasData = await getKitchen();
        setCocinas(cocinasData);
        const meseroData = await GET_WAITERS_ID(orderData?.id_mesero);
        setMesero(meseroData);
      } catch (err) {
        console.error(err);
      }
    };

    getInfo();
  }, [id]);

  const getCocinaName = (id_cocina) => {
    const cocina = cocinas.find((c) => c.id === id_cocina);
    return cocina ? cocina.nombre : "Cocina Desconocida";
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <ButtonForm
        onClick={handlePrint}
        text="Imprimir Ticket"
        bgColor="bg-orange-500"
        width="w-1/3 m-auto"
        icon={<MdLocalPrintshop size={30}/>}
      />
      <div ref={componentRef} className="print:w-[80mm] print:text-xs">
        <div className="gap-4 px-1">
            <div className="flex flex-row justify-between p-x-1">
                <label>{`No. de ticket :${order?.id}`}</label>
                <label>{`Fecha: ${moment(order?.createdAt).format("DD/MM/YY HH:mm")}`}</label>
            </div>
          {Object.keys(order.orden || {}).map((key) => (
            <div key={key} className="col-span-3 mt-4">
              <h1 className="font-bold text-sm">{`Cocina: ${getCocinaName(parseInt(key))}`}</h1>
              <table className="table-auto w-full">
                <thead>
                  <tr className="border-b border-gray-400">
                    <th className="text-left">Producto</th>
                    <th className="text-left">Cantidad</th>
                    <th className="text-left">Precio</th>
                    <th className="text-left">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {order.orden[key].map((producto) => (
                    <tr key={producto.uniqueId} className="border-b border-gray-400">
                      <td>{producto.nombre}</td>
                      <td>{producto.quantity}</td>
                      <td>{producto.precio}</td>
                      <td>{producto.totalProduct}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
          <div className="grid grid-cols-3 mt-8">
            <label>{`Mesero: ${mesero?.nombre}`}</label>
            <label>{`Pago: ${order?.metodo_pago}`}</label>
            <label>{`Total: ${order?.total_general}`}</label>
            <label>{`Propina: ${order?.propina}`}</label>
            {order?.metodo_pago === "Mixto" && (
                <>
                <label>{`Efectivo: ${order?.total_efectivo}`}</label>
                <label>{`Tarjeta: ${order?.total_tarjeta}`}</label>
            </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketPadel;