import { Field, ErrorMessage } from "formik";

const InputFFProdEA = ({
  name = "",
  value = "",
  label = "",
  icon: Icon,
  options = [],
  type = "",
  ...rest
}) => {
  return (
    <div className="w-full flex flex-row justify-between relative my-2">
      { type === "checkbox" ? (
        <div className="flex items-center justify-between space-x-2">
          <Field
            className="form-checkbox h-11 w-11 border-2 text-yellow-300 border-green-500 focus:border-blue-500 focus:outline-none ring-0 disabled:cursor-not-allowed disabled:bg-slate-300 disabled:text-slate-400"
            name={name}
            value={value}
            type="checkbox"
            {...rest}
          />
          <span className="text-yellow-300 text-sm font-bold">{label}</span>
        </div>
      ) : type === "radio" ? (
        <div className="flex items-center space-x-2 justify-between">
          <Field
            className="form-radio h-6 w-6 border-2 border-gray-300 focus:border-blue-500 focus:ring-0 rounded-full checked:bg-green-500 checked:border-transparent"
            name={name}
            value={value}
            type="radio"
            {...rest}
          />
          <span className="text-yellow-300 text-sm font-bold">{label}</span>
        </div>
      ) : 
      ("")
    }
      <ErrorMessage name={name}>
        {(message) => (
          <div className="absolute right-0 text-sm text-red-500">{message}</div>
        )}
      </ErrorMessage>
    </div>
  );
};

export default InputFFProdEA;
