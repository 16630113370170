import React from "react";
import { Formik, Form } from "formik";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import { FcSearch } from "react-icons/fc";
import moment from "moment";
import ButtonForm from "../atoms/ButtonForm";

const FilterHistorialPadel = ({ historialOriginal, setHistorial, today }) => {
    const handleSubmit = (values) => {
        const { fecha_inicio, fecha_fin } = values;
        let filteredHistorial = historialOriginal || [];

        const start = moment(fecha_inicio).startOf('day');
        const end = moment(fecha_fin).endOf('day');
        filteredHistorial = filteredHistorial.filter(order => {
            const orderDate = moment(order.createdAt);
            return orderDate.isBetween(start, end, null, '[]');
        });

        setHistorial(filteredHistorial.length > 0 ? filteredHistorial : []);
    };

    const validate = (values) => {
        const errors = {};
        if (!values.fecha_inicio) { errors.fecha_inicio = "Campo requerido"; } 
        if (!values.fecha_fin) { errors.fecha_fin = "Campo requerido"; } 
        if (values.fecha_fin < values.fecha_inicio) { errors.fecha_fin = "La fecha de inicio es mayor a la de fin"; }
        return errors;
    };
    
    return(
        <div className="px-2 md:px-4 mb-6">
            <Formik
                initialValues={{ fecha_inicio: today, fecha_fin: today }}
                onSubmit={handleSubmit}
                validate={validate}
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="w-full flex flex-col md:flex-row space-x-0 md:space-x-4 items-center">
                        <InputFFAdmin name="fecha_inicio" type="date" label="De:" />
                        <div className="flex flex-row space-x-2 md:space-x-4 items-center">
                        <InputFFAdmin name="fecha_fin" type="date" label="A:" />
                        <ButtonForm text={<FcSearch size={30} />} width="w-16 md:w-40" bgColor="bg-gray-500 bg-opacity-60" type="submit" />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default FilterHistorialPadel;