import { useState, useEffect } from 'react';

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    if (isOpen) {
        document.body.classList.add("no-scroll");
    } else {
        document.body.classList.remove("no-scroll");
    }

    return () => {
        document.body.classList.remove("no-scroll");
    };
}, [isOpen]);

  return {
    isOpen,
    openModal,
    closeModal,
  };
};

export default useModal;
