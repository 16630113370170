import React from "react";
import LargeText from "../atoms/LargeText";
import { IoIosCloseCircle } from "react-icons/io";

const ModalAdmin = ({width="w-[75%]", height="h-auto", onClose, title="", children}) => {
    const divClassname = `w-full sm:${width} h-auto max-h-[80vh] bg-white rounded-lg p-4 overflow-auto z-50 shadow-lg`;
    
    return (
        <div className="fixed bottom-0 left-0 h-full w-full bg-slate-200 bg-opacity-95 flex justify-center items-center z-30">
            <div className={divClassname}>
                <div className="flex flex-row justify-between items-center mb-4">
                    <LargeText text={title} textBold size="text-lg sm:text-4xl" colorText="text-black" />
                    <div className="flex flex-row items-center">
                        <IoIosCloseCircle className="cursor-pointer h-6 sm:h-10 w-6 sm:w-10" onClick={onClose} />
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
}

export default ModalAdmin;
