import React from "react";
import "./styles.css"

const LargeText = ({text , textBold= false, colorText ="text-white", size="text-lg", onClick, cursor="", textAling="", ...res }) =>{

    return(
        <p
            className={`w-auto font-custom ${textAling} ${cursor} ${size} ${textBold ? ("font-bold") : ("font-normal")}  ${colorText} `}
            onClick={onClick}
            {...res}
        >
            {text}
        </p>
    );
}

export default LargeText;