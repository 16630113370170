import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Titulo from "../atoms/Titulo";
import Button from "../atoms/Button";
import LargeText from "../atoms/LargeText";
import contenedor from "../assets/Imagenes/OIG4.jpeg";
import plantAbout from "../assets/Imagenes/plantAbout.webp";

const HomePage = () => {
	const navigate = useNavigate();
	const [showPlants, setShowPlants] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowPlants(false);
		}, 3000);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		document.title = "Contenedor";
	}, []);

	return (
		<div
			style={{
				backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${contenedor})`,
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
			}}
			className="relative h-screen"
		>
			{showPlants && (
				<div className="absolute inset-0 hidden md:flex justify-between items-between">
					<img
						src={plantAbout}
						style={{ transform: "rotate(0.5turn)" }}
						alt="Plant"
						className="w-[80%] h-[80%] absolute bottom-0 left-0 z-40 animate-fade-out"
					/>
					<img
						src={plantAbout}
						style={{}}
						alt="Plant"
						className="w-[80%] h-[80%] absolute top-0 right-0 animate-fade-out"
					/>
					<img
						src={plantAbout}
						style={{ transform: "rotate(0.75turn)" }}
						alt="Plant"
						className="w-[50%] h-[50%] absolute bottom-[30%] right-[60%] animate-fade-out"
					/>
					<img
						src={plantAbout}
						style={{ transform: "rotate(0.25turn)" }}
						alt="Plant"
						className="h-[80%] absolute bottom-[13%] right-[-6%] animate-fade-out"
					/>
				</div>
			)}
			<div className="h-[80%] w-full flex flex-col text-center justify-center px-4 md:px-0">
				<Titulo Letras="CONTENEDOR" />
				<div className="mt-12">
					<LargeText
						text="Es más que una comida, es una experiencia sensorial inolvidable."
						size="text-xl md:text-3xl"
					/>
					<div className="w-full flex flex-col md:flex-row text-center justify-center ">
						<LargeText
							size="text-lg md:text-2xl"
							text="¡Estoy seguro de que querrás saber más y vivirlo por ti mismo!&nbsp;"
							colorText="text-yellow-300"
						/>
						<LargeText
							size="text-lg md:text-2xl"
							text="¿Te animas?"
							colorText="text-yellow-300"
						/>
					</div>
				</div>
			</div>
			<div className="flex w-full justify-between items-center px-2 md:px-8">
				<Button
					text="Más Información"
					onClick={() => navigate("/about")}
					mainButton={true}
					width="w-40 md:w-64"
				/>
				<Button
					text="Ver menú"
					onClick={() => navigate("/menu")}
					mainButton={true}
					width="w-40 md:w-64"
				/>
			</div>
		</div>
	);
};

export default HomePage;
