import React, { useCallback, useState, useEffect } from "react";
import { GET_PRODUCTS_BY_KITCHEN } from "../../apiTheLaundry/api";
import DataTable from "react-data-table-component";
import { useSession } from "../../context/SessionContext";
import ButtonForm from "../atoms/ButtonForm";
import { MdOutlineEdit } from "react-icons/md";
import useModal from "../Hooks/useModal";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import EditProductAdmK from "../admKitOrganisms/EditProductAdmK";
import H2Custom from "../atoms/H2Custom";

const MenuViewAdmkLaundry = () => {
    const [menu, setMenu] = useState([]);
    const [loading, setLoading] = useState(false);
    const { openModal: openEdit, isOpen: isOpenEdit, closeModal: closeEdit } = useModal();
    const [product, setProduct] = useState(null);
    const { user } = useSession();

    const fetchMenu = useCallback(async () => {        
            try {
                setLoading(true);
                const data = await GET_PRODUCTS_BY_KITCHEN(user?.id);
                setMenu(data);
            } catch (error) {
                setMenu([]);
                console.error(error.message);
            } finally {
                setLoading(false);
            }
    }, [user?.id]);

    useEffect(() => {
        fetchMenu();
    }, [fetchMenu]);

    const handleClose = () => {
        closeEdit();
        setProduct(null);
    }

    const handleOpenEdit = (row) => {
        openEdit();
        setProduct(row);
    }

    const columns = [ 
        {
            name:"Imagen",
            selector: row => 
                <div className="w-24 aspect-square flex justify-center items-center p-1">
                    <img src={row.imagen} alt="Producto" className="rounded-lg"/>
                </div>,
            width:"130px"
        },
        { name:"Nombre", selector: row => row.nombre, sortable: true, width:"200px" },
        { name:"Descripcion", selector: row => row.descripcion, width:"350px" },
        { name:"Precio", selector: row => `$${row.precio} MX`, sortable:true, width:"120px" },
        { name:"Estatus", selector: row => row.status, sortable:true, width:"130px" },
        { name: "Acciones", width:"100px", selector: row => 
            <div className="flex flex-row space-x-2">
                <ButtonForm text={<MdOutlineEdit size={35}/>} onClick={()=> handleOpenEdit(row)} bgColor="bg-blue-300" width="w-12"/>
            </div>
        }
    ]

    useEffect(() => {
        document.title = "Menú - Contenedor";
    }, []); 

    return(
        <div>
            <div className="border-2 border-violet-300 rounded-lg pt-4">
                <div className="flex flex-row space-x-4 justify-center items-center">
                    <H2Custom text="Menú" colorText="text-violet-400" size="text-4xl" textAling="text-center" textBold />
                    <ButtonForm text="Actualizar" bgColor="bg-violet-300 hover:bg-black" onClick={fetchMenu} width="w-24" />
                </div>
                <DataTable
                    data={menu} columns={columns} progressPending={loading}
                />
            </div>
            {isOpenEdit && product !== null && 
                <ModalAdmin title="Editar producto" onClose={handleClose}>
                    <EditProductAdmK product={product} onClose={handleClose} refetch={fetchMenu} />
                </ModalAdmin>
            }
        </div>
    )
}

export default MenuViewAdmkLaundry;