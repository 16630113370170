import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { getKitchenById, checkSession } from "../../api";
import Circle from "../atoms/Circle";
import ButtonForm from "../atoms/ButtonForm";
import useModal from "../Hooks/useModal";
import EditKitchenAdmn from "../admKitOrganisms/EditKitchenAdmn";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import EditAdminKitchen from "../adminOrganisms/EditAdminKitchen";
import { useSession } from "../../context/SessionContext";
import { GET_ADMIN_KITCHEN_ID } from "../../ApiAdmK";

const PerfilAdmnK = () => {
	const [cocina, setCocina] = useState();
	const [admin, setAdmin] = useState();
	const { openModal, isOpen, closeModal } = useModal();
	const {
		openModal: openAdmin,
		isOpen: isOpenAdmin,
		closeModal: closeAdmin,
	} = useModal();
	const { user } = useSession();

	const getData = useCallback(async () => {
		try {
			const cocinaData = await getKitchenById(user.id);
			setCocina(cocinaData);
		} catch (error) {
			console.error(error);
			toast.error("Sesión no válida");
		}
	}, [user]);

	useEffect(() => {
		getData();
	}, [getData]);

	useEffect(() => {
		document.title = "Perfil - Contenedor";
	}, []);

	useEffect(() => {
		const verify = async () => {
			try {
				const user = await checkSession();
				const adminSession = await GET_ADMIN_KITCHEN_ID(user.id);
				console.log(adminSession);
				setAdmin(adminSession);
			} catch (error) {
				toast.error("Sesión no válida");
			}
		};

		verify();
	}, [setAdmin]);

	return (
		<div className="w-full">
			<div className="w-full flex flex-col items-center justify-center">
				<Circle cocina={cocina} selected Width="w-1/3" Height="aspect-square" />
				<ButtonForm
					text="Editar cocina"
					bgColor="bg-purple-300"
					width="w-1/2"
					onClick={openModal}
				/>
				<ButtonForm
					text="Editar administrador"
					bgColor="bg-purple-300"
					width="w-1/2"
					onClick={openAdmin}
				/>
			</div>
			{isOpen && (
				<ModalAdmin onClose={closeModal} title="Editar cocina">
					<EditKitchenAdmn
						cocina={cocina}
						refetch={getData}
						onClose={closeModal}
					/>
				</ModalAdmin>
			)}
			{isOpenAdmin && (
				<ModalAdmin onClose={closeAdmin} title="Editar Administrador">
					<EditAdminKitchen
						row={admin}
						onClose={closeAdmin}
						refetch={getData}
					/>
				</ModalAdmin>
			)}
		</div>
	);
};

export default PerfilAdmnK;
