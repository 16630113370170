import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import ButtonForm from '../../atoms/ButtonForm';
import { useProducts } from '../../../context/ProductContext';
import { CREATE_ORDER, CREATE_ORDER_HISTORY, SEND_NOTIFICATION } from '../../../apiUser';
import { PulseLoader } from 'react-spinners';
import { useSession } from '../../../context/SessionContext';

const PaymentModal = ({ onClose, totalPedido, setPagar }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState(null);
  const { groupedByCocina, total, clearCart } = useProducts();
  const { user } = useSession();

  const handleCreateOrder = async () => {
    try {
        for (const [id_cocina, products] of Object.entries(groupedByCocina)) {
            const orderData = {
                status: "Preparando",
                total: products.products
                    .reduce((acc, product) => acc + product.totalProduct, 0),
                tipo_orden: "Llevar",
                orden: products.products,
                id_cocina: Number(id_cocina),
                id_usuarioExterno: user?.id
            };
            await CREATE_ORDER(orderData);
            SEND_NOTIFICATION({ id_cocina: Number(id_cocina) });
        }
        onClose();
    } catch (error) {
        console.error("Error al crear la orden:", error);
    }
  };

  const createOrderHistory = async () => {
        const transformedData = {};
        for (const [id_cocina, { products }] of Object.entries(groupedByCocina)) {
            console.log(id_cocina);
            products.forEach(product => {
                const categoria = product.categoria;

                if (!transformedData[categoria]) {
                    transformedData[categoria] = [];
                }

                transformedData[categoria].push({
                    ...product,
                });
            });
        }
		try {
			const payload = {
				total_general: total,
        total_tarjeta: total,
				orden: transformedData,
        tipo_orden:"Llevar",
        metodo_pago:"Tarjeta",
			};
			await CREATE_ORDER_HISTORY(payload);
		} catch (error) {
			console.error("Error al crear el pedido en el historial", error);
		}
	};

  useEffect(() => {
    if (totalPedido) {
      const createPaymentIntent = async () => {
        try {
          const response = await fetch('http://localhost:3000/api/createPayment', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ total: totalPedido }), 
          });

          const { clientSecret } = await response.json();
          setClientSecret(clientSecret);
        } catch (error) {
          console.error('Error al crear el PaymentIntent:', error);
        }
      };
      createPaymentIntent();
    }
  }, [totalPedido]);

  const handlePayment = async () => {
    if (!stripe || !elements || !clientSecret) return;

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (error) {
      console.error('Error al procesar el pago:', error);
      toast.error("Error al procesar el pago")
    } else if (paymentIntent.status === 'succeeded') {
      toast.success('Pago exitoso');
      await handleCreateOrder();
      await createOrderHistory();
      onClose(); 
      setPagar(true);
      clearCart();
    }
  };

  return (
    <div>
      {clientSecret ? (
        <div>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '20px',
                  color: '#fde047',
                  fontFamily: 'Arial, sans-serif',
                  '::placeholder': {
                    color: '#ffffff80',
                  },
                },
                invalid: {
                  color: '#fa755a',
                },
              },
            }}
            className="p-3 border-2 rounded-md shadow-sm"
          />
          <ButtonForm 
            textColor='' width='w-1/4 ml-auto' disabled={!stripe}
            onClick={handlePayment} text='Pagar' bgColor='bg-yellow-300' 
          />
        </div>
      ) : (
        <PulseLoader color="#0b8500"/>
      )}
    </div>
  );
};

export default PaymentModal;
