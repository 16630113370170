import React from "react";
import Logo from "../assets/Imagenes/logoAdmin.png";
import LargeText from "../atoms/LargeText";
import MenuLinksCaja from "../cajaAtoms/MenuLinksCaja";
import ButtonForm from "../atoms/ButtonForm";
import useModal from "../Hooks/useModal";
import ModalConfirmAdmin from "../adminMolecules/ModalConfirmAdmin";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { logout } from "../../api";

const SidebarCaja = () => {
  const navigate = useNavigate();
  const { openModal, closeModal, isOpen } = useModal();

  const handleLogout = async () => {
    try {
      await logout();
      toast.success("Sesión cerrada exitosamente");
      navigate(`/login`);
    } catch (error) {
      console.error(error);
      toast.error("Error al cerrar sesión");
    }
  };

  return (
    <div className="fixed w-48 bg-gray-400 text-white h-full z-30">
      <div className="w-full p-1 flex flex-col justify-center items-center">
        <img src={Logo} className="w-[100%]" alt="LOGO" />
        <LargeText text="Administrador de caja" colorText="text-black" />
      </div>
      <MenuLinksCaja />
      <ButtonForm text="Cerrar sesión" textColor="text-black mt-full" onClick={openModal} />
      {isOpen &&
        <ModalConfirmAdmin onClose={closeModal} onConfirm={handleLogout} />
      }
    </div>
  );
};

export default SidebarCaja;
