import React, { createContext, useContext, useState } from "react";

const SessionContext = createContext();

export const useSession = () => {
  return useContext(SessionContext);
};

export const SessionProvider = ({ children }) => {
    const [user, setUser] = useState();

  return (
    <SessionContext.Provider value={{user, setUser}}>
      {children}
    </SessionContext.Provider>
  );
};