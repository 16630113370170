import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useProducts } from "../../context/ProductContext";
import ButtonForm from "../atoms/ButtonForm";
import Header from "../organisms/Header";
import Footer from "../organisms/Footer";
import NoPedidos from "../organisms/Guia/NoPedidos";
import HacerPedidos from "../organisms/Guia/HacerPedidos";
import StatusPedido from "../organisms/Guia/StatusPedido";

const Guia = () => {
    const { cart, setAllProducts, total, setTotal, groupedByCocina, user } = useProducts();
    const navigate = useNavigate();
    const location = useLocation();
    const [pagar, setPagar]= useState(false);
    const [envio, setEnvio] = useState(0);
    const [totalPedido, setTotalPedido] = useState(total);

    useEffect(() => {
        document.title = "Guia - Contenedor";
    }, []); 

    useEffect(() => {
        if (location.hash ==="top"){
            const scrollToTop = () => {
                const section= document.getElementById("top");
                if (section) {
                  section.scrollIntoView({ behavior: "smooth" });
                }
              };
              scrollToTop();
        } else {
        const scrollToSec = () => {
          const section= document.getElementById("#");
          if (section) {
            section.scrollIntoView({ behavior: "smooth" });
          }
        };
        scrollToSec();
        }
    }, [location.hash]);

    const handleTotal = (monto) =>{
        setTotalPedido(monto);
    };

    const handleEnvio = (monto) =>{
        setEnvio(monto);
    };

    return(
        <div className="pt-12 sm:pt-0">    
            <Header 
                allProducts={cart} 
                setAllProducts={setAllProducts} 
                total={total} 
                setTotal={setTotal} 
            /> 
            <div className="h-fit w-screen" id="?">

                <div className="w-full min-h-[65vh]" id="#" >
                <ButtonForm  text="Volver" divStyles="pl-6" icon={<MdOutlineArrowBackIos className="h-6 w-6" />} onClick={() => navigate('/home')}/>
                {cart.length ? (
                    <>
                    {pagar === false ? (
                        <HacerPedidos 
                            setAllProducts={setAllProducts} 
                            allProducts={cart} 
                            total={total} 
                            setTotal={setTotal} 
                            setPagar={setPagar} 
                            handleTotal={handleTotal} 
                            handleEnvio={handleEnvio}  
                            Usuario={user}
                            groupedByCocina={groupedByCocina}
                        /> 
                    ) : (
                        <StatusPedido 
                            setAllProducts={setAllProducts} 
                            allProducts={cart} 
                            total={total} 
                            setTotal={setTotal} 
                            totalPedido={totalPedido} 
                            envio={envio} 
                            Usuario={user}
                            groupedByCocina={groupedByCocina}
                        />
                    )
                    }   
                    </>
                ):(
                    <NoPedidos/>
                )}
                </div>
            
            </div> 
            <Footer/> 
        </div>
    );
}

export default Guia;